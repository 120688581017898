// sort the array object list Asending
function sortObjectListAsc(arrayList, NameOfKey) {
  if (arrayList?.length > 1 && NameOfKey) {
    function compare(a, b) {
      if (a[`${NameOfKey}`] < b[`${NameOfKey}`]) {
        return -1;
      }
      if (a[`${NameOfKey}`] > b[`${NameOfKey}`]) {
        return 1;
      }
      return 0;
    }
    return arrayList?.sort(compare);
  }
}

// sort the array object list Des
function sortObjectListDes(arrayList, NameOfKey) {
  if (arrayList?.length > 1 && NameOfKey) {
    function compare(a, b) {
      if (a[`${NameOfKey}`] > b[`${NameOfKey}`]) {
        return -1;
      }
      if (a[`${NameOfKey}`] < b[`${NameOfKey}`]) {
        return 1;
      }
      return 0;
    }
    return arrayList?.sort(compare);
  }
}
// for date object sort
function sortDateObjectListAsc(arrayList, NameOfKey) {
  if (arrayList?.length > 1 && NameOfKey) {
    function compare(a, b) {
      if (
        new Date(a[`${NameOfKey}`]).getTime() <
        new Date(b[`${NameOfKey}`]).getTime()
      ) {
        return -1;
      }
      if (
        new Date(a[`${NameOfKey}`]).getTime() >
        new Date(b[`${NameOfKey}`]).getTime()
      ) {
        return 1;
      }
      return 0;
    }
    return arrayList?.sort(compare);
  }
}

function sortDateObjectListDes(arrayList, NameOfKey) {
  if (arrayList?.length > 1 && NameOfKey) {
    function compare(a, b) {
      if (
        new Date(a[`${NameOfKey}`]).getTime() >
        new Date(b[`${NameOfKey}`]).getTime()
      ) {
        return -1;
      }
      if (
        new Date(a[`${NameOfKey}`]).getTime() <
        new Date(b[`${NameOfKey}`]).getTime()
      ) {
        return 1;
      }
      return 0;
    }
    return arrayList?.sort(compare);
  }
}
// need to check the thing
function getArrayItems(arr, n) {
  let result = new Array(n),
    len = arr.length,
    taken = new Array(len);
  if (n > len) {
    n = len;
  }
  while (n--) {
    let x = Math.floor(Math.random() * len);
    result[n] = arr[x in taken ? taken[x] : x];
    taken[x] = --len;
  }
  return result;
}

async function getBase64FromUrl(url) {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export const sortArrayListAsc = sortObjectListAsc;
export const sortArrayListDes = sortObjectListDes;
export const sortDateArrayListAsc = sortDateObjectListAsc;
export const sortDateArrayListDes = sortDateObjectListDes;

export const clearAllCookies = () => {
  // Clear session cookies
  document.cookie =
    "firstName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie = "lastName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};

export const redirectToLogin = () => {
  // const baseUrl = process.env.REACT_APP_BaseUrl?.split("/api")[0];
  // window.location.href = `${baseUrl}/saml/login`;
  window.location.href = "/";
};

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

// sessionUtils.js
export const SESSION_DIALOG_KEY = "sessionDialogActive";

export const setSessionDialogActive = (isActive) => {
  localStorage.setItem(SESSION_DIALOG_KEY, isActive);
};

export const isSessionDialogActive = () => {
  return localStorage.getItem(SESSION_DIALOG_KEY) === "true";
};

export const clearSessionDialog = () => {
  localStorage.removeItem(SESSION_DIALOG_KEY);
};
