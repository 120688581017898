import React, { useEffect, useState, useContext, useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Navbar from "../components/Navbar";
import PdfThumnailView from "../components/PdfThumbnailView";
import DocView from "../components/DocView";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { axiosRequest } from "../axiosConfig";

const ViewPage = (props) => {
  const history = useHistory();
  const [uploadedPdf, setUploadedPdf] = useState("");
  const [uploadFiles, setUploadeFiles] = useState("");
  const [pagesArray, setPagesArray] = useState([]);
  const [pageOriginalNum, setpageOriginalNum] = useState([]);
  const [currentPage, setCurrentPage] = useState("parent");
  const [cookies] = useCookies();
  const [isLocked, setIsLocked] = useState(false);

  // Check the user if and check the getting fax is locked or not
  // If it is locked then send to the Index page.
  useEffect(() => {
    if (!cookies?.firstName) {
      window?.location?.replace(`${window?.location?.origin}`);
    }
    let faxId = "";
    if (props?.location?.state?.pdfVal) {
      faxId = props?.location?.state?.pdfVal?.Id;
    } else if (props?.location?.state?.parent) {
      faxId = props?.location?.state?.parent?.Id;
    }

    axiosRequest
      .get(`${process.env.REACT_APP_BaseUrl}faxlocked/${faxId}`)
      .then((res) => {
        if (res.data.email && cookies.email !== res.data.email) {
          history.push({
            pathname: `/home`,
            state: {
              indexPageState: history?.location?.state?.indexPageState
                ? history?.location?.state?.indexPageState
                : { alias: [], searchCallerId: "", pageNumber: 1 },
            },
          });
        } else {
          setIsLocked(true);
        }
      })
      .catch((err) => {
        console.log(err, "isLocked");
        history.push({ pathname: `/`, state: {} });
      });
  }, []);

  //Get the pdf from fax id either it is parent of child.
  useEffect(() => {
    if (isLocked == true && props) {
      if (props?.location?.state?.pdfVal) {
        if (props?.location?.state?.pdfVal?.Id) {
          axiosRequest
            .get(
              `${process.env.REACT_APP_BaseUrl}viewpdf/${props?.location?.state?.pdfVal?.Id}`,
              {
                responseType: "blob",
              },
            )
            .then((res) => {
              let blob = new Blob([res.data], { type: "application/pdf" });
              setUploadeFiles(blob);
            })
            .catch((err) => {
              console.log(err, "viewpdf");
            });
        }
        let childPdfAry = props?.location?.state?.pdfVal?.ChildPdf;
        let childPdfAryLength =
          props?.location?.state?.pdfVal?.ChildPdf?.length;
        let childPagesAry = [];
        if (childPdfAryLength > 0) {
          for (let i = 0; i < childPdfAryLength; i++) {
            childPagesAry.push(childPdfAry[i].pages);
          }
          childPagesAry = childPagesAry.reduce((r, a) => r.concat(a), []);
          setPagesArray([...childPagesAry]);
        }
      } else if (props?.location?.state?.parent) {
        setCurrentPage("child");
        axiosRequest
          .get(
            `${process.env.REACT_APP_BaseUrl}viewpdf/${props?.location?.state?.parent?.Id}/${props?.location?.state?.child?.id}`,
            {
              responseType: "blob",
            },
          )
          .then((res) => {
            let blob = new Blob([res.data], { type: "application/pdf" });
            setUploadeFiles(blob);
          })
          .catch((err) => {
            console.log(err, "viewpdf");
          });
        let childPdfAryTemp = props?.location?.state?.child?.pages;
        setpageOriginalNum([...childPdfAryTemp]);
      }
    }
  }, [isLocked == true]);

  // Set the pdf to the -@react-pdf-viewer/thumbnail library.
  useEffect(() => {
    if (uploadFiles) {
      setUploadedPdf(uploadFiles);
    }
  }, [uploadFiles]);

  // Chached the pdf @react-pdf-viewer/thumbnail for this library.
  // <PdfThumnailView> in this component with thumbnail pdf will show.
  const cachedValue = useMemo(() => {
    return (
      <PdfThumnailView
        pdf={uploadedPdf}
        pagesArray={[...pagesArray]}
        pageOriginalNum={[...pageOriginalNum]}
      />
    );
  }, [uploadedPdf, pagesArray, pageOriginalNum]);

  // Pdf split, process and delete functionality in (<DocView/> ) this component.

  return (
    <>
      {cookies.firstName ? (
        <div>
          <Navbar />
          {uploadedPdf ? (
            <Container className="docview">
              {currentPage == "parent" ? (
                <DocView
                  currentPage={currentPage}
                  pdfTotalPages={parseInt(
                    props?.location?.state?.pdfVal?.Pages,
                  )}
                  chaildPdfPage={[...pagesArray]}
                  uploadedPdf={uploadedPdf}
                  pdfObject={props?.location?.state}
                ></DocView>
              ) : (
                <DocView
                  currentPage={currentPage}
                  pdfTotalPages={0}
                  chaildPdfPage={[]}
                  uploadedPdf={uploadedPdf}
                  pdfObject={props?.location?.state}
                ></DocView>
              )}
              <Row className="rowmargin">
                <Col className="mt-2 mb-4 inputpdf" sm={12}>
                  {/* {cachedValue} */}
                  <PdfThumnailView
                    pdf={uploadedPdf}
                    pagesArray={[...pagesArray]}
                    pageOriginalNum={[...pageOriginalNum]}
                  />
                </Col>
              </Row>
            </Container>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ViewPage;
