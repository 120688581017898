export const ShowPages = 5;
export const DocTypeForOrder = {
  DocType1: "Prescription",
  DocType2: "Detailed Written Order",
};
export const CommaAndHypenAndIntegerReg = /^(\d+(-\d+)?( ?,+ ?|$))+$/;
//  export const CommaAndHypenAndIntegerReg = /^(\d+(-\d+)?(,|$))+$/
export const stateCodes = [
  "AL",
  "Ak",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "VI",
  "WA",
  "WV",
  "WI",
  "WY",
];
export const IdealTimeForLogOut = 10;
export const IdealTimeGetFaxCall = 1;
//  export baseUrl = "https://bedard.app:8443/api/"
