import React from "react";
import { Modal, Button } from "react-bootstrap";

const SessionWarningDialog = ({
  remainingTime,
  onExtendSession,
  onLogout,
  isVisible,
}) => {
  return (
    <Modal show={isVisible} centered backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Session Expiring Soon</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Your session will expire in 5 minutes. Would you like to extend your
        session?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onLogout}>
          Logout
        </Button>
        <Button
          style={{ backgroundColor: "#013366", color: "white" }}
          onClick={onExtendSession}
        >
          Stay Logged In
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SessionWarningDialog;
