// src/components/SessionMonitor.jsx
import React, { useEffect, useState, useRef } from "react";
import SessionWarningDialog from "./SessionWarningDialog";
import { axiosRequest } from "../axiosConfig";
import {
  clearAllCookies,
  getCookie,
  redirectToLogin,
  setSessionDialogActive,
} from "../Utils";

const SessionMonitor = () => {
  const [showWarning, setShowWarning] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const warningDisplayed = useRef(false);
  const logoutTimeout = useRef(null);

  const WARNING_THRESHOLD = 5 * 60 * 1000;

  const refreshSession = async () => {
    try {
      await axiosRequest.post("/session/refresh");
      setShowWarning(false);
      setSessionDialogActive(false);
      warningDisplayed.current = false;
      if (logoutTimeout.current) {
        clearTimeout(logoutTimeout.current);
        logoutTimeout.current = null;
      }
      return true;
    } catch (error) {
      console.error("Failed to refresh session:", error);
      return false;
    }
  };

  const handleLogout = () => {
    setShowWarning(false);
    setSessionDialogActive(false);
    clearAllCookies();
    redirectToLogin();
  };

  const checkSession = async () => {
    if (getCookie("firstName")) {
      try {
        const response = await axiosRequest.get("/session/check");
        const timeRemaining = response.data.timeRemaining;

        if (timeRemaining < WARNING_THRESHOLD && !warningDisplayed.current) {
          warningDisplayed.current = true;
          setRemainingTime(timeRemaining);
          setShowWarning(true);
          setSessionDialogActive(true);

          // Set a timeout to logout when session expires
          if (logoutTimeout.current) {
            clearTimeout(logoutTimeout.current);
          }
          logoutTimeout.current = setTimeout(handleLogout, timeRemaining);
        }
      } catch (error) {
        // Only redirect if it's a 401 error
        if (error.response?.status === 401) {
          handleLogout();
        }
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(checkSession, WARNING_THRESHOLD);

    return () => {
      clearInterval(intervalId);
      if (logoutTimeout.current) {
        clearTimeout(logoutTimeout.current);
      }
    };
  }, []);

  return (
    <SessionWarningDialog
      isVisible={showWarning}
      remainingTime={remainingTime}
      onExtendSession={refreshSession}
      onLogout={handleLogout}
    />
  );
};

export default SessionMonitor;
