import axios from "axios";
import { clearAllCookies, getCookie, redirectToLogin } from "./Utils";

export const axiosRequest = axios.create({
  baseURL: process.env.REACT_APP_BaseUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json", // Changed from x-www-form-urlencoded
  },
});

axiosRequest.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      if (getCookie("firstName")) {
        clearAllCookies();
        redirectToLogin();
      }
    }
    return Promise.reject(error);
  },
);
