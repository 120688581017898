import React, { Fragment, useState, useEffect, useMemo, useRef } from "react";
import ProcessPDFView from "../components/ProcessPDFView";
import Navbar from "../components/Navbar";
import DropDownWithSearch from "../components/DropDownWithSearch";
import { Container, Col, Row, Button } from "react-bootstrap";
import { MdModeEditOutline } from "react-icons/md";
import { BsPlusLg, BsFillPersonFill, BsBagCheckFill } from "react-icons/bs";
import { FaEye, FaUserNurse } from "react-icons/fa";
import { GrMapLocation } from "react-icons/gr";
import { IoMdClose } from "react-icons/io";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import CommanSpinner from "../components/CommanSpinner";
import { useHistory } from "react-router-dom";
import SuccessErrorPopup from "../CommonComponents/SuccessErrorPopup";
import { stateCodes } from "../variable";
import { useCookies } from "react-cookie";
import { sortArrayListAsc } from "../Utils";
import { axiosRequest } from "../axiosConfig";

const ProcessComponent = (props) => {
  const history = useHistory();
  const [uploadedPdf, setUploadedPdf] = useState("");
  const [uploadFiles, setUploadeFiles] = useState("");
  const outSideRef = useRef(null);
  const [patientDetailpopup, setPatientDetailpopup] = useState(false);
  const [addBtnPopup, setaddBtnPopup] = useState(false);
  const [attachPatientPopup, setattachPatientPopup] = useState(false);
  const [createAttachPatientPopup, setcreateAttachPatientPopup] =
    useState(false);
  const [attachOrderPopup, setattachOrderPopup] = useState(false);
  const [createAttachOrderPopup, setcreateAttachOrderPopup] = useState(false);
  const [currentPage, setCurrentPage] = useState("parent");
  const [patientTypeDropdown, setpatientTypeDropdown] = useState("Existing");
  const [orderTypeDropdown, setorderTypeDropdown] = useState("Existing");
  const [providerTypeDropdown, setproviderTypeDropdown] = useState("Existing");
  const [therapyTypeDropdownList, settherapyTypeDropdownList] = useState([]);
  const [therapyTypeDropdown, settherapyTypeDropdown] = useState("Select");
  const [PriorityDropdown, setPriorityDropdown] = useState("Normal");
  const [locationProviderDropdown, setLocationProviderDropdown] = useState([]);
  const [selectedProviderLocation, setselectedProviderLocation] = useState({});
  const [selectedDocumentValue, setselectedDocumentValue] = useState("Select");
  const [documentTypeDropdownList, setdocumentTypeDropdownList] = useState([]);
  const [selectedPatientValue, setselectedPatientValue] = useState({
    name: "Patient not selected",
    data: {
      general: {
        name: {
          fullName: "Patient not selected",
        },
      },
    },
  });
  const [selectedOrderValue, setselectedOrderValue] = useState({
    name: "Order not selected",
  });
  const [selectedProviderValue, setselectedProviderValue] = useState({
    name: "Provider not selected",
  });
  let processedDataObj = {
    patientKeyValues: [],
    providerKeyValues: [],
    orderKeyValues: [],
  };
  const [processedData, setProcessedData] = useState(processedDataObj);
  const [processError, setProcessError] = useState(false);
  const [message, setMessage] = useState({ msg: "", for: "" });
  const [saveMsgSucessPopup, setsaveMsgSucessPopup] = useState(false);
  const [editable, setEditable] = useState(false);
  const [actualFaxResponseData, setActualFaxResponseData] = useState({});
  const [saveButtonEnable, setSaveButtonEnable] = useState(false);
  const [resetButtonEnable, setResetButtonEnable] = useState(false);
  const [moveFaxToTrash, setMoveFaxToTrash] = useState(true);
  const [moveChildFaxToTrash, setMoveChildFaxToTrash] = useState(true);
  const [isLastChild, setIsLastChild] = useState(false);
  const [isRedirected, setIsRedirected] = useState(true);
  let patientKeys = {
    patientKeyValues: {
      FirstName: "",
      LastName: "",
      Gender: "",
      DateOfBirth: "",
      Address: {
        addressLine: "",
        city: "",
        state: "",
        zip: "",
      },
      Contact: "",
    },
    providerKeyValues: {
      ProviderFirstName: "",
      ProviderLastName: "",
      ProviderPhone: "",
      ProviderFax: "",
      ProviderNPI: "",
      Address: {
        addressLine: "",
        city: "",
        state: "",
        zip: "",
      },
    },
    orderKeyValues: {
      DateWritten: "",
      LengthOfNeed: "",
      DiagnosisCode: "",
    },
  };
  const [patientErrorMessage, setPatientErrorMessage] = useState(patientKeys);
  let summaryErrParams = {
    patientType: "",
    documentType: "",
    orderType: "",
    providerType: "",
    priorityL: "",
    selectedPatient: "",
    selectedOrder: "",
    selectedProvider: "",
    providerLocation: "",
  };
  const [summaryParamsErrorMessage, setSummaryParamsErrorMessage] =
    useState(summaryErrParams);
  const [saveType, setSaveType] = useState("");
  const [loader, setLoader] = useState("");
  const [isLocked, setIsLocked] = useState(false);
  let staticPatientComponent = [
    {
      labelName: "Patient First Name",
      labelValue: "",
      labelType: "text",
      labelEditType: "text",
      labelBindValue: "FirstName",
      labelPlaceHolder: "Enter Patient First",
      labelStartColor: "red",
      labelHide: false,
    },
    {
      labelName: "Patient Last Name",
      labelValue: "",
      labelType: "text",
      labelEditType: "text",
      labelBindValue: "LastName",
      labelPlaceHolder: "Enter Patient Last Name",
      labelStartColor: "red",
      labelHide: false,
    },
    {
      labelName: "Patient Gender",
      labelValue: "",
      labelType: "text",
      labelEditType: "select",
      labelBindValue: "Gender",
      labelPlaceHolder: "Enter Patient Gender",
      labelStartColor: "red",
      labelHide: false,
    },
    {
      labelName: "Patient DOB",
      labelValue: "",
      labelType: "text",
      labelEditType: "date",
      labelBindValue: "DateOfBirth",
      labelPlaceHolder: "Enter Patient DOB",
      labelStartColor: "red",
      labelHide: false,
    },
    {
      labelName: "Patient Address",
      labelValue: {
        addressLine: "",
        city: "",
        state: "",
        zip: "",
      },
      labelAddressName: {
        city: "Patient City",
        state: "Patient State",
        zip: "Patient Zip",
      },
      labelType: "address",
      labelEditType: "address",
      labelBindValue: "Address",
      labelPlaceHolder: "Enter Patient Address",
      labelStartColor: "red",
      labelHide: false,
    },
    {
      labelName: "Patient Phone",
      labelValue: "",
      labelType: "text",
      labelEditType: "text",
      labelBindValue: "Contact",
      labelPlaceHolder: "Enter Patient Phone",
      labelStartColor: "red",
      labelHide: false,
    },
  ];
  let staticProviderComponent = [
    {
      labelName: "Provider First Name",
      labelValue: "",
      labelType: "text",
      labelEditType: "text",
      labelBindValue: "ProviderFirstName",
      labelPlaceHolder: "Enter Provider First Name",
      labelStartColor: "blue",
      labelHide: false,
    },
    {
      labelName: "Provider Last Name",
      labelValue: "",
      labelType: "text",
      labelEditType: "text",
      labelBindValue: "ProviderLastName",
      labelPlaceHolder: "Enter Provider Last Name",
      labelStartColor: "blue",
      labelHide: false,
    },
    {
      labelName: "Provider Phone",
      labelValue: "",
      labelType: "text",
      labelEditType: "text",
      labelBindValue: "ProviderPhone",
      labelPlaceHolder: "Enter Provider Phone",
      labelStartColor: "blue",
      labelHide: false,
    },
    {
      labelName: "Provider Fax",
      labelValue: "",
      labelType: "text",
      labelEditType: "text",
      labelBindValue: "ProviderFax",
      labelPlaceHolder: "Enter Provider Fax",
      labelStartColor: "blue",
      labelHide: false,
    },
    {
      labelName: "Provider NPI",
      labelValue: "",
      labelType: "text",
      labelEditType: "text",
      labelBindValue: "ProviderNPI",
      labelPlaceHolder: "Enter Provider NPI",
      labelStartColor: "blue",
      labelHide: false,
    },
    {
      labelName: "Provider Address",
      labelValue: {
        addressLine: "",
        city: "",
        state: "",
        zip: "",
      },
      labelAddressName: {
        city: "Provider City",
        state: "Provider State",
        zip: "Provider Zip",
      },
      labelType: "address",
      labelEditType: "address",
      labelBindValue: "Address",
      labelPlaceHolder: "Enter Provider Address",
      labelStartColor: "blue",
      labelHide: false,
    },
  ];
  let staticOrderComponent = [
    {
      labelName: "Date Written",
      labelValue: "",
      labelType: "text",
      labelEditType: "date",
      labelBindValue: "DateWritten",
      labelPlaceHolder: "Enter Date Written",
      labelStartColor: "blue",
      labelHide: false,
    },
    {
      labelName: "Length of Need",
      labelValue: "",
      labelType: "text",
      labelEditType: "text",
      labelBindValue: "LengthOfNeed",
      labelPlaceHolder: "Enter Length of Need",
      labelStartColor: "",
      labelHide: false,
    },
    {
      labelName: "Diagnosis Code",
      labelValue: "",
      labelType: "text",
      labelEditType: "text",
      labelBindValue: "DiagnosisCode",
      labelPlaceHolder: "Enter Diagnosis Code",
      labelStartColor: "",
      labelHide: false,
    },
  ];
  const [cookies] = useCookies();

  // If the user Cookies not set the the goes to the login page.
  if (!cookies?.firstName) {
    window?.location?.replace(`${window?.location?.origin}`);
  }

  // Closed the popup.
  const closePopup = () => {
    setaddBtnPopup(false);
    setattachPatientPopup(false);
    setattachOrderPopup(false);
    setcreateAttachPatientPopup(false);
    setcreateAttachOrderPopup(false);
  };
  const currentyr = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const currentDay = new Date().getDate();
  let currentdtemp = new Date();
  const year = currentdtemp.getFullYear();
  const month = String(currentdtemp.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(currentdtemp.getDate()).padStart(2, "0"); // Get day and pad with 0 if necessary
  const currentdt = `${year}-${month}-${day}`;
  const maxDate = new Date("9999-12-31")?.toISOString()?.split("T")[0];
  const [cursorSelectetion, setCursorSelectetion] = useState(0);
  const [cursorSelectetionPhone, setCursorSelectetionPhone] = useState(0);
  const [cursorSelectetionFax, setCursorSelectetionFax] = useState(0);

  function formatDate(date, val) {
    let month = date.getMonth() + 1;
    month = month % 12;
    month = month ? "0" + month : "12";
    let date1 = date.getDate();
    date1 = "0" + date1;
    return month?.slice(-2) + "/" + date1.slice(-2) + "/" + date.getFullYear();
  }
  // This is saving formate for date.
  function saveDateFormat(date) {
    let month = date.getMonth() + 1;
    month = month % 12;
    month = month ? "0" + month : "12";
    let date1 = date.getDate();
    date1 = "0" + date1;
    return date.getFullYear() + "-" + month?.slice(-2) + "-" + date1.slice(-2);
  }

  // In this useEffect we check the faxid having any one is locked if there then,
  // it moves home page else check the user is same then it will continue.
  useEffect(() => {
    let faxId = "";
    if (props?.location?.state?.pdfVal) {
      faxId = props?.location?.state?.pdfVal?.Id;
    } else if (props?.location?.state?.parent) {
      faxId = props?.location?.state?.parent?.Id;
    }

    axiosRequest
      .get(`${process.env.REACT_APP_BaseUrl}faxlocked/${faxId}`)
      .then((res) => {
        if (res.data.email && cookies.email !== res.data.email) {
          history.push({
            pathname: `/home`,
            state: {
              indexPageState: history?.location?.state?.indexPageState
                ? history?.location?.state?.indexPageState
                : { alias: [], searchCallerId: "", pageNumber: 1 },
            },
          });
        } else {
          setIsLocked(true);
        }
      })
      .catch((err) => {
        console.log(err, "isLocked");
        history.push({ pathname: `/`, state: {} });
      });
  }, []);

  // If the fax is not locked then get the pdf and get calls for initial load.
  useEffect(() => {
    if (isLocked == true && props) {
      let url = "";
      if (props?.location?.state?.pdfVal?.Id) {
        url = `${process.env.REACT_APP_BaseUrl}viewpdf/${props?.location?.state?.pdfVal?.Id}`;
        getProcessFax(props?.location?.state?.pdfVal?.Id, "", false);
      } else {
        url = `${process.env.REACT_APP_BaseUrl}viewpdf/${props?.location?.state?.parent?.Id}/${props?.location?.state?.child?.id}`;
        getProcessFax(
          props?.location?.state?.parent?.Id,
          props?.location?.state?.child?.id,
          false,
        );
        setCurrentPage("child");
        let totalPages = parseInt(props?.location?.state?.parent.Pages);
        let totalPagesArray = Array.from(
          Array(totalPages),
          (_, index) => index + 1,
        );
        let childpages = props?.location?.state?.parent.ChildPdf;
        let childPagesArray = [];
        childpages?.forEach((el) => {
          //  will add once the flag add.
          if (
            el.pages?.length > 1 &&
            (el?.deleted == true || el.id == props?.location?.state?.child?.id)
          ) {
            el.pages.forEach((p) => {
              childPagesArray.push(p);
            });
          } else if (
            el.pages?.length == 1 &&
            (el?.deleted == true || el.id == props?.location?.state?.child?.id)
          ) {
            childPagesArray.push(el.pages[0]);
          }
        });
        if (childPagesArray?.length == totalPagesArray?.length) {
          setIsLastChild(true);
        }
      }
      axiosRequest
        .get(url, {
          responseType: "blob",
        })
        .then((res) => {
          let blob = new Blob([res.data], { type: "application/pdf" });
          setUploadeFiles(blob);
        })
        .catch((err) => {
          console.log(err, "viewpdf");
        });
      getTherapyTypeList();
      getDocumentTypeList();
      if (
        props?.location?.state?.pdfVal?.Status == "Processing" ||
        props?.location?.state?.parent?.Status == "Processing"
      ) {
        setResetButtonEnable(true);
      }
    }
  }, [isLocked == true]);

  useEffect(() => {
    if (cursorSelectetion > 0) {
      var texta = document.getElementsByClassName("CursurText")[0];
      texta.setSelectionRange(cursorSelectetion, cursorSelectetion, "forward");
      setCursorSelectetion(0);
    }
    if (cursorSelectetionPhone > 0) {
      var texta = document.getElementsByClassName("CursurTextPhone")[0];
      texta.setSelectionRange(
        cursorSelectetionPhone,
        cursorSelectetionPhone,
        "forward",
      );
      setCursorSelectetionPhone(0);
    }
    if (cursorSelectetionFax > 0) {
      var texta = document.getElementsByClassName("CursurTextFax")[0];
      texta.setSelectionRange(
        cursorSelectetionFax,
        cursorSelectetionFax,
        "forward",
      );
      setCursorSelectetionFax(0);
    }
  }, [cursorSelectetion, cursorSelectetionPhone, cursorSelectetionFax]);

  //This is the function for getting the values from OCR and saved from database
  // and it formated and set to the associated object.
  const getProcessFax = (parent, child, reset) => {
    let url = "";
    setLoader("processFaxLoader");
    if (child == "") {
      if (reset == true) {
        url = `${process.env.REACT_APP_BaseUrl}resetfax/${parent}`;
      } else {
        url = `${process.env.REACT_APP_BaseUrl}processfax/${parent}`;
      }
    } else {
      if (reset == true) {
        url = `${process.env.REACT_APP_BaseUrl}resetfax/${parent}/${child}`;
      } else {
        url = `${process.env.REACT_APP_BaseUrl}processfax/${parent}/${child}`;
      }
    }
    axiosRequest
      .get(url)
      .then((res) => {
        if (typeof res.data == "string" && res.data.length > 2) {
          setMessage({ msg: res.data, for: "error" });
          setProcessError(true);
        }
        setActualFaxResponseData(res.data);
        let ProcessFaxData = {};
        let providerData = {};
        let orderData = {};
        let actualArrayRes = res.data;
        let contacts =
          actualArrayRes?.patientMaster?.contacts?.contacts?.length > 0
            ? actualArrayRes?.patientMaster?.contacts?.contacts
            : "";
        let contactIndex =
          contacts?.length > 0
            ? contacts?.findIndex((el) => {
                return el?.type == "Home";
              })
            : -1;
        ProcessFaxData = {
          FirstName: `${
            actualArrayRes?.patientMaster?.general?.name?.firstName
              ? actualArrayRes?.patientMaster?.general?.name?.firstName
              : ""
          }`,
          LastName: `${
            actualArrayRes?.patientMaster?.general?.name?.lastName
              ? actualArrayRes?.patientMaster?.general?.name?.lastName
              : ""
          }`,
          DateOfBirth: `${
            actualArrayRes?.patientMaster?.general?.dateOfBirth
              ? actualArrayRes?.patientMaster?.general?.dateOfBirth
              : ""
          }`,
          Gender: `${
            actualArrayRes?.patientMaster?.general?.gender
              ? actualArrayRes?.patientMaster?.general?.gender
              : ""
          }`,
          Contact: `${
            contacts &&
            contactIndex !== -1 &&
            contacts[contactIndex]?.value !== null
              ? contacts[contactIndex]?.value
              : ""
          }`,
          Address: actualArrayRes?.patientMaster?.addresses?.patient
            ? actualArrayRes?.patientMaster?.addresses?.patient
            : {
                addressLine: "",
                city: "",
                state: "",
                zip: "",
              },
        };
        providerData = {
          ProviderFirstName: `${
            actualArrayRes?.referringProvider?.general?.name?.firstName
              ? actualArrayRes?.referringProvider?.general?.name?.firstName
              : ""
          }`,
          ProviderLastName: `${
            actualArrayRes?.referringProvider?.general?.name?.lastName
              ? actualArrayRes?.referringProvider?.general?.name?.lastName
              : ""
          }`,
          ProviderPhone: `${
            actualArrayRes?.referringProvider?.primaryLocation?.contacts?.phone
              ? actualArrayRes?.referringProvider?.primaryLocation?.contacts
                  ?.phone
              : ""
          }`,
          ProviderFax: `${
            actualArrayRes?.referringProvider?.primaryLocation?.contacts?.fax
              ? actualArrayRes?.referringProvider?.primaryLocation?.contacts
                  ?.fax
              : ""
          }`,
          ProviderNPI: `${
            actualArrayRes?.referringProvider?.general?.npi
              ? actualArrayRes?.referringProvider?.general?.npi
              : ""
          }`,
          Address: {
            addressLine: actualArrayRes?.referringProvider?.primaryLocation
              ?.address?.addressLine
              ? actualArrayRes?.referringProvider?.primaryLocation?.address
                  ?.addressLine
              : "",
            city: actualArrayRes?.referringProvider?.primaryLocation?.address
              ?.city
              ? actualArrayRes?.referringProvider?.primaryLocation?.address
                  ?.city
              : "",
            state: actualArrayRes?.referringProvider?.primaryLocation?.address
              ?.state
              ? actualArrayRes?.referringProvider?.primaryLocation?.address
                  ?.state
              : "",
            zip: actualArrayRes?.referringProvider?.primaryLocation?.address
              ?.zip
              ? actualArrayRes?.referringProvider?.primaryLocation?.address?.zip
              : "",
          },
        };
        orderData = {
          DateWritten: `${
            actualArrayRes?.order?.prescription?.effectiveDate
              ? actualArrayRes?.order?.prescription?.effectiveDate
              : currentdt
          }`,
          LengthOfNeed: `${
            actualArrayRes?.order?.prescription?.products[0]?.primitive
              ?.lengthOfNeed
              ? actualArrayRes?.order?.prescription?.products[0]?.primitive
                  ?.lengthOfNeed
              : 1
          }`,
          DiagnosisCode: `${
            actualArrayRes?.order?.prescription?.products[0]?.primitive
              ?.diagnosisCodes[0]
              ? actualArrayRes?.order?.prescription?.products[0]?.primitive
                  ?.diagnosisCodes[0]
              : ""
          }`,
        };
        setpatientTypeDropdown(
          `${actualArrayRes?.patient_type ? actualArrayRes?.patient_type : ""}`,
        );
        setorderTypeDropdown(
          `${actualArrayRes?.order_type ? actualArrayRes?.order_type : ""}`,
        );
        setproviderTypeDropdown(
          `${
            actualArrayRes?.provider_type ? actualArrayRes?.provider_type : ""
          }`,
        );
        if (actualArrayRes?.patientMaster?.id) {
          setselectedPatientValue({
            name: `${
              actualArrayRes?.patientMaster?.general?.name?.fullName
                ? actualArrayRes?.patientMaster?.general?.name?.fullName
                : ""
            } ${
              actualArrayRes?.patientMaster?.general?.dateOfBirth
                ? "(" +
                  formatDate(
                    new Date(
                      actualArrayRes?.patientMaster?.general?.dateOfBirth?.includes(
                        "T",
                      )
                        ? actualArrayRes?.patientMaster?.general?.dateOfBirth
                        : actualArrayRes?.patientMaster?.general?.dateOfBirth?.replaceAll(
                            "-",
                            "/",
                          ),
                    ),
                  ) +
                  ")"
                : ""
            }`,
            data: actualArrayRes?.patientMaster
              ? actualArrayRes?.patientMaster
              : {},
          });
        }
        if (actualArrayRes?.order?.id) {
          setselectedOrderValue({
            name: `${
              actualArrayRes?.order?.displayId
                ? actualArrayRes?.order?.displayId
                : ""
            }`,
            data: actualArrayRes?.order ? actualArrayRes?.order : {},
          });
        }
        if (actualArrayRes?.referringProvider?.id) {
          setselectedProviderValue({
            name: `${
              actualArrayRes?.referringProvider?.general?.name?.firstName
                ? actualArrayRes?.referringProvider?.general?.name?.firstName
                : ""
            } ${
              actualArrayRes?.referringProvider?.general?.name?.lastName
                ? actualArrayRes?.referringProvider?.general?.name?.lastName
                : ""
            } ${
              actualArrayRes?.referringProvider?.general?.npi
                ? "(" + actualArrayRes?.referringProvider?.general?.npi + ")"
                : ""
            }`,
            data: actualArrayRes?.referringProvider
              ? actualArrayRes?.referringProvider
              : {},
          });
          if (actualArrayRes?.referringProvider?.primaryLocation) {
            setselectedProviderLocation({
              name: `${actualArrayRes?.referringProvider?.primaryLocation?.address?.addressLine}, ${actualArrayRes?.referringProvider?.primaryLocation?.address?.city}, ${actualArrayRes?.referringProvider?.primaryLocation?.address?.state}, ${actualArrayRes?.referringProvider?.primaryLocation?.address?.zip}`,
              data: actualArrayRes?.referringProvider?.primaryLocation
                ? actualArrayRes?.referringProvider?.primaryLocation
                : {},
            });
          }
        }
        setselectedDocumentValue(
          `${
            actualArrayRes?.document_type ? actualArrayRes?.document_type : ""
          }`,
        );
        settherapyTypeDropdown(
          `${actualArrayRes?.product_type ? actualArrayRes?.product_type : ""}`,
        );
        setPriorityDropdown(
          `${
            actualArrayRes?.order?.priorityLevel == "SameDay"
              ? "Urgent"
              : actualArrayRes?.order?.priorityLevel
              ? actualArrayRes?.order?.priorityLevel
              : ""
          }`,
        );

        //set the patient value
        let keysare = Object.keys(ProcessFaxData);
        staticPatientComponent?.forEach((element) => {
          if (keysare?.indexOf(element.labelBindValue) !== -1) {
            let addressTemp = {
              addressLine: "",
              city: "",
              state: "",
              zip: "",
            };
            if (element.labelBindValue === "Address") {
              addressTemp.addressLine =
                ProcessFaxData[element.labelBindValue]?.addressLine === null
                  ? ""
                  : ProcessFaxData[element.labelBindValue]?.addressLine;
              addressTemp.city =
                ProcessFaxData[element.labelBindValue]?.city === null
                  ? ""
                  : ProcessFaxData[element.labelBindValue]?.city;
              addressTemp.state =
                ProcessFaxData[element.labelBindValue]?.state === null
                  ? ""
                  : ProcessFaxData[element.labelBindValue]?.state;
              addressTemp.zip =
                ProcessFaxData[element.labelBindValue]?.zip === null
                  ? ""
                  : ProcessFaxData[element.labelBindValue]?.zip;
              element.labelValue = addressTemp;
            } else if (element.labelBindValue === "DateOfBirth") {
              element.labelValue = ProcessFaxData[element?.labelBindValue]
                ? formatDate(
                    new Date(
                      ProcessFaxData[element?.labelBindValue]?.includes("T")
                        ? ProcessFaxData[element?.labelBindValue]
                        : ProcessFaxData[element?.labelBindValue]?.replaceAll(
                            "-",
                            "/",
                          ),
                    ),
                  )
                : "";
            } else if (element.labelBindValue === "Contact") {
              element.labelValue = ProcessFaxData[element?.labelBindValue]
                ? formatPhoneNumber(ProcessFaxData[element?.labelBindValue])
                : "";
            } else {
              element.labelValue = ProcessFaxData[element.labelBindValue];
            }
            if (actualArrayRes?.patient_type == "Existing") {
              element.labelGreyout = true;
            }
          }
        });
        //set the provider value
        let keysareProvider = Object.keys(providerData);
        staticProviderComponent?.forEach((element) => {
          if (keysareProvider?.indexOf(element.labelBindValue) !== -1) {
            element.labelValue = providerData[element.labelBindValue];
            if (actualArrayRes?.provider_type == "Existing") {
              element.labelGreyout = true;
            }
            if (actualArrayRes?.order_type == "Existing") {
              element.labelHide = true;
            }
            if (
              element.labelBindValue === "ProviderPhone" ||
              element.labelBindValue === "ProviderFax"
            ) {
              element.labelValue = providerData[element?.labelBindValue]
                ? formatPhoneNumber(providerData[element?.labelBindValue])
                : "";
            }
          }
        });
        //set the order value
        let keysareOrder = Object.keys(orderData);
        staticOrderComponent?.forEach((element) => {
          if (keysareOrder?.indexOf(element.labelBindValue) !== -1) {
            element.labelValue = orderData[element.labelBindValue];
            if (actualArrayRes?.order_type == "Existing") {
              element.labelHide = true;
            }
          }
        });
        // add the value to the processed data.
        let processedDataObj = {
          patientKeyValues: [],
          providerKeyValues: [],
          orderKeyValues: [],
        };
        processedDataObj.patientKeyValues = [...staticPatientComponent];
        processedDataObj.providerKeyValues = [...staticProviderComponent];
        processedDataObj.orderKeyValues = [...staticOrderComponent];
        setProcessedData(processedDataObj);
        setLoader("");
        if (reset == true) {
          setSaveType("reset");
          setResetButtonEnable(false);
        }
      })
      .catch((err) => {
        console.log(err, "processfax");
        if (err?.response?.data?.length < 2) {
          setMessage({ msg: err?.response?.data[0]?.Message, for: "error" });
          setProcessError(true);
        } else {
          setMessage({ msg: err?.response?.data, for: "error" });
          setProcessError(true);
        }
        setLoader("");
      });
  };

  // This is function for getting the list of Therapy type.
  const getTherapyTypeList = () => {
    axiosRequest
      .get(`${process.env.REACT_APP_BaseUrl}therapytypelist`)
      .then((res) => {
        let actualTherapyArray = res.data;
        let therapyArray = [];
        let len = actualTherapyArray?.content?.length;

        for (let i = 0; i < len; i++) {
          therapyArray.push({
            Id: `${
              actualTherapyArray?.content[i]?.product_type_id
                ? actualTherapyArray?.content[i]?.product_type_id
                : ""
            }`,
            Type: `${
              actualTherapyArray?.content[i]?.product_type_name
                ? actualTherapyArray?.content[i]?.product_type_name
                : ""
            }`,
          });
        }
        therapyArray = sortArrayListAsc(therapyArray, "Type");
        settherapyTypeDropdownList([...therapyArray]);
      })
      .catch((err) => {
        console.log(err, "therapytypelist");
      });
  };

  // This is function for getting the list of Document type.
  const getDocumentTypeList = () => {
    axiosRequest
      .get(`${process.env.REACT_APP_BaseUrl}documenttypelist`)
      .then((res) => {
        let actualDocumentArray = res.data;
        let documentArray = [];
        let len = actualDocumentArray?.content?.length;

        for (let i = 0; i < len; i++) {
          documentArray.push({
            documentType: `${
              actualDocumentArray?.content[i]?.document
                ? actualDocumentArray?.content[i]?.document
                : ""
            }`,
          });
        }
        documentArray = sortArrayListAsc(documentArray, "documentType");
        setdocumentTypeDropdownList([...documentArray]);
      })
      .catch((err) => {
        console.log(err, "documenttypelist");
      });
  };

  // Set the pdf to the -@react-pdf-viewer library.
  useEffect(() => {
    if (uploadFiles) {
      setUploadedPdf(uploadFiles);
    }
  }, [uploadFiles]);

  // Drop down auto close event bind and unbind.
  useEffect(() => {
    function handleClickOutside(event) {
      if (outSideRef.current && !outSideRef.current.contains(event.target)) {
        if (patientDetailpopup) {
          setPatientDetailpopup(false);
        }
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  }, [outSideRef, patientDetailpopup]);

  // These are some constant regex.
  const lengthOfNeedRegex = /^(0?[1-9]|[1-9][0-9])$/;
  const nameRegex = new RegExp("[A-Za-zs]", "g");
  const nameSpacereg = new RegExp("[A-Za-z- s]", "g");
  const numRegex = /^[0-9\b]+$/;
  const cityRegex = /^[A-Za-z\s]*$/;

  const dateValidation = (date) => {
    if (date) {
      let year = date?.split("-")[0];
      let month = date?.split("-")[1];
      let day = date?.split("-")[2];
      if (!(year <= currentyr)) {
        return currentdt;
      } else if (!(month <= currentMonth) && year == currentyr) {
        return currentdt;
      } else if (
        !(day <= currentDay) &&
        month == currentMonth &&
        year == currentyr
      ) {
        return currentdt;
      } else if (year?.length > 4) {
        year = year?.slice(0, 4);
        let datePass = `${year}-${date?.split("-")[1]}-${date?.split("-")[2]}`;
        return datePass;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  // This is on change method will add the value on when event is triggered.
  const editeValue = (e, key) => {
    let keyDataTemp = JSON.parse(JSON.stringify(processedData[key]));
    keyDataTemp = keyDataTemp?.map((object) => {
      if (object.labelBindValue == e.target.name) {
        if (
          e.target.name == "FirstName" ||
          e.target.name == "LastName" ||
          e.target.name == "ProviderFirstName" ||
          e.target.name == "ProviderLastName"
        ) {
          if (nameSpacereg?.test(e.nativeEvent.data)) {
            return { ...object, labelValue: e.target.value };
          } else {
            return {
              ...object,
              labelValue: e.target.value?.slice(0, e.target.value?.length - 1),
            };
          }
        } else if (
          e.target.name == "Contact" ||
          e.target.name == "ProviderPhone" ||
          e.target.name == "ProviderFax"
        ) {
          // cursor issue for Providerphone,Providerfax,Patietnphone
          const cursorIssue = (
            length,
            objectLength,
            selectionStart,
            selectionEnd,
            setCursorState,
          ) => {
            if (length <= objectLength && length < 15) {
              setCursorState(selectionStart);
            } else if (length >= objectLength && length < 15) {
              if (selectionEnd == 4) {
                if (length < 5) {
                  setCursorState(selectionEnd + 3);
                } else {
                  setCursorState(selectionEnd + 2);
                }
              } else if (selectionEnd == 5) {
                setCursorState(selectionEnd + 2);
              } else if (selectionEnd == 6) {
                setCursorState(selectionEnd + 1);
              } else if (selectionEnd == 10) {
                setCursorState(selectionEnd + 1);
              } else {
                setCursorState(selectionEnd);
              }
            } else if (length >= 15) {
              if (selectionEnd == 5) {
                setCursorState(selectionEnd + 2);
              } else if (selectionEnd == 6) {
                setCursorState(selectionEnd + 1);
              } else if (selectionEnd == 10) {
                setCursorState(selectionEnd + 1);
              } else {
                setCursorState(selectionEnd);
              }
            }
          };
          if (e.target.name == "ProviderPhone") {
            cursorIssue(
              e.target.value?.length,
              object.labelValue?.length,
              e.target.selectionStart,
              e.target.selectionEnd,
              setCursorSelectetionPhone,
            );
          } else if (e.target.name == "ProviderFax") {
            cursorIssue(
              e.target.value?.length,
              object.labelValue?.length,
              e.target.selectionStart,
              e.target.selectionEnd,
              setCursorSelectetionFax,
            );
          } else {
            cursorIssue(
              e.target.value?.length,
              object.labelValue?.length,
              e.target.selectionStart,
              e.target.selectionEnd,
              setCursorSelectetion,
            );
          }
          if (numRegex?.test(e.nativeEvent.data)) {
            return {
              ...object,
              labelValue: formatPhoneNumber(e.target.value?.slice(0, 14)),
            };
          } else if (
            e.target.value &&
            numRegex?.test(e.target.value?.replace(/[- )(]/g, ""))
          ) {
            return { ...object, labelValue: formatPhoneNumber(e.target.value) };
          } else if (
            e.target.value &&
            !numRegex?.test(e.target.value?.replace(/[- )(]/g, ""))
          ) {
            return { ...object };
          }
        } else if (
          e.target.name == "DateOfBirth" ||
          e.target.name == "DateWritten"
        ) {
          let inputVal = e.target.value;
          if (inputVal) {
            return { ...object, labelValue: inputVal };
          }
          // else{
          //   return {...object}
          // }
        } else if (e.target.name == "LengthOfNeed") {
          if (lengthOfNeedRegex?.test(e.target.value)) {
            object.labelValue = e.target.value;
            return { ...object, labelValue: e.target.value };
          } else if (e.nativeEvent.data == null) {
            return { ...object, labelValue: "" };
          } else {
            return { ...object };
          }
        } else if (e.target.name == "ProviderNPI") {
          if (e.target.value && numRegex?.test(e.target.value)) {
            return { ...object, labelValue: e.target.value?.slice(0, 10) };
          } else if (e.target.value && !numRegex?.test(e.target.value)) {
            return { ...object };
          }
        }
        return { ...object, labelValue: e.target.value };
      } else if (
        object.labelBindValue == "Address" &&
        (e.target.name == "addressLine" ||
          e.target.name == "city" ||
          e.target.name == "state" ||
          e.target.name == "zip")
      ) {
        if (e.target.name == "zip" && !(e.target.value?.length <= 5)) {
          object.labelValue[e.target.name] = e.target.value?.slice(0, 5);
        } else if (
          e.target.name == "zip" &&
          e.target.value?.length > 0 &&
          !numRegex?.test(e.target.value)
        ) {
          object.labelValue[e.target.name] = object.labelValue[e.target.name];
        } else {
          if (e.target.name == "city") {
            if (cityRegex?.test(e.nativeEvent.data)) {
              object.labelValue[e.target.name] = e.target.value;
            }
          } else if (e.target.name == "state") {
            if (nameRegex?.test(e.nativeEvent.data)) {
              object.labelValue[e.target.name] = e.target.value.toUpperCase();
            }
          } else {
            object.labelValue[e.target.name] = e.target.value;
          }
        }
        return { ...object };
      }
      return object;
    });
    let processDataTemp = JSON.parse(JSON.stringify(processedData));
    processDataTemp[key] = keyDataTemp;
    setProcessedData(processDataTemp);
    let ErrorMessage = patientErrorMessage;
    if (
      e.target.name == "addressLine" ||
      e.target.name == "zip" ||
      e.target.name == "city" ||
      e.target.name == "state"
    ) {
      ErrorMessage[key]["Address"][e.target.name] = "";
    } else if (
      e.target.name == "ProviderPhone" ||
      e.target.name == "ProviderFax"
    ) {
      ErrorMessage[key]["ProviderPhone"] = "";
      ErrorMessage[key]["ProviderFax"] = "";
    } else {
      ErrorMessage[key][e.target.name] = "";
    }
    setPatientErrorMessage({ ...ErrorMessage });
  };

  //This is the format for phone number for usa people.
  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6,
    )}-${phoneNumber.slice(6, 10)}`;
  }

  // This is the toggle method for edit penciel icon.
  const editButtonClick = () => {
    setEditable(!editable);
  };

  // This is the common method for setting up parameter for api.(create patient,create order and ...)
  const setParams = (type) => {
    let paramsNeed = {};
    let parent = "";
    let child = "";
    let moveFaxToTrashLocal = moveFaxToTrash;
    let moveChildFaxToTrashLocal = moveChildFaxToTrash;
    if (currentPage == "parent") {
      parent = props?.location?.state?.pdfVal?.Id;
      moveChildFaxToTrashLocal = false;
    } else {
      parent = props?.location?.state?.parent?.Id;
      child = props?.location?.state?.child?.id;
      if (isLastChild && moveChildFaxToTrashLocal) {
        moveFaxToTrashLocal = true;
      } else {
        moveFaxToTrashLocal = false;
      }
    }
    paramsNeed = {
      id: actualFaxResponseData?.id ? actualFaxResponseData?.id : null,
      faxId: parent,
      process_id: actualFaxResponseData?.process_id
        ? actualFaxResponseData?.process_id
        : null,
      childFaxId: child,
      patient_type: patientTypeDropdown,
      order_type: orderTypeDropdown,
      provider_type: providerTypeDropdown,
      document_type: selectedDocumentValue,
      product_type: therapyTypeDropdown,
      moveFaxToTrash: moveFaxToTrashLocal,
      moveChildFaxToTrash: moveChildFaxToTrashLocal,
      patientMaster: {
        locationId: actualFaxResponseData?.patientMaster?.locationId
          ? actualFaxResponseData?.patientMaster?.locationId
          : null,
        facilityLocationId: actualFaxResponseData?.patientMaster
          ?.facilityLocationId
          ? actualFaxResponseData?.patientMaster?.facilityLocationId
          : null,
        //patient_id this is for internal bedard param
        patient_id: selectedPatientValue?.data?.id
          ? selectedPatientValue?.data?.id
          : null,
        id: selectedPatientValue?.data?.id
          ? selectedPatientValue?.data?.id
          : null,
        tags: actualFaxResponseData?.patientMaster?.tags
          ? actualFaxResponseData?.patientMaster?.tags
          : null,
        general: {
          name: {
            fullName: actualFaxResponseData?.patientMaster?.general?.name
              ?.fullName
              ? actualFaxResponseData?.patientMaster?.general?.name?.fullName
              : "",
            lastName: processedData.patientKeyValues[1].labelValue,
            firstName: processedData.patientKeyValues[0].labelValue,
            middleName: "",
            // middleName: actualFaxResponseData?.patientMaster?.general?.name?.middleName ? actualFaxResponseData?.patientMaster?.general?.name?.middleName:""
          },
          prefix: actualFaxResponseData?.patientMaster?.general?.prefix
            ? actualFaxResponseData?.patientMaster?.general?.prefix
            : null,
          dateOfBirth: processedData?.patientKeyValues[3]?.labelValue
            ? saveDateFormat(
                new Date(
                  processedData?.patientKeyValues[3]?.labelValue?.includes("T")
                    ? processedData?.patientKeyValues[3]?.labelValue
                    : processedData?.patientKeyValues[3]?.labelValue?.replaceAll(
                        "-",
                        "/",
                      ),
                ),
              )
            : processedData?.patientKeyValues[3]?.labelValue,
          gender: processedData?.patientKeyValues[2]?.labelValue,
          ssn: actualFaxResponseData?.patientMaster?.general?.ssn
            ? actualFaxResponseData?.patientMaster?.general?.ssn
            : null,
          height: actualFaxResponseData?.patientMaster?.general?.height
            ? actualFaxResponseData?.patientMaster?.general?.height
            : null,
          maritalStatus: actualFaxResponseData?.patientMaster?.general
            ?.maritalStatus
            ? actualFaxResponseData?.patientMaster?.general?.maritalStatus
            : null,
          weight: actualFaxResponseData?.patientMaster?.general?.weight
            ? actualFaxResponseData?.patientMaster?.general?.weight
            : null,
          nickName: actualFaxResponseData?.patientMaster?.general?.nickName
            ? actualFaxResponseData?.patientMaster?.general?.nickName
            : null,
        },
        contacts: {
          contacts: [
            {
              value: processedData.patientKeyValues[5].labelValue?.replace(
                /[- )(]/g,
                "",
              ),
              type: actualFaxResponseData?.patientMaster?.contacts?.contacts
                ?.type
                ? actualFaxResponseData?.patientMaster?.contacts?.contacts?.type
                : "Home",
              ext: actualFaxResponseData?.patientMaster?.contacts?.contacts?.ext
                ? actualFaxResponseData?.patientMaster?.contacts?.contacts?.ext
                : null,
            },
          ],
          preferredContactType: actualFaxResponseData?.patientMaster?.contacts
            ?.preferredContactType
            ? actualFaxResponseData?.patientMaster?.contacts
                ?.preferredContactType
            : null,
          preferredCallTime: actualFaxResponseData?.patientMaster?.contacts
            ?.preferredCallTime
            ? actualFaxResponseData?.patientMaster?.contacts?.preferredCallTime
            : null,
        },
        displayId: selectedPatientValue?.data?.displayId
          ? selectedPatientValue?.data?.displayId
          : "",
        addresses: {
          patient: {
            state:
              processedData?.patientKeyValues[4]?.labelValue?.state?.toUpperCase(),
            addressLine:
              processedData?.patientKeyValues[4]?.labelValue?.addressLine,
            city: processedData?.patientKeyValues[4]?.labelValue?.city,
            zip: processedData?.patientKeyValues[4]?.labelValue?.zip,
            addressLine2: actualFaxResponseData?.patientMaster?.addresses
              ?.patient?.addressLine2
              ? actualFaxResponseData?.patientMaster?.addresses?.patient
                  ?.addressLine2
              : null,
          },
          skipDeliveryAddressVerification: actualFaxResponseData?.patientMaster
            ?.addresses?.skipDeliveryAddressVerification
            ? actualFaxResponseData?.patientMaster?.addresses
                ?.skipDeliveryAddressVerification
            : false,
          skipPatientAddressVerification: actualFaxResponseData?.patientMaster
            ?.addresses?.skipPatientAddressVerification
            ? actualFaxResponseData?.patientMaster?.addresses
                ?.skipPatientAddressVerification
            : false,
          delivery: actualFaxResponseData?.patientMaster?.addresses?.delivery
            ? actualFaxResponseData?.patientMaster?.addresses?.delivery
            : null,
        },
        signatureOnFile: actualFaxResponseData?.patientMaster?.signatureOnFile
          ? actualFaxResponseData?.patientMaster?.signatureOnFile
          : null,
        employerContact: actualFaxResponseData?.patientMaster?.employerContact
          ? actualFaxResponseData?.patientMaster?.employerContact
          : null,
        taxExemption: actualFaxResponseData?.patientMaster?.taxExemption
          ? actualFaxResponseData?.patientMaster?.taxExemption
          : null,
        status: actualFaxResponseData?.patientMaster?.status
          ? actualFaxResponseData?.patientMaster?.status
          : null,
        medicalReleaseInfo: actualFaxResponseData?.patientMaster
          ?.medicalReleaseInfo
          ? actualFaxResponseData?.patientMaster?.medicalReleaseInfo
          : null,
        emergencyContact: actualFaxResponseData?.patientMaster?.emergencyContact
          ? actualFaxResponseData?.patientMaster?.emergencyContact
          : null,
        eesponsibleContact: actualFaxResponseData?.patientMaster
          ?.eesponsibleContact
          ? actualFaxResponseData?.patientMaster?.eesponsibleContact
          : null,
      },
      order: {
        id: selectedOrderValue?.data?.id ? selectedOrderValue?.data?.id : "",
        type: selectedOrderValue?.data?.type
          ? selectedOrderValue?.data?.type
          : "",
        patient: selectedOrderValue?.data?.patient
          ? selectedOrderValue?.data?.patient
          : null,
        displayId: selectedOrderValue?.data?.displayId
          ? selectedOrderValue?.data?.displayId
          : "",
        archived: selectedOrderValue?.data?.archived
          ? selectedOrderValue?.data?.archived
          : false,
        audit: {
          createdBy: {
            fullName: selectedOrderValue?.data?.audit?.createdBy?.fullName
              ? selectedOrderValue?.data?.audit?.createdBy?.fullName
              : null,
            lastName: selectedOrderValue?.data?.audit?.createdBy?.lastName
              ? selectedOrderValue?.data?.audit?.createdBy?.lastName
              : "",
            firstName: selectedOrderValue?.data?.audit?.createdBy?.firstName
              ? selectedOrderValue?.data?.audit?.createdBy?.firstName
              : "",
            middleName: selectedOrderValue?.data?.audit?.createdBy?.middleName
              ? selectedOrderValue?.data?.audit?.createdBy?.middleName
              : "",
          },
          modifiedOn: selectedOrderValue?.data?.audit?.modifiedOn
            ? selectedOrderValue?.data?.audit?.modifiedOn
            : "",
          createdOn: selectedOrderValue?.data?.audit?.createdOn
            ? selectedOrderValue?.data?.audit?.createdOn
            : "",
          modifiedBy: {
            fullName: selectedOrderValue?.data?.audit?.modifiedBy?.fullName
              ? selectedOrderValue?.data?.audit?.modifiedBy?.fullName
              : null,
            lastName: selectedOrderValue?.data?.audit?.modifiedBy?.lastName
              ? selectedOrderValue?.data?.audit?.modifiedBy?.lastName
              : null,
            firstName: selectedOrderValue?.data?.audit?.modifiedBy?.firstName
              ? selectedOrderValue?.data?.audit?.modifiedBy?.firstName
              : null,
            middleName: selectedOrderValue?.data?.audit?.modifiedBy?.middleName
              ? selectedOrderValue?.data?.audit?.modifiedBy?.middleName
              : null,
          },
        },
        startDate: selectedOrderValue?.data?.startDate
          ? selectedOrderValue?.data?.startDate
          : "",
        priorityLevel: PriorityDropdown
          ? PriorityDropdown == "Urgent"
            ? "SameDay"
            : PriorityDropdown
          : null,
        serviceLocation: {
          name: selectedOrderValue?.data?.serviceLocation?.name
            ? selectedOrderValue?.data?.serviceLocation?.name
            : null,
          id: selectedOrderValue?.data?.serviceLocation?.id
            ? selectedOrderValue?.data?.serviceLocation?.id
            : null,
          npi: selectedOrderValue?.data?.serviceLocation?.npi
            ? selectedOrderValue?.data?.serviceLocation?.npi
            : null,
        },
        delivery: selectedOrderValue?.data?.delivery
          ? selectedOrderValue?.data?.delivery
          : null,
        status: selectedOrderValue?.data?.status
          ? selectedOrderValue?.data?.status
          : "",
        referringProviderId: selectedProviderValue?.data?.id
          ? selectedProviderValue?.data?.id
          : null,
        referringProviderLocationId: selectedProviderLocation?.data?.id
          ? selectedProviderLocation?.data?.id
          : null,
        general: selectedProviderValue?.data?.general
          ? selectedProviderValue?.data?.general
          : null,
        prescription: {
          effectiveDate: processedData.orderKeyValues[0].labelValue
            ? processedData.orderKeyValues[0].labelValue
            : "",
          treatingProviderId: selectedProviderValue?.data?.id
            ? selectedProviderValue?.data?.id
            : null,
          products: [
            {
              primitive: {
                lengthOfNeed: processedData.orderKeyValues[1].labelValue
                  ? processedData.orderKeyValues[1].labelValue
                  : null,
                diagnosisCodes: [
                  `${
                    processedData.orderKeyValues[2].labelValue
                      ? processedData.orderKeyValues[2].labelValue
                      : ""
                  }`,
                ],
              },
            },
          ],
        },
      },
      referringProvider: {
        salesAgent: selectedProviderValue?.data?.salesAgent
          ? selectedProviderValue?.data?.salesAgent
          : null,
        id: selectedProviderValue?.data?.id
          ? selectedProviderValue?.data?.id
          : null,
        general: {
          name: {
            lastName: processedData.providerKeyValues[1]?.labelValue
              ? processedData.providerKeyValues[1]?.labelValue
              : "",
            firstName: processedData.providerKeyValues[0]?.labelValue
              ? processedData.providerKeyValues[0]?.labelValue
              : "",
            middleName: selectedProviderValue?.data?.general?.name?.middleName
              ? selectedProviderValue?.data?.general?.name?.middleName
              : "",
          },
          Title: selectedProviderValue?.data?.general?.Title
            ? selectedProviderValue?.data?.general?.Title
            : "",
          npi: processedData.providerKeyValues[4]?.labelValue
            ? processedData.providerKeyValues[4]?.labelValue
            : "",
        },
        note: selectedProviderValue?.data?.note
          ? selectedProviderValue?.data?.note
          : null,
        locations: selectedProviderValue?.data?.locations
          ? selectedProviderValue?.data?.locations
          : null,
        primaryLocation:
          providerTypeDropdown == "Existing" &&
          selectedProviderLocation?.data?.id
            ? {
                id: selectedProviderLocation?.data?.id
                  ? selectedProviderLocation?.data?.id
                  : null,
                address: {
                  addressLine:
                    processedData?.providerKeyValues[5]?.labelValue
                      ?.addressLine,
                  city: processedData?.providerKeyValues[5]?.labelValue?.city,
                  state:
                    processedData?.providerKeyValues[5]?.labelValue?.state?.toUpperCase(),
                  zip: processedData?.providerKeyValues[5]?.labelValue?.zip,
                },
                contacts: {
                  phone: processedData.providerKeyValues[2]?.labelValue
                    ? processedData.providerKeyValues[2]?.labelValue?.replace(
                        /[- )(]/g,
                        "",
                      )
                    : "",
                  fax: processedData.providerKeyValues[3]?.labelValue
                    ? processedData.providerKeyValues[3]?.labelValue?.replace(
                        /[- )(]/g,
                        "",
                      )
                    : null,
                },
              }
            : providerTypeDropdown == "New"
            ? {
                id: selectedProviderLocation?.data?.id
                  ? selectedProviderLocation?.data?.id
                  : null,
                address: {
                  addressLine:
                    processedData?.providerKeyValues[5]?.labelValue
                      ?.addressLine,
                  city: processedData?.providerKeyValues[5]?.labelValue?.city,
                  state:
                    processedData?.providerKeyValues[5]?.labelValue?.state?.toUpperCase(),
                  zip: processedData?.providerKeyValues[5]?.labelValue?.zip,
                },
                contacts: {
                  phone: processedData.providerKeyValues[2]?.labelValue
                    ? processedData.providerKeyValues[2]?.labelValue?.replace(
                        /[- )(]/g,
                        "",
                      )
                    : "",
                  fax: processedData.providerKeyValues[3]?.labelValue
                    ? processedData.providerKeyValues[3]?.labelValue?.replace(
                        /[- )(]/g,
                        "",
                      )
                    : null,
                },
              }
            : null,
        contactPerson: selectedProviderValue?.data?.contactPerson
          ? selectedProviderValue?.data?.contactPerson
          : null,
        salesAgentNote: selectedProviderValue?.data?.salesAgentNote
          ? selectedProviderValue?.data?.salesAgentNote
          : null,
        pecosEnrollment: selectedProviderValue?.data?.pecosEnrollment
          ? selectedProviderValue?.data?.pecosEnrollment
          : false,
      },
    };
    return paramsNeed;
  };

  // This is the function for save the current data of process page.
  const saveProcessedData = (e) => {
    let val = "";
    if (e == "create") {
      val = "create";
    } else if (e == "attach") {
      val = "attach";
    } else if (e == "reset") {
      val = "reset";
    } else {
      e?.preventDefault();
    }
    let paramsNeed = setParams(val);
    axiosRequest
      .post(`${process.env.REACT_APP_BaseUrl}save`, paramsNeed)
      .then((res) => {
        if (res.data) {
          setSaveButtonEnable(false);
          if (val == "") {
            setMessage({ msg: res.data, for: "success" });
            setsaveMsgSucessPopup(!saveMsgSucessPopup);
            setResetButtonEnable(true);
            setIsRedirected(false);
          } else {
            setIsRedirected(true);
          }

          setEditable(!editable);
        }
      })
      .catch((err) => {
        console.log(err, "save=====");
      });
  };

  // This is the function for api call for creating and attaching patient.
  const createAndAttachPatientCall = (e, params) => {
    saveProcessedData(e);
    setLoader("create&AttPatientLoader");
    axiosRequest
      .post(`${process.env.REACT_APP_BaseUrl}createpatient`, params)
      .then((res) => {
        setLoader("");
        if (res.data?.id) {
          setMessage({ msg: "Patient created successfully", for: "success" });
          setpatientTypeDropdown("Existing");
          setselectedPatientValue({
            name: `${res.data?.general?.name?.fullName} ${
              res.data?.general?.dateOfBirth
                ? "(" +
                  formatDate(
                    new Date(
                      res.data?.general?.dateOfBirth?.includes("T")
                        ? res.data?.general?.dateOfBirth
                        : res.data?.general?.dateOfBirth?.replaceAll("-", "/"),
                    ),
                  ) +
                  ")"
                : ""
            }`,
            data: res.data,
          });
        } else if (res.data) {
          setMessage({ msg: "Document attached successfully", for: "success" });
        }
        setsaveMsgSucessPopup(true);
        setSaveButtonEnable(false);
        closePopup();
      })
      .catch((err) => {
        setLoader("");
        if (err?.response?.data?.length < 2) {
          setMessage({ msg: err?.response?.data[0]?.Message, for: "error" });
          setProcessError(true);
        } else {
          setMessage({ msg: err?.response?.data, for: "error" });
          setProcessError(true);
        }
        closePopup();
        setPatientErrorMessage(patientKeys);
      });
    setPatientErrorMessage(patientKeys);
  };

  // This is the function for validating the patient parameter.
  const validatePatientValue = () => {
    let returnVal = "";
    const dobValidation = (date) => {
      if (date?.includes("-")) {
        let year = date?.split("-")[0];
        let month = date?.split("-")[1];
        let day = date?.split("-")[2];
        if (!(year <= currentyr)) {
          return false;
        } else if (!(month <= currentMonth) && year == currentyr) {
          return false;
        } else if (
          !(day <= currentDay) &&
          month == currentMonth &&
          year == currentyr
        ) {
          return false;
        }
      } else if (date?.includes("/")) {
        let year = date?.split("/")[2];
        let month = date?.split("/")[0];
        let day = date?.split("/")[1];
        if (!(year <= currentyr)) {
          return false;
        } else if (!(month <= currentMonth) && year == currentyr) {
          return false;
        } else if (
          !(day <= currentDay) &&
          month == currentMonth &&
          year == currentyr
        ) {
          return false;
        }
      }
    };
    let errmsg = patientErrorMessage["patientKeyValues"];
    if (!processedData?.patientKeyValues[0]?.labelValue) {
      errmsg.FirstName = "Enter Value";
      setPatientErrorMessage({
        ...patientErrorMessage,
        patientKeyValues: errmsg,
      });
      closePopup();
      returnVal = "error";
    }
    if (!processedData?.patientKeyValues[1]?.labelValue) {
      errmsg.LastName = "Enter Value";
      setPatientErrorMessage({
        ...patientErrorMessage,
        patientKeyValues: errmsg,
      });
      closePopup();
      returnVal = "error";
    }
    if (!processedData?.patientKeyValues[2]?.labelValue) {
      errmsg.Gender = "Enter Value";
      setPatientErrorMessage({
        ...patientErrorMessage,
        patientKeyValues: errmsg,
      });
      closePopup();
      returnVal = "error";
    }
    if (!processedData?.patientKeyValues[3]?.labelValue) {
      errmsg.DateOfBirth = "Enter Value";
      setPatientErrorMessage({
        ...patientErrorMessage,
        patientKeyValues: errmsg,
      });
      closePopup();
      returnVal = "error";
    } else if (
      dobValidation(processedData?.patientKeyValues[3]?.labelValue) == false
    ) {
      errmsg.DateOfBirth = "Enter valid value";
      setPatientErrorMessage({
        ...patientErrorMessage,
        patientKeyValues: errmsg,
      });
      closePopup();
      returnVal = "error";
    }
    if (processedData?.patientKeyValues[4]?.labelValue) {
      if (!processedData?.patientKeyValues[4]?.labelValue?.addressLine) {
        errmsg.Address.addressLine = "Enter Value";
        setPatientErrorMessage({
          ...patientErrorMessage,
          patientKeyValues: errmsg,
        });
        closePopup();
        returnVal = "error";
      }
      if (!processedData?.patientKeyValues[4]?.labelValue?.city) {
        errmsg.Address.city = "Enter Value";
        setPatientErrorMessage({
          ...patientErrorMessage,
          patientKeyValues: errmsg,
        });
        closePopup();
        returnVal = "error";
      }
      if (!processedData?.patientKeyValues[4]?.labelValue?.state) {
        errmsg.Address.state = "Enter Value";
        setPatientErrorMessage({
          ...patientErrorMessage,
          patientKeyValues: errmsg,
        });
        closePopup();
        returnVal = "error";
      } else if (
        processedData?.patientKeyValues[4]?.labelValue?.state?.length !== 2 ||
        !stateCodes.includes(
          processedData?.patientKeyValues[4]?.labelValue?.state,
        )
      ) {
        errmsg.Address.state = "Enter valid value";
        setPatientErrorMessage({
          ...patientErrorMessage,
          patientKeyValues: errmsg,
        });
        closePopup();
        returnVal = "error";
      }
      if (!processedData?.patientKeyValues[4]?.labelValue?.zip) {
        errmsg.Address.zip = "Enter Value";
        setPatientErrorMessage({
          ...patientErrorMessage,
          patientKeyValues: errmsg,
        });
        closePopup();
        returnVal = "error";
      } else if (
        processedData?.patientKeyValues[4]?.labelValue?.zip?.length != 5
      ) {
        errmsg.Address.zip = "Enter valid value";
        setPatientErrorMessage({
          ...patientErrorMessage,
          patientKeyValues: errmsg,
        });
        closePopup();
        returnVal = "error";
      }
    }
    if (!processedData?.patientKeyValues[5]?.labelValue) {
      errmsg.Contact = "Enter Value";
      setPatientErrorMessage({
        ...patientErrorMessage,
        patientKeyValues: errmsg,
      });
      closePopup();
      returnVal = "error";
    } else if (processedData?.patientKeyValues[5]?.labelValue?.length !== 14) {
      errmsg.Contact = "Enter Valid Phone No";
      setPatientErrorMessage({
        ...patientErrorMessage,
        patientKeyValues: errmsg,
      });
      closePopup();
      returnVal = "error";
    }
    return returnVal;
  };

  // This is the function for validating the provider parameter.
  const validateProviderValue = () => {
    let returnVal = "";
    let errmsg = patientErrorMessage["providerKeyValues"];
    if (processedData?.providerKeyValues[5]?.labelValue) {
      if (!processedData?.providerKeyValues[5]?.labelValue?.addressLine) {
        errmsg.Address.addressLine = "Enter Value";
        setPatientErrorMessage({
          ...patientErrorMessage,
          providerKeyValues: errmsg,
        });
        closePopup();
        returnVal = "error";
      }
      if (!processedData?.providerKeyValues[5]?.labelValue?.city) {
        errmsg.Address.city = "Enter Value";
        setPatientErrorMessage({
          ...patientErrorMessage,
          providerKeyValues: errmsg,
        });
        closePopup();
        returnVal = "error";
      }
      if (!processedData?.providerKeyValues[5]?.labelValue?.state) {
        errmsg.Address.state = "Enter Value";
        setPatientErrorMessage({
          ...patientErrorMessage,
          providerKeyValues: errmsg,
        });
        closePopup();
        returnVal = "error";
      } else if (
        processedData?.providerKeyValues[5]?.labelValue?.state?.length > 2 ||
        !stateCodes.includes(
          processedData?.providerKeyValues[5]?.labelValue?.state,
        )
      ) {
        errmsg.Address.state = "Enter valid value";
        setPatientErrorMessage({
          ...patientErrorMessage,
          providerKeyValues: errmsg,
        });
        closePopup();
        returnVal = "error";
      }
      if (!processedData?.providerKeyValues[5]?.labelValue?.zip) {
        errmsg.Address.zip = "Enter Value";
        setPatientErrorMessage({
          ...patientErrorMessage,
          providerKeyValues: errmsg,
        });
        closePopup();
        returnVal = "error";
      } else if (
        processedData?.providerKeyValues[5]?.labelValue?.zip?.length != 5
      ) {
        errmsg.Address.zip = "Enter valid value";
        setPatientErrorMessage({
          ...patientErrorMessage,
          providerKeyValues: errmsg,
        });
        closePopup();
        returnVal = "error";
      }
    }
    return returnVal;
  };

  // This is the function attached to the html event to create and attach patient.
  const createPatientAndAttach = (e) => {
    let params = setParams("PatientCreate");
    if (patientTypeDropdown == "New") {
      if (
        processedData?.patientKeyValues[0].labelValue &&
        processedData?.patientKeyValues[1].labelValue &&
        processedData?.patientKeyValues[2].labelValue &&
        processedData?.patientKeyValues[3].labelValue &&
        processedData?.patientKeyValues[4]?.labelValue?.addressLine &&
        processedData?.patientKeyValues[4]?.labelValue?.city &&
        processedData?.patientKeyValues[4]?.labelValue?.state &&
        processedData?.patientKeyValues[4]?.labelValue?.zip &&
        processedData?.patientKeyValues[5]?.labelValue &&
        processedData?.patientKeyValues[5]?.labelValue?.length == 14 &&
        selectedDocumentValue &&
        patientTypeDropdown &&
        processedData?.patientKeyValues[4]?.labelValue?.state?.length == 2
      ) {
        //call the save method aswell.
        createAndAttachPatientCall(e, params);
      } else {
        closePopup();
      }
    } else if (
      patientTypeDropdown == "Existing" &&
      selectedPatientValue?.data?.id
    ) {
      createAndAttachPatientCall(e, params);
    }
  };

  // Chached the pdf @react-pdf-viewer for this library.
  // <ProcessPDFView in this component with pdf will show.
  const cachedValue = useMemo(() => {
    return <ProcessPDFView pdf={uploadedPdf} />;
  }, [uploadedPdf]);

  //  If any field is changed from data summary then the save button enable.
  useEffect(() => {
    setSaveButtonEnable(true);
  }, [
    processedData,
    patientTypeDropdown,
    orderTypeDropdown,
    providerTypeDropdown,
    selectedDocumentValue,
  ]);

  //Reset button click it will take call the reset api which OCR out put generated.
  // and removed earlier changes.
  const resetOcrResult = () => {
    if (currentPage == "parent") {
      getProcessFax(props?.location?.state?.pdfVal?.Id, "", true);
    } else {
      getProcessFax(
        props?.location?.state?.parent?.Id,
        props?.location?.state?.child?.id,
        true,
      );
    }
  };

  // When reset is called then the pop will not need to load that's the reason, this binds.
  useEffect(() => {
    if (saveType == "reset") {
      saveProcessedData("reset");
      setSaveType("");
    }
  }, [saveType]);

  // Getting the provider location call.
  const getproviderLocation = (providerId) => {
    if (providerId) {
      axiosRequest
        .get(
          `${process.env.REACT_APP_BaseUrl}getreferringproviderlocations/${providerId}`,
        )
        .then((res) => {
          if (res.data?.length > 0) {
            let addressList = [];
            res.data?.forEach((element) => {
              addressList.push({
                name: `${element?.address?.addressLine}, ${element?.address?.city}, ${element?.address?.state}, ${element?.address?.zip}`,
                data: element,
              });
            });
            setLocationProviderDropdown(addressList);
            if (res.data?.length == 1) {
              setselectedProviderLocation(addressList[0]);
              dropDownSelectProviderLocation(addressList[0]);
            }
          }
        })
        .catch((err) => {
          console.log(err, "getreferringproviderlocations=====");
        });
    }
  };

  //Get orderList on basis on id.
  const getOrderList = (id) => {
    axiosRequest
      .get(`${process.env.REACT_APP_BaseUrl}orderlist?id=${id}`)
      .then((res) => {
        let data = res?.data?.content;
        if (data?.length == 1) {
          setselectedOrderValue({
            name: `${data[0]?.displayId}`,
            data: data[0],
          });
        } else {
          setselectedOrderValue({ name: "Order not selected" });
        }
      })
      .catch((err) => {
        console.log(err, "orderlist");
        setselectedOrderValue({ name: "Order not selected" });
      });
  };

  // Creation of order and attach document to the order.
  const createOrderAndAttach = (type) => {
    if (
      orderTypeDropdown &&
      PriorityDropdown &&
      (type == "attach" || type == "create")
    ) {
      let params = setParams("OrderCreate");
      setLoader("create&attachOrderLoader");
      saveProcessedData(type);
      axiosRequest
        .post(`${process.env.REACT_APP_BaseUrl}createorder`, params)
        .then((res) => {
          setLoader("");
          if (res.data) {
            setMessage({ msg: res.data, for: "success" });
            setsaveMsgSucessPopup(true);
            setSummaryParamsErrorMessage(summaryErrParams);
            setSaveButtonEnable(false);
          }
          closePopup();
        })
        .catch((err) => {
          setLoader("");
          setMessage({
            msg: err?.response?.data
              ? err?.response?.data?.length > 2
                ? err?.response?.data
                : err?.response?.data?.length == 1
                ? err?.response?.data[0]?.Message
                : "error Occurs"
              : "error Occurs",
            for: "error",
          });
          setProcessError(true);
          closePopup();
        });
    } else {
      closePopup();
    }
  };

  //When user select patient type as new then getprocessfax response is set for patient parameters.
  const setPatientType = (type) => {
    if (type == "New") {
      setselectedPatientValue({
        name: "Patient not selected",
        data: {
          general: {
            name: {
              fullName: "Patient not selected",
            },
          },
        },
      });
      let keyDataTemp = JSON.parse(
        JSON.stringify(processedData["patientKeyValues"]),
      );
      keyDataTemp?.forEach((element) => {
        if (element.labelBindValue == "FirstName") {
          element.labelValue = actualFaxResponseData?.patientMaster?.general
            ?.name?.firstName
            ? actualFaxResponseData?.patientMaster?.general?.name?.firstName
            : "";
          element.labelGreyout = false;
        } else if (element.labelBindValue == "LastName") {
          element.labelValue = actualFaxResponseData?.patientMaster?.general
            ?.name?.lastName
            ? actualFaxResponseData?.patientMaster?.general?.name?.lastName
            : "";
          element.labelGreyout = false;
        } else if (element.labelBindValue == "DateOfBirth") {
          element.labelValue = actualFaxResponseData?.patientMaster?.general
            ?.dateOfBirth
            ? formatDate(
                new Date(
                  actualFaxResponseData?.patientMaster?.general?.dateOfBirth?.includes(
                    "T",
                  )
                    ? actualFaxResponseData?.patientMaster?.general?.dateOfBirth
                    : actualFaxResponseData?.patientMaster?.general?.dateOfBirth?.replaceAll(
                        "-",
                        "/",
                      ),
                ),
              )
            : "";
          element.labelGreyout = false;
        } else if (element.labelBindValue == "Gender") {
          element.labelValue = actualFaxResponseData?.patientMaster?.general
            ?.gender
            ? actualFaxResponseData?.patientMaster?.general?.gender
            : "";
          element.labelGreyout = false;
        } else if (element.labelBindValue == "Contact") {
          let contacts =
            actualFaxResponseData?.patientMaster?.contacts?.contacts?.length > 0
              ? actualFaxResponseData?.patientMaster?.contacts?.contacts
              : "";
          let contactIndex =
            contacts?.length > 0
              ? contacts?.findIndex((el) => {
                  return el?.type == "Home";
                })
              : -1;
          element.labelValue = formatPhoneNumber(
            `${
              contacts &&
              contactIndex !== -1 &&
              contacts[contactIndex]?.value !== null
                ? contacts[contactIndex]?.value
                : ""
            }`,
          );
          element.labelGreyout = false;
        } else if (element.labelBindValue == "Address") {
          let Address = {
            addressLine: actualFaxResponseData?.patientMaster?.addresses
              ?.patient?.addressLine
              ? actualFaxResponseData?.patientMaster?.addresses?.patient
                  ?.addressLine
              : "",
            city: actualFaxResponseData?.patientMaster?.addresses?.patient?.city
              ? actualFaxResponseData?.patientMaster?.addresses?.patient?.city
              : "",
            state: actualFaxResponseData?.patientMaster?.addresses?.patient
              ?.state
              ? actualFaxResponseData?.patientMaster?.addresses?.patient?.state
              : "",
            zip: actualFaxResponseData?.patientMaster?.addresses?.patient?.zip
              ? actualFaxResponseData?.patientMaster?.addresses?.patient?.zip
              : "",
          };
          element.labelValue = Address;
          element.labelGreyout = false;
        }
        return element;
      });
      setProcessedData((prevState) => {
        let processDataTemp = JSON.parse(JSON.stringify(prevState));
        processDataTemp["patientKeyValues"] = keyDataTemp;
        return processDataTemp;
      });
    }
  };

  // When user select the patient from patient list then set the values and get the order list call.
  const dropDownSelectPatient = (childProps) => {
    if (childProps) {
      setselectedPatientValue(childProps);
      let keyDataTemp = JSON.parse(
        JSON.stringify(processedData["patientKeyValues"]),
      );
      keyDataTemp?.forEach((element) => {
        if (element.labelBindValue == "FirstName") {
          element.labelValue = childProps.data?.general?.name?.firstName
            ? childProps.data?.general?.name?.firstName
            : "";
          element.labelGreyout = true;
        } else if (element.labelBindValue == "LastName") {
          element.labelValue = childProps.data?.general?.name?.lastName
            ? childProps.data?.general?.name?.lastName
            : "";
          element.labelGreyout = true;
        } else if (element.labelBindValue == "DateOfBirth") {
          element.labelValue = childProps.data?.general?.dateOfBirth
            ? formatDate(
                new Date(
                  childProps.data?.general?.dateOfBirth?.includes("T")
                    ? childProps.data?.general?.dateOfBirth
                    : childProps.data?.general?.dateOfBirth?.replaceAll(
                        "-",
                        "/",
                      ),
                ),
              )
            : "";
          element.labelGreyout = true;
        } else if (element.labelBindValue == "Gender") {
          element.labelValue = childProps.data?.general?.gender
            ? childProps.data?.general?.gender
            : "";
          element.labelGreyout = true;
        } else if (element.labelBindValue == "Contact") {
          let contacts =
            childProps.data?.contacts?.contacts?.length > 0
              ? childProps.data?.contacts?.contacts
              : "";
          let contactIndex =
            contacts?.length > 0
              ? contacts?.findIndex((el) => {
                  return el?.type == "Home";
                })
              : -1;
          element.labelValue = formatPhoneNumber(
            `${
              contacts &&
              contactIndex !== -1 &&
              contacts[contactIndex]?.value !== null
                ? contacts[contactIndex]?.value
                : ""
            }`,
          );
          element.labelGreyout = true;
        } else if (element.labelBindValue == "Address") {
          element.labelValue = childProps.data?.addresses?.patient
            ? childProps.data?.addresses?.patient
            : { addressLine: "", city: "", state: "", zip: "" };
          element.labelGreyout = true;
        }
        return element;
      });
      let processDataTemp = JSON.parse(JSON.stringify(processedData));
      processDataTemp["patientKeyValues"] = keyDataTemp;
      setProcessedData(processDataTemp);
      getOrderList(childProps.data.id);
      setPatientErrorMessage({
        ...patientErrorMessage,
        patientKeyValues: patientKeys["patientKeyValues"],
      });
    }
  };

  //When user select order type as new then enable the edit mode.
  const setOrderType = (type) => {
    if (type == "New") {
      setselectedOrderValue({ name: "Order not selected" });
      let keyDataTemp = JSON.parse(
        JSON.stringify(processedData["orderKeyValues"]),
      );
      keyDataTemp?.forEach((element) => {
        if (element.labelBindValue == "DateWritten") {
          element.labelGreyout = false;
          element.labelHide = false;
        } else if (element.labelBindValue == "LengthOfNeed") {
          element.labelGreyout = false;
          element.labelHide = false;
        } else if (element.labelBindValue == "DiagnosisCode") {
          element.labelGreyout = false;
          element.labelHide = false;
        }
        return element;
      });
      let providerKeyTemp = JSON.parse(
        JSON.stringify(processedData["providerKeyValues"]),
      );
      providerKeyTemp?.forEach((element) => {
        if (element.labelBindValue == "ProviderFirstName") {
          element.labelHide = false;
        } else if (element.labelBindValue == "ProviderLastName") {
          element.labelHide = false;
        } else if (element.labelBindValue == "ProviderPhone") {
          element.labelHide = false;
        } else if (element.labelBindValue == "ProviderFax") {
          element.labelHide = false;
        } else if (element.labelBindValue == "ProviderNPI") {
          element.labelHide = false;
        } else if (element.labelBindValue == "Address") {
          element.labelHide = false;
        }
        return element;
      });
      setProcessedData((prevState) => {
        let processDataTemp = JSON.parse(JSON.stringify(prevState));
        processDataTemp["orderKeyValues"] = keyDataTemp;
        processDataTemp["providerKeyValues"] = providerKeyTemp;
        return processDataTemp;
      });
    } else if (type == "Existing") {
      let orderKeyTemp = JSON.parse(
        JSON.stringify(processedData["orderKeyValues"]),
      );
      orderKeyTemp?.forEach((element) => {
        if (element.labelBindValue == "DateWritten") {
          element.labelHide = true;
        } else if (element.labelBindValue == "LengthOfNeed") {
          element.labelHide = true;
        } else if (element.labelBindValue == "DiagnosisCode") {
          element.labelHide = true;
        }
        return element;
      });
      let providerKeyTemp = JSON.parse(
        JSON.stringify(processedData["providerKeyValues"]),
      );
      providerKeyTemp?.forEach((element) => {
        if (element.labelBindValue == "ProviderFirstName") {
          element.labelHide = true;
        } else if (element.labelBindValue == "ProviderLastName") {
          element.labelHide = true;
        } else if (element.labelBindValue == "ProviderPhone") {
          element.labelHide = true;
        } else if (element.labelBindValue == "ProviderFax") {
          element.labelHide = true;
        } else if (element.labelBindValue == "ProviderNPI") {
          element.labelHide = true;
        } else if (element.labelBindValue == "Address") {
          element.labelHide = true;
        }
        return element;
      });
      setProcessedData((prevState) => {
        let processDataTemp = JSON.parse(JSON.stringify(prevState));
        processDataTemp["providerKeyValues"] = providerKeyTemp;
        processDataTemp["orderKeyValues"] = orderKeyTemp;
        return processDataTemp;
      });
    }
  };

  // When user select the order from order list then set the values.
  const dropDownSelectOrder = (childProps) => {
    if (childProps) {
      setselectedOrderValue(childProps);
      let keyDataTemp = JSON.parse(
        JSON.stringify(processedData["orderKeyValues"]),
      );
      keyDataTemp?.forEach((element) => {
        if (element.labelBindValue == "DateWritten") {
          element.labelValue = childProps.data?.dateWritten
            ? childProps.data?.dateWritten
            : "";
          element.labelGreyout = true;
        } else if (element.labelBindValue == "LengthOfNeed") {
          element.labelValue = childProps.data?.lengthOfNeed
            ? childProps.data?.lengthOfNeed
            : 1;
          element.labelGreyout = true;
        } else if (element.labelBindValue == "DiagnosisCode") {
          element.labelValue = childProps.data?.diagnosisCode
            ? childProps.data?.diagnosisCode
            : "";
          element.labelGreyout = true;
        }
        return element;
      });
      let processDataTemp = JSON.parse(JSON.stringify(processedData));
      processDataTemp["orderKeyValues"] = keyDataTemp;
      setProcessedData(processDataTemp);
    }
  };

  //When user select provider type as new then getprocessfax response is set for provider parameters.
  // and when type as existing then grey out for those.
  const setProviderType = (type) => {
    if (type == "New") {
      setselectedProviderValue({ name: "Provider not selected" });
      let keyDataTemp = JSON.parse(
        JSON.stringify(processedData["providerKeyValues"]),
      );
      keyDataTemp?.forEach((element) => {
        if (element.labelBindValue == "ProviderFirstName") {
          element.labelValue = actualFaxResponseData?.referringProvider?.general
            ?.name?.firstName
            ? actualFaxResponseData?.referringProvider?.general?.name?.firstName
            : "";
          element.labelGreyout = false;
        } else if (element.labelBindValue == "ProviderLastName") {
          element.labelValue = actualFaxResponseData?.referringProvider?.general
            ?.name?.lastName
            ? actualFaxResponseData?.referringProvider?.general?.name?.lastName
            : "";
          element.labelGreyout = false;
        } else if (element.labelBindValue == "ProviderPhone") {
          element.labelValue = actualFaxResponseData?.referringProvider
            ?.primaryLocation?.contacts?.phone
            ? formatPhoneNumber(
                actualFaxResponseData?.referringProvider?.primaryLocation
                  ?.contacts?.phone,
              )
            : "";
          element.labelGreyout = false;
        } else if (element.labelBindValue == "ProviderFax") {
          element.labelValue = actualFaxResponseData?.referringProvider
            ?.primaryLocation?.contacts?.fax
            ? formatPhoneNumber(
                actualFaxResponseData?.referringProvider?.primaryLocation
                  ?.contacts?.fax,
              )
            : "";
          element.labelGreyout = false;
        } else if (element.labelBindValue == "ProviderNPI") {
          element.labelValue = actualFaxResponseData?.referringProvider?.general
            ?.npi
            ? actualFaxResponseData?.referringProvider?.general?.npi
            : "";
          element.labelGreyout = false;
        } else if (element.labelBindValue == "Address") {
          element.labelValue = {
            addressLine: "",
            city: "",
            state: "",
            zip: "",
          };
          element.labelGreyout = false;
        }
        return element;
      });
      let processDataTemp = JSON.parse(JSON.stringify(processedData));
      processDataTemp["providerKeyValues"] = keyDataTemp;
      setProcessedData(processDataTemp);
    } else if (type == "Existing") {
      let keyDataTemp = JSON.parse(
        JSON.stringify(processedData["providerKeyValues"]),
      );
      keyDataTemp?.forEach((element) => {
        element.labelGreyout = true;
        return element;
      });
      let processDataTemp = JSON.parse(JSON.stringify(processedData));
      processDataTemp["providerKeyValues"] = keyDataTemp;
      setProcessedData(processDataTemp);
    }
  };

  // When user select the provider from provider list then set the values and grey out.
  const dropDownSelectProvider = (childProps) => {
    if (childProps) {
      setselectedProviderValue(childProps);
      let keyDataTemp = JSON.parse(
        JSON.stringify(processedData["providerKeyValues"]),
      );
      keyDataTemp?.forEach((element) => {
        if (element.labelBindValue == "ProviderFirstName") {
          element.labelValue = childProps.data?.general?.name?.firstName
            ? childProps.data?.general?.name?.firstName
            : "";
          element.labelGreyout = true;
        } else if (element.labelBindValue == "ProviderLastName") {
          element.labelValue = childProps.data?.general?.name?.lastName
            ? childProps.data?.general?.name?.lastName
            : "";
          element.labelGreyout = true;
        } else if (element.labelBindValue == "ProviderPhone") {
          element.labelValue = childProps.data?.primaryLocation?.contacts?.phone
            ? formatPhoneNumber(
                childProps.data?.primaryLocation?.contacts?.phone,
              )
            : "";
          element.labelGreyout = true;
        } else if (element.labelBindValue == "ProviderFax") {
          element.labelValue = childProps.data?.primaryLocation?.contacts?.fax
            ? formatPhoneNumber(childProps.data?.primaryLocation?.contacts?.fax)
            : "";
          element.labelGreyout = true;
        } else if (element.labelBindValue == "ProviderNPI") {
          element.labelValue = childProps.data?.general?.npi
            ? childProps.data?.general?.npi
            : "";
          element.labelGreyout = true;
        } else if (element.labelBindValue == "Address") {
          element.labelValue = {
            addressLine: "",
            city: "",
            state: "",
            zip: "",
          };
          element.labelGreyout = true;
        }
        return element;
      });
      setProcessedData((prevState) => {
        let processDataTemp = JSON.parse(JSON.stringify(prevState));
        processDataTemp["providerKeyValues"] = [...keyDataTemp];
        return processDataTemp;
      });
      getproviderLocation(childProps?.data?.id);
      setselectedProviderLocation({});
      let errmsg = patientErrorMessage["providerKeyValues"];
      errmsg.ProviderNPI = "";
      setPatientErrorMessage({
        ...patientErrorMessage,
        providerKeyValues: errmsg,
      });
    }
  };

  // This is the function for location selected in location list.
  const dropDownSelectProviderLocation = (location) => {
    if (location) {
      let AddressObj = {
        addressLine: location?.data?.address?.addressLine
          ? location?.data?.address?.addressLine
          : "",
        city: location?.data?.address?.city
          ? location?.data?.address?.city
          : "",
        state: location?.data?.address?.state
          ? location?.data?.address?.state
          : "",
        zip: location?.data?.address?.zip ? location?.data?.address?.zip : "",
      };
      let ProviderPhoneObj = location?.data?.contacts?.phone
        ? formatPhoneNumber(location?.data?.contacts?.phone)
        : "";
      let ProviderFaxObj = location.data.contacts.fax
        ? formatPhoneNumber(location.data.contacts.fax)
        : "";
      let keyDataTemp = JSON.parse(
        JSON.stringify(processedData["providerKeyValues"]),
      );
      let errmsg = patientErrorMessage["providerKeyValues"];
      keyDataTemp?.forEach((element, index) => {
        if (element.labelBindValue == "Address") {
          let addressError = {
            addressLine: "",
            city: "",
            state: "",
            zip: "",
          };
          errmsg.Address = addressError;
          element.labelValue = AddressObj;
          element.labelGreyout = true;
        } else if (element.labelBindValue == "ProviderPhone") {
          element.labelValue = ProviderPhoneObj;
          element.labelGreyout = true;
          errmsg.ProviderPhone = "";
        } else if (element.labelBindValue == "ProviderFax") {
          element.labelValue = ProviderFaxObj;
          element.labelGreyout = true;
          errmsg.ProviderFax = "";
        }
        setPatientErrorMessage({
          ...patientErrorMessage,
          providerKeyValues: errmsg,
        });
        return element;
      });
      setProcessedData((prevState) => {
        let processDataTemp = JSON.parse(JSON.stringify(prevState));
        processDataTemp["providerKeyValues"][5] = keyDataTemp[5];
        processDataTemp["providerKeyValues"][3] = keyDataTemp[3];
        processDataTemp["providerKeyValues"][2] = keyDataTemp[2];
        return processDataTemp;
      });
    }
  };

  // This is the function for setting the address for provider from validateNpi function.
  const validNpiData = (data) => {
    if (data) {
      setselectedProviderLocation({
        name: `${data?.primaryLocation?.address?.addressLine},${
          data?.primaryLocation?.address?.addressLine2
            ? data?.primaryLocation?.address?.addressLine2
            : ""
        }, ${data?.primaryLocation?.address?.city}, ${
          data?.primaryLocation?.address?.state
        }, ${data?.primaryLocation?.address?.zip}`,
        data: data,
      });
      let keyDataTemp = JSON.parse(
        JSON.stringify(processedData["providerKeyValues"]),
      );
      keyDataTemp?.forEach((element) => {
        if (element.labelBindValue == "ProviderFirstName") {
          element.labelValue = data?.general?.name?.firstName
            ? data?.general?.name?.firstName
            : "";
          element.labelGreyout = true;
        } else if (element.labelBindValue == "ProviderLastName") {
          element.labelValue = data?.general?.name?.lastName
            ? data?.general?.name?.lastName
            : "";
          element.labelGreyout = true;
        } else if (element.labelBindValue == "ProviderPhone") {
          element.labelValue = data.primaryLocation.contacts.phone
            ? formatPhoneNumber(data.primaryLocation.contacts.phone)
            : "";
          // element.labelValue = processedData.providerKeyValues[2].labelValue ? formatPhoneNumber(processedData.providerKeyValues[2].labelValue) :""
          element.labelGreyout = false;
        } else if (element.labelBindValue == "ProviderFax") {
          // element.labelValue = data.primaryLocation.contacts.fax ? data.primaryLocation.contacts.fax:""
          element.labelValue = processedData.providerKeyValues[3].labelValue
            ? formatPhoneNumber(processedData.providerKeyValues[3].labelValue)
            : "";
          element.labelGreyout = false;
        } else if (element.labelBindValue == "ProviderNPI") {
          element.labelValue = data?.general?.npi ? data?.general?.npi : "";
          element.labelGreyout = false;
        } else if (element.labelBindValue == "Address") {
          element.labelValue = {
            addressLine: `${
              data?.primaryLocation?.address?.addressLine
                ? data?.primaryLocation?.address?.addressLine
                : ""
            } ${
              data?.primaryLocation?.address?.addressLine2
                ? data?.primaryLocation?.address?.addressLine2
                : ""
            }`,
            city: data?.primaryLocation?.address?.city
              ? data?.primaryLocation?.address?.city
              : "",
            state: data?.primaryLocation?.address?.state
              ? data?.primaryLocation?.address?.state
              : "",
            zip: data?.primaryLocation?.address?.zip
              ? data?.primaryLocation?.address?.zip
              : "",
          };
          element.labelGreyout = false;
        }
        return element;
      });
      let processDataTemp = JSON.parse(JSON.stringify(processedData));
      processDataTemp["providerKeyValues"] = keyDataTemp;
      setProcessedData(processDataTemp);
    }
  };

  // When user enter the npi number then this is the function for gave the address.
  const validateNpi = (npiVal) => {
    let validRegx = /^[0-9]{10}$/;
    if (validRegx.test(npiVal)) {
      setLoader("validateNPILoader");
      axiosRequest
        .get(`${process.env.REACT_APP_BaseUrl}validatenpi/${npiVal}`)
        .then((res) => {
          setLoader("");
          if (res.data) {
            validNpiData(res.data);
          }
        })
        .catch((err) => {
          setLoader("");
          if (err?.response?.data?.length > 2) {
            setMessage({ msg: err?.response?.data, for: "error" });
            setProcessError(true);
          } else {
            setMessage({ msg: "NPI doesn't exist.", for: "error" });
            setProcessError(true);
          }
        });
    } else {
      setMessage({ msg: "Please enter valid NPI", for: "error" });
      setProcessError(true);
    }
  };

  //When successful message is came then redirected to the index page.
  const redirectIndexPage = () => {
    history.push({
      pathname: "/home",
      state: { indexPageState: history.location.state.indexPageState },
    });
  };

  // In edit mode dateofbith object getting empty so set the existing date.
  const editExistingDateOnEditButton = (dateVal) => {
    let returnDate = "";
    if (dateVal?.includes("/")) {
      dateVal = dateVal?.split("/");
      returnDate = `${dateVal[2]}-${dateVal[0]}-${dateVal[1]}`;
      return returnDate;
    } else {
      return dateVal;
    }
  };
  const validateDocumentAndPatientType = () => {
    let returnVal = "";
    if (!patientTypeDropdown) {
      let documentErr = summaryParamsErrorMessage;
      documentErr.patientType = "Please Select Value";
      setSummaryParamsErrorMessage({ ...documentErr });
      closePopup();
      returnVal = "error";
    }
    if (!selectedDocumentValue) {
      let documentErr = summaryParamsErrorMessage;
      documentErr.documentType = "Please Select Value";
      setSummaryParamsErrorMessage({ ...documentErr });
      closePopup();
      returnVal = "error";
    }
    return returnVal;
  };
  const createPatient = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let docAndPtypeVal = validateDocumentAndPatientType();
    let paval = validatePatientValue();
    if (docAndPtypeVal == "error" || paval == "error") {
      return;
    } else {
      setcreateAttachPatientPopup(true);
    }
  };
  const attachPatient = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let patientVal = "";
    let docAndPtypeVal = validateDocumentAndPatientType();
    if (!selectedPatientValue?.data?.id) {
      let documentErr = summaryParamsErrorMessage;
      documentErr.selectedPatient = "Please Select Value";
      setSummaryParamsErrorMessage({ ...documentErr });
      closePopup();
      patientVal = "error";
    }
    if (docAndPtypeVal == "error" || patientVal == "error") {
      return;
    } else {
      setattachPatientPopup(true);
    }
  };
  const commonCheckInCreatAndAttachOrder = () => {
    let returnVal = "";
    let summERR = summaryParamsErrorMessage;
    let docAndPtypeVal = validateDocumentAndPatientType();
    if (docAndPtypeVal == "error") {
      returnVal = "error";
    }
    if (!orderTypeDropdown) {
      summERR.orderType = "Please Select Value";
      setSummaryParamsErrorMessage({ ...summERR });
      closePopup();
      returnVal = "error";
    }
    if (!PriorityDropdown) {
      summERR.priorityL = "Please Select Value";
      setSummaryParamsErrorMessage({ ...summERR });
      closePopup();
      returnVal = "error";
    }
    if (!selectedDocumentValue) {
      summERR.documentType = `Please Select Value`;
      setSummaryParamsErrorMessage({ ...summERR });
      closePopup();
      returnVal = "error";
    }
    if (patientTypeDropdown == "New") {
      let pval = validatePatientValue();
      if (pval == "error") {
        returnVal = "error";
      }
    }
    if (patientTypeDropdown == "Existing" && !selectedPatientValue?.data?.id) {
      let documentErr = summaryParamsErrorMessage;
      documentErr.selectedPatient = "Please Select Value";
      setSummaryParamsErrorMessage({ ...documentErr });
      returnVal = "error";
    }
    return returnVal;
  };
  const createOrder = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let orderVal = "";
    let summERR = summaryParamsErrorMessage;
    let checksVal = commonCheckInCreatAndAttachOrder();
    if (checksVal == "error") {
      orderVal = "error";
    }
    if (!providerTypeDropdown) {
      summERR.providerType = "Please Select Value";
      setSummaryParamsErrorMessage({ ...summERR });
      closePopup();
      orderVal = "error";
    }
    if (
      providerTypeDropdown == "Existing" &&
      !selectedProviderValue?.data?.id
    ) {
      summERR.selectedProvider = "Please Select provider";
      setSummaryParamsErrorMessage({ ...summERR });
      closePopup();
      orderVal = "error";
    }
    if (providerTypeDropdown == "New") {
      let errmsg = patientErrorMessage["providerKeyValues"];
      if (!processedData.providerKeyValues[0].labelValue) {
        errmsg.ProviderFirstName = "Enter Value";
        setPatientErrorMessage({
          ...patientErrorMessage,
          providerKeyValues: errmsg,
        });
        closePopup();
        orderVal = "error";
      }
      if (!processedData?.providerKeyValues[1].labelValue) {
        errmsg.ProviderLastName = "Enter Value";
        setPatientErrorMessage({
          ...patientErrorMessage,
          providerKeyValues: errmsg,
        });
        closePopup();
        orderVal = "error";
      }
      if (
        !processedData.providerKeyValues[2].labelValue &&
        !processedData.providerKeyValues[3].labelValue
      ) {
        errmsg.ProviderPhone = "Enter Value";
        errmsg.ProviderFax = "Enter Value";
        setPatientErrorMessage({
          ...patientErrorMessage,
          providerKeyValues: errmsg,
        });
        closePopup();
        orderVal = "error";
      }
      if (processedData.providerKeyValues[2].labelValue) {
        if (processedData?.providerKeyValues[2]?.labelValue?.length !== 14) {
          errmsg.ProviderPhone = "Enter Valid Phone No";
          setPatientErrorMessage({
            ...patientErrorMessage,
            providerKeyValues: errmsg,
          });
          closePopup();
          orderVal = "error";
        }
      }
      if (processedData.providerKeyValues[3].labelValue) {
        if (processedData?.providerKeyValues[3]?.labelValue?.length !== 14) {
          errmsg.ProviderFax = "Enter Valid Fax";
          setPatientErrorMessage({
            ...patientErrorMessage,
            providerKeyValues: errmsg,
          });
          closePopup();
          orderVal = "error";
        }
      }
    }
    if (!processedData?.providerKeyValues[4]?.labelValue) {
      let errmsg = patientErrorMessage["providerKeyValues"];
      errmsg.ProviderNPI = "Enter Value";
      setPatientErrorMessage({
        ...patientErrorMessage,
        providerKeyValues: errmsg,
      });
      closePopup();
      orderVal = "error";
    }
    if (!processedData.orderKeyValues[0].labelValue) {
      let errmsg = patientErrorMessage.orderKeyValues;
      errmsg.DateWritten = "Enter Value";
      setPatientErrorMessage({
        ...patientErrorMessage,
        orderKeyValues: errmsg,
      });
      closePopup();
      orderVal = "error";
    }
    let val = validateProviderValue();
    if (val == "error") {
      orderVal = "error";
    }
    if (orderVal == "error") {
      return;
    } else {
      setcreateAttachOrderPopup(!createAttachOrderPopup);
    }
  };
  const attachOrder = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let orderVal = "";
    let checksVal = commonCheckInCreatAndAttachOrder();
    if (checksVal == "error") {
      orderVal = "error";
    }
    let summERR = summaryParamsErrorMessage;
    if (!selectedOrderValue?.data?.id) {
      summERR.selectedOrder = "Please Select Value";
      setSummaryParamsErrorMessage({ ...summERR });
      closePopup();
      orderVal = "error";
    }
    if (orderVal == "error") {
      return;
    } else {
      setattachOrderPopup(!attachOrderPopup);
    }
  };
  return (
    <>
      {cookies?.firstName ? (
        <div>
          <Navbar />
          <Container className="processview">
            <Row className="mt-2">
              <ol className="breadcrum">
                <li className="breadcrumb-item">
                  <Link
                    to={{
                      pathname: "/home",
                      state: {
                        indexPageState: history.location.state.indexPageState,
                      },
                    }}
                  >
                    Inbox
                  </Link>
                </li>
                {props?.location?.state?.from ? (
                  <li className="breadcrumb-item">
                    <Link
                      to={{
                        pathname: "/viewpage",
                        state: props?.location?.state,
                      }}
                    >
                      Document View
                    </Link>{" "}
                  </li>
                ) : (
                  ""
                )}
                <li className="breadcrumb-item">Document Processing</li>
              </ol>
            </Row>
            <Row className="mt-3">
              <Col sm={6}>
                <h2 className="semi-bold">Document Processing</h2>
              </Col>
              <Col sm={6}>
                <div className="justify-content-right text-right">
                  <p className="mb-0 start-text">
                    <span style={{ color: "red" }}>*</span> For create patient
                  </p>
                  <p className="mb-0 start-text">
                    <span style={{ color: "blue" }}>*</span> For create order
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="mt-3 mb-3 selbx">
              <Col md={12} className="">
                <Row>
                  <Col sm={4}>
                    <h3>Document Summary</h3>
                  </Col>
                  {processError ? (
                    <SuccessErrorPopup
                      message={message}
                      closeSuccessErrorMsg={() =>
                        setProcessError(!processError)
                      }
                    />
                  ) : (
                    ""
                  )}
                  {saveMsgSucessPopup ? (
                    <SuccessErrorPopup
                      message={message}
                      closeSuccessErrorMsg={() => {
                        setsaveMsgSucessPopup(!saveMsgSucessPopup);
                        if (isRedirected) redirectIndexPage();
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Row>
                <Row>
                  <Col md={3}>
                    <div>
                      <label
                        className={`form-label ${
                          summaryParamsErrorMessage?.patientType
                            ? "form-label-error"
                            : ""
                        } `}
                      >
                        Patient Type<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div>
                      {/* <span className="editedErrorMessage">{summaryParamsErrorMessage?.patientType ? summaryParamsErrorMessage?.patientType:"" }</span> */}
                      <Dropdown
                        className={` ${
                          summaryParamsErrorMessage?.patientType
                            ? "form-error"
                            : ""
                        } `}
                      >
                        <Dropdown.Toggle>{patientTypeDropdown}</Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              setPatientType("New");
                              setpatientTypeDropdown("New");
                              setOrderType("New");
                              setorderTypeDropdown("New");
                              setSummaryParamsErrorMessage({
                                ...summaryParamsErrorMessage,
                                patientType: "",
                              });
                            }}
                          >
                            New
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setPatientType("Existing");
                              setpatientTypeDropdown("Existing");
                              setSummaryParamsErrorMessage({
                                ...summaryParamsErrorMessage,
                                patientType: "",
                                selectedPatient: "",
                              });
                            }}
                          >
                            Existing
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div>
                      <label
                        className={`form-label ${
                          summaryParamsErrorMessage?.orderType
                            ? "form-label-error"
                            : ""
                        } `}
                      >
                        Order Type<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div>
                      {/* <span className="editedErrorMessage">{summaryParamsErrorMessage?.orderType ? summaryParamsErrorMessage?.orderType:"" }</span> */}
                      <Dropdown
                        className={`${
                          patientTypeDropdown == "New" ? "locDropdown" : ""
                        } ${
                          summaryParamsErrorMessage?.orderType
                            ? "form-error"
                            : ""
                        }`}
                      >
                        <Dropdown.Toggle>{orderTypeDropdown}</Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              setOrderType("New");
                              setorderTypeDropdown("New");
                            }}
                          >
                            New
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setOrderType("Existing");
                              setorderTypeDropdown("Existing");
                              setSummaryParamsErrorMessage({
                                ...summaryParamsErrorMessage,
                                selectedOrder: "",
                              });
                            }}
                          >
                            Existing
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Col>
                  {orderTypeDropdown == "Existing" ? (
                    ""
                  ) : (
                    <Col md={3}>
                      <div>
                        <label
                          className={`form-label ${
                            summaryParamsErrorMessage?.providerType
                              ? "form-label-error"
                              : ""
                          } `}
                        >
                          Provider Type<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div>
                        {/* <span className="editedErrorMessage">{summaryParamsErrorMessage?.providerType ? summaryParamsErrorMessage?.providerType:"" }</span> */}
                        <Dropdown
                          className={` ${
                            summaryParamsErrorMessage?.providerType
                              ? "form-error"
                              : ""
                          } `}
                        >
                          <Dropdown.Toggle>
                            {providerTypeDropdown}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                setProviderType("New");
                                setproviderTypeDropdown("New");
                                setselectedProviderLocation({});
                                setSummaryParamsErrorMessage({
                                  ...summaryParamsErrorMessage,
                                  providerType: "",
                                });
                              }}
                            >
                              New
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setProviderType("Existing");
                                setproviderTypeDropdown("Existing");
                                setselectedProviderLocation({});
                                setSummaryParamsErrorMessage({
                                  ...summaryParamsErrorMessage,
                                  providerType: "",
                                  selectedProvider: "",
                                  providerLocation: "",
                                });
                              }}
                            >
                              Existing
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Col>
                  )}
                  {orderTypeDropdown == "Existing" ? (
                    ""
                  ) : (
                    <Col md={3}>
                      <div>
                        <label className="form-label">
                          Product Type<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div>
                        <Dropdown style={{ width: "100%" }}>
                          <Dropdown.Toggle>
                            {therapyTypeDropdown}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="therapy-Dropdown">
                            {therapyTypeDropdownList &&
                              therapyTypeDropdownList.length > 0 &&
                              therapyTypeDropdownList?.map((ele, ind) => {
                                return (
                                  <Dropdown.Item
                                    key={ind}
                                    onClick={() =>
                                      settherapyTypeDropdown(`${ele?.Type}`)
                                    }
                                  >
                                    {ele?.Type}
                                  </Dropdown.Item>
                                );
                              })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Col>
                  )}
                </Row>
                <Row className="mt-3">
                  <Col md={3}>
                    {patientTypeDropdown == "New" ? (
                      ""
                    ) : patientTypeDropdown == "Existing" ? (
                      <>
                        <Row>
                          <Col md={10} className="pe-0">
                            <label
                              className={`form-label label-black view ${
                                !selectedPatientValue?.data?.id &&
                                summaryParamsErrorMessage?.selectedPatient
                                  ? "form-label-error"
                                  : ""
                              } `}
                            >
                              <BsFillPersonFill />
                              {selectedPatientValue?.name?.length > 2 ? (
                                <>
                                  &nbsp;&nbsp;{selectedPatientValue.name}
                                  <span style={{ color: "red" }}>*</span>
                                </>
                              ) : (
                                ""
                              )}
                            </label>
                          </Col>
                          <Col
                            md={2}
                            ref={outSideRef}
                            className="view ps-0 justify-content-center text-center"
                          >
                            <button
                              className="view-btn1"
                              onClick={() =>
                                setPatientDetailpopup(!patientDetailpopup)
                              }
                            >
                              <FaEye className="icon" />
                            </button>
                          </Col>
                          {patientDetailpopup ? (
                            <Col md={3}>
                              <div className="patientDetail">
                                <div className="justify-content-center text-center p-name">
                                  <h6>
                                    {
                                      selectedPatientValue.data?.general?.name
                                        ?.fullName
                                    }
                                  </h6>
                                </div>
                                <div className="p-address">
                                  <span>Address</span>
                                  <p className="m-0">
                                    {
                                      selectedPatientValue.data?.addresses
                                        ?.patient?.addressLine
                                    }
                                  </p>
                                  <p>
                                    {
                                      selectedPatientValue.data?.addresses
                                        ?.patient?.city
                                    }
                                    ,&nbsp;&nbsp;
                                    {
                                      selectedPatientValue.data?.addresses
                                        ?.patient?.state
                                    }
                                    &nbsp;
                                    {
                                      selectedPatientValue.data?.addresses
                                        ?.patient?.zip
                                    }
                                  </p>
                                </div>
                                <div className="p-phone">
                                  <span>Phone</span>
                                  <p>
                                    {formatPhoneNumber(
                                      selectedPatientValue.data?.contacts
                                        ?.contacts[
                                        selectedPatientValue.data?.contacts?.contacts.findIndex(
                                          (el) => {
                                            return el.type == "Home";
                                          },
                                        )
                                      ]?.value,
                                    )}
                                  </p>
                                </div>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>

                        <div>
                          {/* {
                        selectedPatientValue?.data?.id ? null
                        :<span className="editedErrorMessage">{summaryParamsErrorMessage?.selectedPatient ? summaryParamsErrorMessage?.selectedPatient:"" }</span>
                      }                         */}
                          <DropDownWithSearch
                            setselectedValue={dropDownSelectPatient}
                            selectedValue={selectedPatientValue}
                            placeholder="Select Another Patient"
                            drpdwn="patientList"
                            summaryParamsErrorMessage={
                              summaryParamsErrorMessage
                            }
                            inputPlaceHolder="Search Patient/DOB(mm/dd/yyyy)"
                          />
                        </div>
                      </>
                    ) : null}
                  </Col>
                  <Col md={3}>
                    {orderTypeDropdown == "New" ? (
                      ""
                    ) : orderTypeDropdown == "Existing" ? (
                      <>
                        <div>
                          <label
                            className={`form-label label-black ${
                              !selectedOrderValue?.data?.id &&
                              summaryParamsErrorMessage?.selectedOrder
                                ? "form-label-error"
                                : ""
                            } `}
                          >
                            <BsBagCheckFill />
                            &nbsp;&nbsp;{selectedOrderValue.name}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                        <div>
                          {/* {
                        selectedOrderValue?.data?.id ? null
                        :<span className="editedErrorMessage">{summaryParamsErrorMessage?.selectedOrder ? summaryParamsErrorMessage?.selectedOrder:"" }</span>
                      } */}
                          <DropDownWithSearch
                            setselectedValue={dropDownSelectOrder}
                            selectedValue={selectedOrderValue}
                            placeholder="Select Another Order"
                            drpdwn="orderList"
                            summaryParamsErrorMessage={
                              summaryParamsErrorMessage
                            }
                            displayedPatient={selectedPatientValue.data?.id}
                            inputPlaceHolder="Search Order"
                          />
                          {/* {console.log(selectedPatientValue.data,"patientID")} */}
                        </div>
                      </>
                    ) : null}
                  </Col>
                  {orderTypeDropdown == "Existing" ? (
                    ""
                  ) : (
                    <Col md={3}>
                      {providerTypeDropdown == "New" ? (
                        ""
                      ) : providerTypeDropdown == "Existing" ? (
                        <>
                          <div>
                            <label
                              className={`form-label label-black ${
                                !selectedProviderValue?.data?.id &&
                                summaryParamsErrorMessage?.selectedProvider
                                  ? "form-label-error"
                                  : ""
                              } `}
                            >
                              <FaUserNurse />
                              {selectedProviderValue?.name?.length > 2 ? (
                                <>
                                  &nbsp;&nbsp;{selectedProviderValue.name}
                                  <span style={{ color: "red" }}>*</span>
                                </>
                              ) : null}
                            </label>
                          </div>
                          <div>
                            {/* {
                        selectedProviderValue?.data?.id ? null
                        :<span className="editedErrorMessage">{summaryParamsErrorMessage?.selectedProvider ? summaryParamsErrorMessage?.selectedProvider:"" }</span>
                      } */}
                            <DropDownWithSearch
                              setselectedValue={dropDownSelectProvider}
                              selectedValue={selectedProviderValue}
                              placeholder="Select Another Provider"
                              drpdwn="providerList"
                              summaryParamsErrorMessage={
                                summaryParamsErrorMessage
                              }
                              inputPlaceHolder="Search Provider"
                            />
                          </div>
                        </>
                      ) : null}
                    </Col>
                  )}
                  <Col md={3}>
                    <div>
                      <label
                        className={`form-label ${
                          summaryParamsErrorMessage?.documentType
                            ? "form-label-error"
                            : ""
                        } `}
                      >
                        Document Type<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div>
                      {/* <span className="editedErrorMessage">{summaryParamsErrorMessage?.documentType ? summaryParamsErrorMessage?.documentType :""}</span> */}
                      <Dropdown
                        className={`${
                          summaryParamsErrorMessage?.documentType
                            ? "form-error"
                            : ""
                        } `}
                        style={{ width: "100%" }}
                      >
                        <Dropdown.Toggle>
                          {selectedDocumentValue
                            ? selectedDocumentValue
                            : "Select"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="therapy-Dropdown">
                          {documentTypeDropdownList &&
                            documentTypeDropdownList.length > 0 &&
                            documentTypeDropdownList?.map((ele, ind) => {
                              return (
                                <Dropdown.Item
                                  key={ind}
                                  onClick={() => {
                                    setselectedDocumentValue(ele?.documentType);
                                    setSummaryParamsErrorMessage({
                                      ...summaryParamsErrorMessage,
                                      documentType: "",
                                    });
                                  }}
                                >
                                  {ele?.documentType}
                                </Dropdown.Item>
                              );
                            })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3" style={{ justifyContent: "right" }}>
                  {orderTypeDropdown == "Existing" ? (
                    ""
                  ) : (
                    <Col md={3}>
                      {providerTypeDropdown == "New" ? (
                        ""
                      ) : providerTypeDropdown == "Existing" ? (
                        <>
                          <div>
                            <label
                              className={`form-label label-black view  ${
                                summaryParamsErrorMessage?.providerLocation
                                  ? "form-label-error"
                                  : ""
                              } `}
                            >
                              <GrMapLocation />
                              &nbsp;&nbsp;
                              {selectedProviderLocation?.name
                                ? selectedProviderLocation?.name
                                : "Provider Location not selected"}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                          <div>
                            {/* <span className="editedErrorMessage">{summaryParamsErrorMessage?.providerLocation ? summaryParamsErrorMessage?.providerLocation :""}</span> */}
                            <Dropdown
                              className={`${
                                summaryParamsErrorMessage?.providerLocation
                                  ? "form-error"
                                  : ""
                              } `}
                              style={{ width: "100%" }}
                              onClick={() => {
                                if (selectedProviderValue?.data?.id)
                                  getproviderLocation(
                                    selectedProviderValue?.data?.id,
                                  );
                              }}
                            >
                              <Dropdown.Toggle>Select Location</Dropdown.Toggle>
                              <Dropdown.Menu>
                                {locationProviderDropdown &&
                                  locationProviderDropdown.length > 0 &&
                                  locationProviderDropdown?.map((ele, ind) => {
                                    return (
                                      <Dropdown.Item
                                        className={
                                          ele?.name ==
                                          selectedProviderLocation?.name
                                            ? "text-primary"
                                            : ""
                                        }
                                        key={ind}
                                        onClick={() => {
                                          setselectedProviderLocation(ele);
                                          setSummaryParamsErrorMessage({
                                            ...summaryParamsErrorMessage,
                                            providerLocation: "",
                                          });
                                          dropDownSelectProviderLocation(ele);
                                        }}
                                      >
                                        {ele?.name}
                                      </Dropdown.Item>
                                    );
                                  })}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </>
                      ) : null}
                    </Col>
                  )}
                  {orderTypeDropdown == "Existing" ? (
                    ""
                  ) : (
                    <Col md={3}>
                      <div>
                        <label
                          className={`form-label  ${
                            summaryParamsErrorMessage?.priorityL
                              ? "form-label-error"
                              : ""
                          } `}
                        >
                          Priority<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div>
                        {/* <span className="editedErrorMessage">{summaryParamsErrorMessage?.priorityL ? summaryParamsErrorMessage?.priorityL:"" }</span> */}
                        <Dropdown
                          className={`${
                            summaryParamsErrorMessage?.priorityL
                              ? "form-error"
                              : ""
                          } `}
                          style={{ width: "100%" }}
                        >
                          <Dropdown.Toggle>{PriorityDropdown}</Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                setPriorityDropdown("Urgent");
                                setSummaryParamsErrorMessage({
                                  ...summaryParamsErrorMessage,
                                  priorityL: "",
                                });
                              }}
                            >
                              Urgent
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setPriorityDropdown("High");
                                setSummaryParamsErrorMessage({
                                  ...summaryParamsErrorMessage,
                                  priorityL: "",
                                });
                              }}
                            >
                              High
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setPriorityDropdown("Normal");
                                setSummaryParamsErrorMessage({
                                  ...summaryParamsErrorMessage,
                                  priorityL: "",
                                });
                              }}
                            >
                              Normal
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setPriorityDropdown("Low");
                                setSummaryParamsErrorMessage({
                                  ...summaryParamsErrorMessage,
                                  priorityL: "",
                                });
                              }}
                            >
                              Low
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
            {loader == "processFaxLoader" ? (
              <CommanSpinner
                spinner={loader}
                text={
                  (currentPage == "parent" &&
                    props?.location?.state?.pdfVal?.Status == "Processing") ||
                  props?.location?.state?.parent?.Status == "Processing" ||
                  props?.location?.state?.pdfVal?.Status == "Completed" ||
                  props?.location?.state?.parent?.Status == "Completed"
                    ? ""
                    : "OCR Processing..."
                }
              />
            ) : (
              ""
            )}
            {/* Document Data Started */}
            <Row>
              <Col lg={6}>
                <div className="inputpdf">
                  {uploadedPdf
                    ? // <ProcessPDFView pdf={uploadedPdf} />
                      cachedValue
                    : ""}
                </div>
              </Col>
              <Col lg={6}>
                <Row className="mb-4 outputbx">
                  <Row>
                    <Col md={6}>
                      <h3>Document Data</h3>
                    </Col>
                    <Col md={6} className="content-right">
                      {selectedPatientValue?.data?.id &&
                      orderTypeDropdown == "Existing" ? (
                        ""
                      ) : (
                        <div className="p-1">
                          <button
                            className={
                              !editable ? "editbtn" : "selectededitbtn"
                            }
                            onClick={() => {
                              editButtonClick();
                            }}
                          >
                            <MdModeEditOutline />
                          </button>
                        </div>
                      )}
                      <div className="p-1">
                        <button
                          className="addbtn"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setaddBtnPopup(!addBtnPopup);
                          }}
                        >
                          <BsPlusLg />
                        </button>
                        {addBtnPopup ? (
                          <div className="addbtnpopup">
                            <div className="popupbackdrop">
                              <div className="popup mt-4">
                                <div className="clsbtnrow">
                                  <button
                                    className="clsbtn"
                                    onClick={closePopup}
                                  >
                                    <IoMdClose />
                                  </button>
                                </div>
                                <div className="popup-header justify-content-center text-center mb-4">
                                  <h4 className="bold">Add Key and Value</h4>
                                </div>
                                <div className="popup-header">
                                  <p className="form-label textleftalign">
                                    Select a Key
                                  </p>
                                  <select className="form-fieldcommon">
                                    <option>Select a Key</option>
                                  </select>
                                </div>
                                <div className="popup-header justify-content-center text-center mt-5 mb-5">
                                  <Button className="form-btn1 btn-width">
                                    ADD KEY AND VALUE
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                  </Row>
                  {/* repeated child will be here */}
                  {!editable ? (
                    <>
                      {Object.keys(processedData)?.length > 0 &&
                        Object.keys(processedData)?.map((keyName, ind) => {
                          return (
                            <div
                              style={{ display: "flex", flexWrap: "wrap" }}
                              key={ind}
                            >
                              {processedData[keyName] &&
                                processedData[keyName]?.map((pk, ind) => {
                                  let dynamicColor = {
                                    color: `${pk.labelStartColor}`,
                                  };
                                  return (
                                    <Col
                                      md={6}
                                      key={ind}
                                      style={{ padding: "0 10px" }}
                                    >
                                      {pk.labelHide == false ? (
                                        <Row>
                                          <Col sm={4}>
                                            {pk.labelBindValue == "Address" ? (
                                              <>
                                                <div className="mt-2 mb-2">
                                                  <label
                                                    className={`form-label ${
                                                      patientErrorMessage[
                                                        keyName
                                                      ]?.[
                                                        `${pk?.labelBindValue}`
                                                      ]?.addressLine?.length > 0
                                                        ? "form-label-error"
                                                        : ""
                                                    } `}
                                                    style={{ height: "100px" }}
                                                  >
                                                    {pk?.labelName
                                                      ? pk?.labelName
                                                      : ""}
                                                    {pk?.labelStartColor ==
                                                    "" ? (
                                                      ""
                                                    ) : (
                                                      <span
                                                        style={{
                                                          ...dynamicColor,
                                                        }}
                                                      >
                                                        *
                                                      </span>
                                                    )}
                                                  </label>
                                                </div>
                                                <div className="mt-2 mb-2">
                                                  <label
                                                    className={`form-label ${
                                                      patientErrorMessage[
                                                        keyName
                                                      ]?.[
                                                        `${pk?.labelBindValue}`
                                                      ]?.city?.length > 0
                                                        ? "form-label-error"
                                                        : ""
                                                    } `}
                                                  >
                                                    {pk?.labelAddressName.city}
                                                    {pk?.labelStartColor ==
                                                    "" ? (
                                                      ""
                                                    ) : (
                                                      <span
                                                        style={{
                                                          ...dynamicColor,
                                                        }}
                                                      >
                                                        *
                                                      </span>
                                                    )}
                                                  </label>
                                                </div>
                                                <div className="mt-3 mb-3">
                                                  <label
                                                    className={`form-label ${
                                                      patientErrorMessage[
                                                        keyName
                                                      ]?.[
                                                        `${pk?.labelBindValue}`
                                                      ]?.state?.length > 0
                                                        ? "form-label-error"
                                                        : ""
                                                    } `}
                                                  >
                                                    {pk?.labelAddressName.state}
                                                    {pk?.labelStartColor ==
                                                    "" ? (
                                                      ""
                                                    ) : (
                                                      <span
                                                        style={{
                                                          ...dynamicColor,
                                                        }}
                                                      >
                                                        *
                                                      </span>
                                                    )}
                                                  </label>
                                                </div>
                                                <div className="mt-2 mb-2">
                                                  <label
                                                    className={`form-label ${
                                                      patientErrorMessage[
                                                        keyName
                                                      ]?.[
                                                        `${pk?.labelBindValue}`
                                                      ]?.zip?.length > 0
                                                        ? "form-label-error"
                                                        : ""
                                                    } `}
                                                  >
                                                    {pk?.labelAddressName.zip}
                                                    {pk?.labelStartColor ==
                                                    "" ? (
                                                      ""
                                                    ) : (
                                                      <span
                                                        style={{
                                                          ...dynamicColor,
                                                        }}
                                                      >
                                                        *
                                                      </span>
                                                    )}
                                                  </label>
                                                </div>
                                              </>
                                            ) : (
                                              <div className="mt-2 mb-2">
                                                <label
                                                  className={`form-label ${
                                                    pk?.labelBindValue !==
                                                      "Address" &&
                                                    patientErrorMessage[
                                                      keyName
                                                    ]?.[`${pk?.labelBindValue}`]
                                                      ?.length > 0
                                                      ? "form-label-error"
                                                      : ""
                                                  } `}
                                                >
                                                  {pk?.labelName
                                                    ? pk?.labelName
                                                    : ""}
                                                  {pk?.labelStartColor == "" ? (
                                                    ""
                                                  ) : (
                                                    <span
                                                      style={{
                                                        ...dynamicColor,
                                                      }}
                                                    >
                                                      *
                                                    </span>
                                                  )}
                                                </label>
                                              </div>
                                            )}
                                          </Col>
                                          <Col sm={8}>
                                            {/* <span className="editedErrorMessage">{
                                              pk?.labelBindValue !=="Address" ?  patientErrorMessage[keyName]?.[`${pk?.labelBindValue}`]?.length >0 ?
                                                    patientErrorMessage[keyName]?.[`${pk?.labelBindValue}`]:"":""
                                            }</span> */}
                                            {pk.labelType == "address" ? (
                                              <>
                                                <div className="mt-2 mb-2">
                                                  {/* <span className="editedErrorMessage">{
                                                        patientErrorMessage[keyName]?.[`${pk?.labelBindValue}`]?.addressLine?.length >0 ? patientErrorMessage[keyName]?.[`${pk?.labelBindValue}`]?.addressLine:""
                                                      }</span> */}
                                                  <textarea
                                                    className={`form-fieldcommon greayout ${
                                                      patientErrorMessage[
                                                        keyName
                                                      ]?.[
                                                        `${pk?.labelBindValue}`
                                                      ]?.addressLine?.length > 0
                                                        ? "form-error"
                                                        : ""
                                                    } `}
                                                    style={{
                                                      height: "100px",
                                                      resize: "none",
                                                    }}
                                                    placeholder="Address line 1"
                                                    value={
                                                      pk?.labelValue
                                                        ?.addressLine
                                                    }
                                                    readOnly
                                                  />
                                                </div>
                                                <div className="mt-2 mb-2">
                                                  {/* <span className="editedErrorMessage">{
                                                        patientErrorMessage[keyName]?.[`${pk?.labelBindValue}`]?.city?.length >0 ? patientErrorMessage[keyName]?.[`${pk?.labelBindValue}`]?.city:""
                                                      }</span> */}
                                                  <input
                                                    className={`form-fieldcommon greayout ${
                                                      patientErrorMessage[
                                                        keyName
                                                      ]?.[
                                                        `${pk?.labelBindValue}`
                                                      ]?.city?.length > 0
                                                        ? "form-error"
                                                        : ""
                                                    } `}
                                                    type="text"
                                                    placeholder="City"
                                                    value={pk?.labelValue?.city}
                                                    readOnly
                                                  />
                                                </div>
                                                <div className="mt-2 mb-2">
                                                  {/* <span className="editedErrorMessage">{
                                                        patientErrorMessage[keyName]?.[`${pk?.labelBindValue}`]?.state?.length >0 ? patientErrorMessage[keyName]?.[`${pk?.labelBindValue}`]?.state:""
                                                      }</span> */}
                                                  <input
                                                    className={`form-fieldcommon greayout ${
                                                      patientErrorMessage[
                                                        keyName
                                                      ]?.[
                                                        `${pk?.labelBindValue}`
                                                      ]?.state?.length > 0
                                                        ? "form-error"
                                                        : ""
                                                    } `}
                                                    type="text"
                                                    placeholder="State"
                                                    value={
                                                      pk?.labelValue?.state
                                                    }
                                                    readOnly
                                                  />
                                                </div>
                                                <div className="mt-2 mb-2">
                                                  {/* <span className="editedErrorMessage">{
                                                        patientErrorMessage[keyName]?.[`${pk?.labelBindValue}`]?.zip?.length >0 ? patientErrorMessage[keyName]?.[`${pk?.labelBindValue}`]?.zip:""
                                                      }</span> */}
                                                  <input
                                                    className={`form-fieldcommon greayout ${
                                                      patientErrorMessage[
                                                        keyName
                                                      ]?.[
                                                        `${pk?.labelBindValue}`
                                                      ]?.zip?.length > 0
                                                        ? "form-error"
                                                        : ""
                                                    } `}
                                                    type="text"
                                                    placeholder="Zip"
                                                    value={pk?.labelValue?.zip}
                                                    readOnly
                                                  />
                                                </div>
                                              </>
                                            ) : pk.labelType == "dropdown" ? (
                                              <></>
                                            ) : pk.labelType == "text" ? (
                                              <div
                                                className={
                                                  pk.labelBindValue ==
                                                  "ProviderNPI"
                                                    ? "validpos mt-2 mb-2"
                                                    : "mt-2 mb-2"
                                                }
                                              >
                                                <input
                                                  className={`form-fieldcommon greayout ${
                                                    pk?.labelBindValue !==
                                                      "Address" &&
                                                    patientErrorMessage[
                                                      keyName
                                                    ]?.[`${pk?.labelBindValue}`]
                                                      ?.length > 0
                                                      ? "form-error"
                                                      : ""
                                                  } `}
                                                  type="text"
                                                  value={
                                                    (pk.labelBindValue ==
                                                      "DateOfBirth" ||
                                                      pk.labelBindValue ==
                                                        "DateWritten") &&
                                                    pk?.labelValue?.split(
                                                      "-",
                                                    )[0]?.length > 2
                                                      ? formatDate(
                                                          new Date(
                                                            pk?.labelValue?.includes(
                                                              "T",
                                                            )
                                                              ? pk?.labelValue
                                                              : pk?.labelValue?.replaceAll(
                                                                  "-",
                                                                  "/",
                                                                ),
                                                          ),
                                                        )
                                                      : (pk.labelBindValue ==
                                                          "DateOfBirth" ||
                                                          pk.labelBindValue ==
                                                            "DateWritten") &&
                                                        pk?.labelValue
                                                      ? formatDate(
                                                          new Date(
                                                            pk?.labelValue?.includes(
                                                              "T",
                                                            )
                                                              ? pk?.labelValue
                                                              : pk?.labelValue?.replaceAll(
                                                                  "-",
                                                                  "/",
                                                                ),
                                                          ),
                                                        )
                                                      : pk?.labelValue
                                                  }
                                                  readOnly
                                                />
                                                {pk.labelBindValue ==
                                                  "ProviderNPI" &&
                                                providerTypeDropdown ==
                                                  "New" ? (
                                                  <input
                                                    className="validbtn"
                                                    type="button"
                                                    value="Validate"
                                                    readOnly
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            ) : pk.labelType == "number" ? (
                                              <div className="mt-2 mb-2 ">
                                                <input
                                                  className={`form-fieldcommon greayout ${
                                                    pk?.labelBindValue !==
                                                      "Address" &&
                                                    patientErrorMessage[
                                                      keyName
                                                    ]?.[`${pk?.labelBindValue}`]
                                                      ?.length > 0
                                                      ? "form-error"
                                                      : ""
                                                  } `}
                                                  type="number"
                                                  value={pk?.labelValue}
                                                  readOnly
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </Col>
                                        </Row>
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  );
                                })}
                            </div>
                          );
                        })}
                    </>
                  ) : (
                    <>
                      {Object.keys(processedData)?.length > 0 &&
                        Object.keys(processedData)?.map((keyName, ind) => {
                          return (
                            <div
                              style={{ display: "flex", flexWrap: "wrap" }}
                              key={ind}
                            >
                              {processedData[keyName] &&
                                processedData[keyName]?.map((pk, ind) => {
                                  let dynamicColor = {
                                    color: `${pk.labelStartColor}`,
                                  };
                                  return (
                                    <Col
                                      md={6}
                                      key={ind}
                                      style={{ padding: "0 10px" }}
                                    >
                                      {pk.labelHide == false ? (
                                        <Row>
                                          <Col sm={4}>
                                            {pk.labelBindValue == "Address" ? (
                                              <>
                                                <div className="mt-2 mb-2">
                                                  <label
                                                    className={`form-label ${
                                                      patientErrorMessage[
                                                        keyName
                                                      ]?.[
                                                        `${pk?.labelBindValue}`
                                                      ]?.addressLine?.length > 0
                                                        ? "form-label-error"
                                                        : ""
                                                    } `}
                                                    style={{ height: "100px" }}
                                                  >
                                                    {pk?.labelName
                                                      ? pk?.labelName
                                                      : ""}
                                                    {pk?.labelStartColor ==
                                                    "" ? (
                                                      ""
                                                    ) : (
                                                      <span
                                                        style={{
                                                          ...dynamicColor,
                                                        }}
                                                      >
                                                        *
                                                      </span>
                                                    )}
                                                  </label>
                                                </div>
                                                <div className="mt-2 mb-2">
                                                  <label
                                                    className={`form-label ${
                                                      patientErrorMessage[
                                                        keyName
                                                      ]?.[
                                                        `${pk?.labelBindValue}`
                                                      ]?.city?.length > 0
                                                        ? "form-label-error"
                                                        : ""
                                                    } `}
                                                  >
                                                    {pk?.labelAddressName.city}
                                                    {pk?.labelStartColor ==
                                                    "" ? (
                                                      ""
                                                    ) : (
                                                      <span
                                                        style={{
                                                          ...dynamicColor,
                                                        }}
                                                      >
                                                        *
                                                      </span>
                                                    )}
                                                  </label>
                                                </div>
                                                <div className="mt-3 mb-3">
                                                  <label
                                                    className={`form-label ${
                                                      patientErrorMessage[
                                                        keyName
                                                      ]?.[
                                                        `${pk?.labelBindValue}`
                                                      ]?.state?.length > 0
                                                        ? "form-label-error"
                                                        : ""
                                                    } `}
                                                  >
                                                    {pk?.labelAddressName.state}
                                                    {pk?.labelStartColor ==
                                                    "" ? (
                                                      ""
                                                    ) : (
                                                      <span
                                                        style={{
                                                          ...dynamicColor,
                                                        }}
                                                      >
                                                        *
                                                      </span>
                                                    )}
                                                  </label>
                                                </div>
                                                <div className="mt-2 mb-2">
                                                  <label
                                                    className={`form-label ${
                                                      patientErrorMessage[
                                                        keyName
                                                      ]?.[
                                                        `${pk?.labelBindValue}`
                                                      ]?.zip?.length > 0
                                                        ? "form-label-error"
                                                        : ""
                                                    } `}
                                                  >
                                                    {pk?.labelAddressName.zip}
                                                    {pk?.labelStartColor ==
                                                    "" ? (
                                                      ""
                                                    ) : (
                                                      <span
                                                        style={{
                                                          ...dynamicColor,
                                                        }}
                                                      >
                                                        *
                                                      </span>
                                                    )}
                                                  </label>
                                                </div>
                                              </>
                                            ) : (
                                              <div className="mt-2 mb-2">
                                                <label
                                                  className={`form-label ${
                                                    pk?.labelBindValue !==
                                                      "Address" &&
                                                    patientErrorMessage[
                                                      keyName
                                                    ]?.[`${pk?.labelBindValue}`]
                                                      ?.length > 0
                                                      ? "form-label-error"
                                                      : ""
                                                  } ${
                                                    pk.labelBindValue ==
                                                      "ProviderFirstName" ||
                                                    pk.labelBindValue ==
                                                      "ProviderLastName"
                                                      ? "providerDisable"
                                                      : ""
                                                  }`}
                                                >
                                                  {pk?.labelName
                                                    ? pk?.labelName
                                                    : ""}
                                                  {pk?.labelStartColor == "" ? (
                                                    ""
                                                  ) : (
                                                    <span
                                                      style={{
                                                        ...dynamicColor,
                                                      }}
                                                    >
                                                      *
                                                    </span>
                                                  )}
                                                </label>
                                              </div>
                                            )}
                                          </Col>
                                          <Col sm={8}>
                                            {/* <span className="editedErrorMessage">{
                                                pk?.labelBindValue !=="Address" ?   patientErrorMessage[keyName]?.[`${pk?.labelBindValue}`]?.length >0 ?
                                                    patientErrorMessage[keyName]?.[`${pk?.labelBindValue}`]:"":""
                                              }</span> */}
                                            {pk.labelEditType == "address" ? (
                                              <>
                                                <div className="mt-2 mb-2">
                                                  {/* <span className="editedErrorMessage">{
                                                        patientErrorMessage[keyName]?.[`${pk?.labelBindValue}`]?.addressLine?.length >0 ? patientErrorMessage[keyName]?.[`${pk?.labelBindValue}`]?.addressLine:""
                                                      }</span> */}
                                                  {pk?.labelGreyout == true ? (
                                                    <textarea
                                                      className={`form-fieldcommon greayout ${
                                                        patientErrorMessage[
                                                          keyName
                                                        ]?.[
                                                          `${pk?.labelBindValue}`
                                                        ]?.addressLine?.length >
                                                        0
                                                          ? "form-error"
                                                          : ""
                                                      } `}
                                                      style={{
                                                        height: "100px",
                                                        resize: "none",
                                                      }}
                                                      name="addressLine"
                                                      placeholder="Address line 1"
                                                      value={
                                                        pk?.labelValue
                                                          ?.addressLine
                                                      }
                                                      readOnly
                                                    />
                                                  ) : (
                                                    <textarea
                                                      className={`form-fieldcommon ${
                                                        patientErrorMessage[
                                                          keyName
                                                        ]?.[
                                                          `${pk?.labelBindValue}`
                                                        ]?.addressLine?.length >
                                                        0
                                                          ? "form-error"
                                                          : ""
                                                      } `}
                                                      style={{
                                                        height: "100px",
                                                        resize: "none",
                                                      }}
                                                      name="addressLine"
                                                      placeholder="Address line 1"
                                                      value={
                                                        pk?.labelValue
                                                          ?.addressLine
                                                      }
                                                      onChange={(e) =>
                                                        editeValue(e, keyName)
                                                      }
                                                      required
                                                    />
                                                  )}
                                                </div>
                                                <div className="mt-2 mb-2">
                                                  {/* <span className="editedErrorMessage">{
                                                        patientErrorMessage[keyName]?.[`${pk?.labelBindValue}`]?.city?.length >0 ? patientErrorMessage[keyName]?.[`${pk?.labelBindValue}`]?.city:""
                                                      }</span> */}
                                                  {pk?.labelGreyout == true ? (
                                                    <input
                                                      className={`form-fieldcommon greayout ${
                                                        patientErrorMessage[
                                                          keyName
                                                        ]?.[
                                                          `${pk?.labelBindValue}`
                                                        ]?.city?.length > 0
                                                          ? "form-error"
                                                          : ""
                                                      } `}
                                                      name="city"
                                                      type="text"
                                                      placeholder="City"
                                                      value={
                                                        pk?.labelValue?.city
                                                      }
                                                      readOnly
                                                    />
                                                  ) : (
                                                    <input
                                                      className={`form-fieldcommon ${
                                                        patientErrorMessage[
                                                          keyName
                                                        ]?.[
                                                          `${pk?.labelBindValue}`
                                                        ]?.city?.length > 0
                                                          ? "form-error"
                                                          : ""
                                                      } `}
                                                      name="city"
                                                      type="text"
                                                      placeholder="City"
                                                      value={
                                                        pk?.labelValue?.city
                                                      }
                                                      onChange={(e) =>
                                                        editeValue(e, keyName)
                                                      }
                                                      required
                                                    />
                                                  )}
                                                </div>
                                                <div className="mt-2 mb-2">
                                                  {/* <span className="editedErrorMessage">{
                                                      patientErrorMessage[keyName]?.[`${pk?.labelBindValue}`]?.state?.length >0 ? patientErrorMessage[keyName]?.[`${pk?.labelBindValue}`]?.state:""
                                                      }</span> */}
                                                  {pk?.labelGreyout == true ? (
                                                    <input
                                                      className={`form-fieldcommon greayout ${
                                                        patientErrorMessage[
                                                          keyName
                                                        ]?.[
                                                          `${pk?.labelBindValue}`
                                                        ]?.state?.length > 0
                                                          ? "form-error"
                                                          : ""
                                                      } `}
                                                      name="state"
                                                      type="text"
                                                      maxLength="2"
                                                      placeholder="State"
                                                      value={
                                                        pk?.labelValue?.state
                                                      }
                                                      readOnly
                                                    />
                                                  ) : (
                                                    <input
                                                      className={`form-fieldcommon  ${
                                                        patientErrorMessage[
                                                          keyName
                                                        ]?.[
                                                          `${pk?.labelBindValue}`
                                                        ]?.state?.length > 0
                                                          ? "form-error"
                                                          : ""
                                                      } `}
                                                      name="state"
                                                      type="text"
                                                      maxLength="2"
                                                      placeholder="State"
                                                      value={
                                                        pk?.labelValue?.state
                                                      }
                                                      onChange={(e) =>
                                                        editeValue(e, keyName)
                                                      }
                                                      required
                                                    />
                                                  )}
                                                </div>
                                                <div className="mt-2 mb-2">
                                                  {/* <span className="editedErrorMessage">{
                                                        patientErrorMessage[keyName]?.[`${pk?.labelBindValue}`]?.zip?.length >0 ? patientErrorMessage[keyName]?.[`${pk?.labelBindValue}`]?.zip:""
                                                      }</span> */}
                                                  {pk?.labelGreyout == true ? (
                                                    <input
                                                      className={`form-fieldcommon greayout ${
                                                        patientErrorMessage[
                                                          keyName
                                                        ]?.[
                                                          `${pk?.labelBindValue}`
                                                        ]?.zip?.length > 0
                                                          ? "form-error"
                                                          : ""
                                                      } `}
                                                      name="zip"
                                                      type="text"
                                                      maxLength="5"
                                                      placeholder="Zip"
                                                      value={
                                                        pk?.labelValue?.zip
                                                      }
                                                      readOnly
                                                    />
                                                  ) : (
                                                    <input
                                                      className={`form-fieldcommon ${
                                                        patientErrorMessage[
                                                          keyName
                                                        ]?.[
                                                          `${pk?.labelBindValue}`
                                                        ]?.zip?.length > 0
                                                          ? "form-error"
                                                          : ""
                                                      } `}
                                                      name="zip"
                                                      type="text"
                                                      maxLength="5"
                                                      placeholder="Zip"
                                                      value={
                                                        pk?.labelValue?.zip
                                                      }
                                                      onChange={(e) =>
                                                        editeValue(e, keyName)
                                                      }
                                                      required
                                                    />
                                                  )}
                                                </div>
                                              </>
                                            ) : pk.labelEditType == "select" ? (
                                              <div className="mt-2 mb-2 ">
                                                {pk?.labelGreyout == true ? (
                                                  <input
                                                    className={`form-fieldcommon greayout ${
                                                      pk?.labelBindValue !==
                                                        "Address" &&
                                                      patientErrorMessage[
                                                        keyName
                                                      ]?.[
                                                        `${pk?.labelBindValue}`
                                                      ]?.length > 0
                                                        ? "form-error"
                                                        : ""
                                                    } `}
                                                    name={pk.labelBindValue}
                                                    type="text"
                                                    value={pk?.labelValue}
                                                    readOnly
                                                  />
                                                ) : (
                                                  <select
                                                    className={`form-fieldcommon arrowclass ${
                                                      pk?.labelBindValue !==
                                                        "Address" &&
                                                      patientErrorMessage[
                                                        keyName
                                                      ]?.[
                                                        `${pk?.labelBindValue}`
                                                      ]?.length > 0
                                                        ? "form-error"
                                                        : ""
                                                    } `}
                                                    value={pk?.labelValue}
                                                    name={pk.labelBindValue}
                                                    onChange={(e) =>
                                                      editeValue(e, keyName)
                                                    }
                                                  >
                                                    <option value="">
                                                      select value
                                                    </option>
                                                    <option value="Male">
                                                      Male
                                                    </option>
                                                    <option value="Female">
                                                      Female
                                                    </option>
                                                    <option value="Other">
                                                      Other
                                                    </option>
                                                  </select>
                                                )}
                                              </div>
                                            ) : pk.labelEditType == "text" ? (
                                              <div
                                                className={
                                                  pk.labelBindValue ==
                                                  "ProviderNPI"
                                                    ? "validpos mt-2 mb-2"
                                                    : "mt-2 mb-2"
                                                }
                                              >
                                                {pk?.labelGreyout == true ? (
                                                  <>
                                                    <input
                                                      className={`form-fieldcommon greayout ${
                                                        pk?.labelBindValue !==
                                                          "Address" &&
                                                        patientErrorMessage[
                                                          keyName
                                                        ]?.[
                                                          `${pk?.labelBindValue}`
                                                        ]?.length > 0
                                                          ? "form-error"
                                                          : ""
                                                      } `}
                                                      name={pk?.labelBindValue}
                                                      type="text"
                                                      value={pk?.labelValue}
                                                      readOnly
                                                    />
                                                    {pk.labelBindValue ==
                                                      "ProviderNPI" &&
                                                    providerTypeDropdown ==
                                                      "New" ? (
                                                      <input
                                                        className="validbtn"
                                                        type="button"
                                                        value="Validate"
                                                        readOnly
                                                      />
                                                    ) : pk.labelBindValue ==
                                                      "LengthOfNeed" ? (
                                                      <span
                                                        style={{
                                                          fontSize: "10px",
                                                        }}
                                                      >
                                                        valid (1-99)only
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    <input
                                                      className={`form-fieldcommon ${
                                                        pk.labelBindValue ==
                                                          "ProviderFirstName" ||
                                                        pk.labelBindValue ==
                                                          "ProviderLastName"
                                                          ? "providerDisable"
                                                          : pk.labelBindValue ==
                                                            "Contact"
                                                          ? "CursurText"
                                                          : pk.labelBindValue ==
                                                            "ProviderPhone"
                                                          ? "CursurTextPhone"
                                                          : pk.labelBindValue ==
                                                            "ProviderFax"
                                                          ? "CursurTextFax"
                                                          : ""
                                                      } ${
                                                        pk?.labelBindValue !==
                                                          "Address" &&
                                                        patientErrorMessage[
                                                          keyName
                                                        ]?.[
                                                          `${pk?.labelBindValue}`
                                                        ]?.length > 0
                                                          ? "form-error"
                                                          : ""
                                                      }`}
                                                      name={pk?.labelBindValue}
                                                      type="text"
                                                      value={pk?.labelValue}
                                                      onChange={(e) =>
                                                        editeValue(e, keyName)
                                                      }
                                                      readOnly={
                                                        pk.labelBindValue ==
                                                          "ProviderFirstName" ||
                                                        pk.labelBindValue ==
                                                          "ProviderLastName"
                                                          ? true
                                                          : false
                                                      }
                                                      required
                                                    />
                                                    {pk.labelBindValue ==
                                                      "ProviderNPI" &&
                                                    providerTypeDropdown ==
                                                      "New" ? (
                                                      <input
                                                        className="validbtn"
                                                        type="button"
                                                        onClick={() =>
                                                          validateNpi(
                                                            pk?.labelValue,
                                                          )
                                                        }
                                                        value="Validate"
                                                      />
                                                    ) : pk.labelBindValue ==
                                                      "LengthOfNeed" ? (
                                                      <span
                                                        style={{
                                                          fontSize: "10px",
                                                        }}
                                                      >
                                                        {" "}
                                                        valid (1-99)
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                )}
                                              </div>
                                            ) : pk.labelEditType == "number" ? (
                                              <div className="mt-2 mb-2 ">
                                                {pk?.labelGreyout == true ? (
                                                  <input
                                                    className={`form-fieldcommon greayout ${
                                                      pk?.labelBindValue !==
                                                        "Address" &&
                                                      patientErrorMessage[
                                                        keyName
                                                      ]?.[
                                                        `${pk?.labelBindValue}`
                                                      ]?.length > 0
                                                        ? "form-error"
                                                        : ""
                                                    } `}
                                                    name={pk?.labelBindValue}
                                                    type="number"
                                                    maxLength="9"
                                                    value={pk?.labelValue}
                                                    readOnly
                                                  />
                                                ) : (
                                                  <input
                                                    className={`form-fieldcommon ${
                                                      pk?.labelBindValue !==
                                                        "Address" &&
                                                      patientErrorMessage[
                                                        keyName
                                                      ]?.[
                                                        `${pk?.labelBindValue}`
                                                      ]?.length > 0
                                                        ? "form-error"
                                                        : ""
                                                    } `}
                                                    name={pk?.labelBindValue}
                                                    type="number"
                                                    maxLength="9"
                                                    value={pk?.labelValue}
                                                    onChange={(e) =>
                                                      editeValue(e, keyName)
                                                    }
                                                    required
                                                  />
                                                )}
                                              </div>
                                            ) : pk.labelEditType == "date" ? (
                                              <div className="mt-2 mb-2 ">
                                                {
                                                  pk?.labelGreyout == true ? (
                                                    <input
                                                      className={`form-fieldcommon greayout ${
                                                        pk?.labelBindValue !==
                                                          "Address" &&
                                                        patientErrorMessage[
                                                          keyName
                                                        ]?.[
                                                          `${pk?.labelBindValue}`
                                                        ]?.length > 0
                                                          ? "form-error"
                                                          : ""
                                                      } `}
                                                      name={pk?.labelBindValue}
                                                      type="text"
                                                      max={currentdt}
                                                      value={pk?.labelValue}
                                                      readOnly
                                                    />
                                                  ) : (
                                                    <>
                                                      {
                                                        // pk?.labelValue ?
                                                        pk?.labelBindValue ==
                                                        "DateWritten" ? (
                                                          <input
                                                            className={`form-fieldcommon ${
                                                              pk?.labelBindValue !==
                                                                "Address" &&
                                                              patientErrorMessage[
                                                                keyName
                                                              ]?.[
                                                                `${pk?.labelBindValue}`
                                                              ]?.length > 0
                                                                ? "form-error"
                                                                : ""
                                                            } `}
                                                            name={
                                                              pk?.labelBindValue
                                                            }
                                                            max={maxDate}
                                                            type="date"
                                                            value={
                                                              pk?.labelValue
                                                                ? editExistingDateOnEditButton(
                                                                    pk?.labelValue,
                                                                  )
                                                                : pk?.labelValue
                                                            }
                                                            onChange={(e) =>
                                                              editeValue(
                                                                e,
                                                                keyName,
                                                              )
                                                            }
                                                            required
                                                          />
                                                        ) : pk?.labelBindValue ==
                                                          "DateOfBirth" ? (
                                                          <input
                                                            className={`form-fieldcommon ${
                                                              pk?.labelBindValue !==
                                                                "Address" &&
                                                              patientErrorMessage[
                                                                keyName
                                                              ]?.[
                                                                `${pk?.labelBindValue}`
                                                              ]?.length > 0
                                                                ? "form-error"
                                                                : ""
                                                            } `}
                                                            name={
                                                              pk?.labelBindValue
                                                            }
                                                            max={maxDate}
                                                            type="date"
                                                            value={
                                                              pk?.labelValue
                                                                ? editExistingDateOnEditButton(
                                                                    pk?.labelValue,
                                                                  )
                                                                : pk?.labelValue
                                                            }
                                                            onChange={(e) =>
                                                              editeValue(
                                                                e,
                                                                keyName,
                                                              )
                                                            }
                                                            required
                                                          />
                                                        ) : (
                                                          ""
                                                        )
                                                        // :<input className='form-fieldcommon' name={pk?.labelBindValue} type="date" max={currentdt} value={pk?.labelValue} onChange={(e)=>editeValue(e,keyName)} required />
                                                      }
                                                    </>
                                                  )
                                                  // <input className='form-fieldcommon' name={pk?.labelBindValue} type="date" max={currentdt} value={pk?.labelValue ?editExistingDate(pk?.labelValue):pk?.labelValue} onChange={(e)=>editeValue(e,keyName)} required />
                                                  // :<input className='form-fieldcommon' name={pk?.labelBindValue} type="date" max={currentdt} value={pk?.labelValue} onChange={(e)=>editeValue(e,key)} onKeyDown={(e) => e.preventDefault()} required />  // stop entering manually
                                                }
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </Col>
                                        </Row>
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  );
                                })}
                            </div>
                          );
                        })}
                    </>
                  )}
                </Row>
                {loader == "validateNPILoader" ? (
                  <CommanSpinner spinner={loader} text="" />
                ) : (
                  ""
                )}
                <Row className="mb-4">
                  <Col md={6}></Col>
                  <Col md={3}>
                    {resetButtonEnable ? (
                      <button
                        className="form-btn"
                        onClick={() => resetOcrResult()}
                      >
                        RESET
                      </button>
                    ) : (
                      <button className="form-btn grey-col" disabled>
                        RESET
                      </button>
                    )}
                  </Col>
                  <Col md={3}>
                    {saveButtonEnable ? (
                      <button
                        className="form-btn"
                        onClick={(e) => saveProcessedData(e)}
                      >
                        SAVE
                      </button>
                    ) : (
                      <button className="form-btn grey-col" disabled>
                        SAVE
                      </button>
                    )}
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col md={6}>
                    {patientTypeDropdown == "New" ? (
                      <>
                        <button
                          className="form-btn1"
                          onClick={(e) => {
                            createPatient(e);
                          }}
                        >
                          CREATE & ATTACH TO PATIENT
                        </button>
                        {/*// todo  attach patient popup  */}
                        {createAttachPatientPopup ? (
                          <div className="addbtnpopup">
                            <div className="popupbackdrop">
                              <div className="popup mt-4 attachpatientpop">
                                <div className="clsbtnrow">
                                  <button
                                    className="clsbtn"
                                    onClick={closePopup}
                                  >
                                    <IoMdClose />
                                  </button>
                                </div>
                                <div className="popup-header justify-content-center text-center mb-4">
                                  <h5 className="bold">
                                    If you continue, you will create a new
                                    patient and then attach the document to that
                                    patient.
                                  </h5>
                                </div>
                                <div className="popup-header">
                                  {/* <p className="form-label textleftalign font-black">Patient Name: <BsFillPersonFill /> {processedData?.patientKeyValues[0]?.labelValue} {processedData?.patientKeyValues[1]?.labelValue}</p> */}
                                  <p className="form-label textleftalign font-black">
                                    Provider Name: <FaUserNurse />
                                    {
                                      selectedProviderValue?.data?.general?.name
                                        ?.fullName
                                    }
                                  </p>
                                </div>
                                <div className="popup-header bold mt-3">
                                  {currentPage == "parent" ? (
                                    <>
                                      <input
                                        type="checkbox"
                                        style={{ accentColor: "black" }}
                                        name="abc"
                                        value={moveFaxToTrash}
                                        onChange={() =>
                                          setMoveFaxToTrash(!moveFaxToTrash)
                                        }
                                        checked={moveFaxToTrash}
                                        id="moveTrash"
                                      />{" "}
                                      <label
                                        for="moveTrash"
                                        style={{ cursor: "pointer" }}
                                      >
                                        Move fax to trash{" "}
                                      </label>
                                    </>
                                  ) : (
                                    <>
                                      <input
                                        type="checkbox"
                                        style={{ accentColor: "black" }}
                                        name="abc"
                                        value={moveChildFaxToTrash}
                                        onChange={() =>
                                          setMoveChildFaxToTrash(
                                            !moveChildFaxToTrash,
                                          )
                                        }
                                        checked={moveChildFaxToTrash}
                                        id="moveChildTrash"
                                      />{" "}
                                      <label
                                        for="moveChildTrash"
                                        style={{ cursor: "pointer" }}
                                      >
                                        {isLastChild
                                          ? "Move parent fax to trash as well"
                                          : "Move child fax to trash"}{" "}
                                      </label>
                                    </>
                                  )}
                                </div>
                                <div className="popup-header justify-content-center text-center mt-5 mb-5">
                                  <Row>
                                    <div className="popup-header  mt-4 mb-4">
                                      <Button
                                        className="form-btn1 cancel-btn-popup cancel-btn-width"
                                        onClick={closePopup}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        className="form-btn1 cancel-btn-width"
                                        onClick={() =>
                                          createPatientAndAttach("create")
                                        }
                                      >
                                        CREATE & ATTACH
                                      </Button>
                                    </div>
                                  </Row>
                                </div>
                              </div>
                            </div>
                            {loader == "create&AttPatientLoader" ? (
                              <CommanSpinner spinner={loader} text="" />
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : patientTypeDropdown == "Existing" ? (
                      <>
                        <button
                          className="form-btn1"
                          onClick={(e) => {
                            attachPatient(e);
                          }}
                        >
                          ATTACH TO PATIENT
                        </button>
                        {/*// todo  attach patient popup  */}
                        {attachPatientPopup ? (
                          <div className="addbtnpopup">
                            <div className="popupbackdrop">
                              <div className="popup mt-4 attachpatientpop">
                                <div className="clsbtnrow">
                                  <button
                                    className="clsbtn"
                                    onClick={closePopup}
                                  >
                                    <IoMdClose />
                                  </button>
                                </div>
                                <div className="popup-header justify-content-center text-center mb-4">
                                  <h5 className="bold">
                                    {" "}
                                    If you continue, this document will be
                                    attached to the patient specified below.
                                  </h5>
                                </div>
                                <div className="popup-header">
                                  <p className="form-label textleftalign font-black">
                                    Patient Name: <BsFillPersonFill />{" "}
                                    {
                                      processedData?.patientKeyValues[0]
                                        ?.labelValue
                                    }{" "}
                                    {
                                      processedData?.patientKeyValues[1]
                                        ?.labelValue
                                    }
                                  </p>
                                  {/* <p className="form-label textleftalign font-black">Order ID: <BsBagCheckFill /> {selectedOrderValue?.data?.displayId}</p> */}
                                  {/* <p className="form-label textleftalign font-black">Provider Name: <FaUserNurse/> {selectedProviderValue?.data?.general?.name?.firstName} {selectedProviderValue?.data?.general?.name?.lastName}</p> */}
                                </div>
                                <div className="popup-header bold mt-3">
                                  {currentPage == "parent" ? (
                                    <>
                                      <input
                                        type="checkbox"
                                        style={{ accentColor: "black" }}
                                        name="abc"
                                        value={moveFaxToTrash}
                                        onChange={() =>
                                          setMoveFaxToTrash(!moveFaxToTrash)
                                        }
                                        checked={moveFaxToTrash}
                                        id="moveTrash1"
                                      />{" "}
                                      <label
                                        for="moveTrash1"
                                        style={{ cursor: "pointer" }}
                                      >
                                        Move fax to trash
                                      </label>
                                    </>
                                  ) : (
                                    <>
                                      <input
                                        type="checkbox"
                                        style={{ accentColor: "black" }}
                                        name="abc"
                                        value={moveChildFaxToTrash}
                                        onChange={() =>
                                          setMoveChildFaxToTrash(
                                            !moveChildFaxToTrash,
                                          )
                                        }
                                        checked={moveChildFaxToTrash}
                                        id="moveChildTrash1"
                                      />{" "}
                                      <label
                                        for="moveChildTrash1"
                                        style={{ cursor: "pointer" }}
                                      >
                                        {isLastChild
                                          ? "Move parent fax to trash as well"
                                          : "Move child fax to trash"}
                                      </label>
                                    </>
                                  )}
                                </div>
                                <div className="popup-header justify-content-center text-center mt-5 mb-5">
                                  <Row>
                                    <div className="popup-header  mt-4 mb-4">
                                      <Button
                                        className="form-btn1 cancel-btn-popup cancel-btn-width"
                                        onClick={closePopup}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        className="form-btn1 cancel-btn-width"
                                        onClick={() =>
                                          createPatientAndAttach("attach")
                                        }
                                      >
                                        Attach
                                      </Button>
                                    </div>
                                  </Row>
                                </div>
                              </div>
                            </div>
                            {loader == "create&AttPatientLoader" ? (
                              <CommanSpinner spinner={loader} text="" />
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : null}
                  </Col>
                  <Col md={6}>
                    {orderTypeDropdown == "New" ? (
                      <>
                        <button
                          className="form-btn1"
                          onClick={(e) => {
                            createOrder(e);
                          }}
                        >
                          CREATE & ATTACH TO ORDER{" "}
                        </button>
                        {/*// todo  create order popup  */}
                        {createAttachOrderPopup ? (
                          <div className="addbtnpopup">
                            <div className="popupbackdrop">
                              <div className="popup mt-4 attachpatientpop">
                                <div className="clsbtnrow">
                                  <button
                                    className="clsbtn"
                                    onClick={closePopup}
                                  >
                                    <IoMdClose />
                                  </button>
                                </div>
                                <div className="popup-header justify-content-center text-center mb-4">
                                  <h5 className="bold">
                                    {
                                      `If you continue ${
                                        patientTypeDropdown == "New"
                                          ? "you will create a new patient, new order"
                                          : "you will create a new order for this patient"
                                      } ${
                                        providerTypeDropdown == "New"
                                          ? "and a new provider"
                                          : ""
                                      } ${
                                        patientTypeDropdown == "New"
                                          ? "and attach the document to that patient and order."
                                          : "and attach this document to the new order."
                                      }`
                                      //  patientTypeDropdown == "New" ?
                                      //  "If you continue, you will create a new patient, create a new order and then attach the document to that patient and order."
                                      // :
                                      // "If you continue, you will attach document to Patient, create a new order and then attach the document to that order."
                                    }{" "}
                                  </h5>
                                </div>
                                <div className="popup-header">
                                  {patientTypeDropdown == "Existing" ? (
                                    <p className="form-label textleftalign font-black">
                                      Patient Name: <BsFillPersonFill />
                                      &nbsp;&nbsp;{" "}
                                      {selectedPatientValue?.name
                                        ? selectedPatientValue?.name
                                        : ""}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  {selectedProviderValue?.name ==
                                  "Provider not selected" ? (
                                    ""
                                  ) : (
                                    <p className="form-label textleftalign font-black">
                                      Provider Name: <FaUserNurse />
                                      &nbsp;&nbsp;{" "}
                                      {selectedProviderValue?.name
                                        ? selectedProviderValue?.name
                                        : ""}
                                    </p>
                                  )}
                                  {console.log(
                                    selectedProviderValue?.name,
                                    "provider",
                                  )}
                                </div>
                                <div className="popup-header bold mt-3">
                                  {currentPage == "parent" ? (
                                    <>
                                      <input
                                        type="checkbox"
                                        style={{ accentColor: "black" }}
                                        name="abc"
                                        value={moveFaxToTrash}
                                        onChange={() =>
                                          setMoveFaxToTrash(!moveFaxToTrash)
                                        }
                                        checked={moveFaxToTrash}
                                        id="moveTrash2"
                                      />{" "}
                                      <label
                                        for="moveTrash2"
                                        style={{ cursor: "pointer" }}
                                      >
                                        Move fax to trash
                                      </label>
                                    </>
                                  ) : (
                                    <>
                                      <input
                                        type="checkbox"
                                        style={{ accentColor: "black" }}
                                        name="abc"
                                        value={moveChildFaxToTrash}
                                        onChange={() =>
                                          setMoveChildFaxToTrash(
                                            !moveChildFaxToTrash,
                                          )
                                        }
                                        checked={moveChildFaxToTrash}
                                        id="moveChildTrash2"
                                      />{" "}
                                      <label
                                        for="moveChildTrash2"
                                        style={{ cursor: "pointer" }}
                                      >
                                        {isLastChild
                                          ? "Move parent fax to trash as well"
                                          : "Move child fax to trash"}
                                      </label>
                                    </>
                                  )}
                                </div>
                                <div className="popup-header justify-content-center text-center mt-5 mb-5">
                                  <Row>
                                    <div className="popup-header  mt-4 mb-4">
                                      <Button
                                        className="form-btn1 cancel-btn-popup cancel-btn-width"
                                        onClick={closePopup}
                                      >
                                        Cancel
                                      </Button>
                                      {loader ? (
                                        <Button className="form-btn1 cancel-btn-width">
                                          CREATE & Attach
                                        </Button>
                                      ) : (
                                        <Button
                                          className="form-btn1 cancel-btn-width"
                                          onClick={() =>
                                            createOrderAndAttach("create")
                                          }
                                        >
                                          CREATE & Attach
                                        </Button>
                                      )}
                                    </div>
                                  </Row>
                                </div>
                              </div>
                            </div>
                            {loader == "create&attachOrderLoader" ? (
                              <CommanSpinner spinner={loader} text="" />
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : orderTypeDropdown == "Existing" ? (
                      <>
                        <button
                          className="form-btn1"
                          onClick={(e) => {
                            attachOrder(e);
                          }}
                        >
                          ATTACH TO ORDER{" "}
                        </button>
                        {/*// todo  attach order popup  */}
                        {attachOrderPopup ? (
                          <div className="addbtnpopup">
                            <div className="popupbackdrop">
                              <div className="popup mt-4 attachpatientpop">
                                <div className="clsbtnrow">
                                  <button
                                    className="clsbtn"
                                    onClick={closePopup}
                                  >
                                    <IoMdClose />
                                  </button>
                                </div>
                                <div className="popup-header justify-content-center text-center mb-4">
                                  <h5 className="bold">
                                    If you continue, you will attach the
                                    document to the patient and the existing
                                    order.
                                  </h5>
                                </div>
                                <div className="popup-header">
                                  <p className="form-label textleftalign font-black">
                                    Patient Name: <BsFillPersonFill />
                                    {selectedPatientValue?.name
                                      ? selectedPatientValue?.name
                                      : ""}
                                  </p>
                                  <p className="form-label textleftalign font-black">
                                    Order ID: <BsBagCheckFill />
                                    {selectedOrderValue?.data?.displayId}
                                  </p>
                                  {/* <p className="form-label textleftalign font-black">Provider Name: <FaUserNurse/>{selectedProviderValue?.name? selectedProviderValue?.name :""}</p> */}
                                </div>
                                <div className="popup-header bold mt-3">
                                  {currentPage == "parent" ? (
                                    <>
                                      <input
                                        type="checkbox"
                                        style={{ accentColor: "black" }}
                                        name="abc"
                                        value={moveFaxToTrash}
                                        onChange={() =>
                                          setMoveFaxToTrash(!moveFaxToTrash)
                                        }
                                        checked={moveFaxToTrash}
                                        id="moveTrash3"
                                      />{" "}
                                      <label
                                        for="moveTrash3"
                                        style={{ cursor: "pointer" }}
                                      >
                                        Move fax to trash
                                      </label>
                                    </>
                                  ) : (
                                    <>
                                      <input
                                        type="checkbox"
                                        style={{ accentColor: "black" }}
                                        name="abc"
                                        value={moveChildFaxToTrash}
                                        onChange={() =>
                                          setMoveChildFaxToTrash(
                                            !moveChildFaxToTrash,
                                          )
                                        }
                                        checked={moveChildFaxToTrash}
                                        id="moveChildTrash3"
                                      />{" "}
                                      <label
                                        for="moveChildTrash3"
                                        style={{ cursor: "pointer" }}
                                      >
                                        {isLastChild
                                          ? "Move parent fax to trash as well"
                                          : "Move child fax to trash"}
                                      </label>
                                    </>
                                  )}
                                </div>
                                <div className="popup-header justify-content-center text-center mt-5 mb-5">
                                  <Row>
                                    <div className="popup-header  mt-4 mb-4">
                                      <Button
                                        className="form-btn1 cancel-btn-popup cancel-btn-width"
                                        onClick={closePopup}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        className="form-btn1 cancel-btn-width"
                                        onClick={() =>
                                          createOrderAndAttach("attach")
                                        }
                                      >
                                        Attach
                                      </Button>
                                    </div>
                                  </Row>
                                </div>
                              </div>
                            </div>
                            {loader == "create&attachOrderLoader" ? (
                              <CommanSpinner spinner={loader} text="" />
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* Document Data End */}
          </Container>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ProcessComponent;
