import React, { useState, useEffect, useRef } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { useCookies } from "react-cookie";
import { DebounceInput } from "react-debounce-input";
import { axiosRequest } from "../axiosConfig";

const DropDownWithSearch = (props) => {
  const [searchDropdownPopup, setsearchDropdownPopup] = useState(false);
  const [dropDownList, setDropDownList] = useState([]);
  const outSideRef = useRef(null);
  const [pageSize, setPageSize] = useState(10);
  const [scrollCall, setScrollCall] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [SearchLoader, setSearchLoader] = useState(false);
  const [cookies] = useCookies();

  // This is the formate for the date.
  function formatDate(date, val) {
    let month = date.getMonth() + 1;
    month = month % 12;
    month = month ? "0" + month : "12";
    let date1 = date.getDate();
    date1 = "0" + date1;
    return month?.slice(-2) + "/" + date1?.slice(-2) + "/" + date.getFullYear();
  }

  // Drop down auto close event bind and unbind.
  useEffect(() => {
    function handleClickOutside(event) {
      if (outSideRef.current && !outSideRef.current.contains(event.target)) {
        if (searchDropdownPopup) {
          setsearchDropdownPopup(false);
          setDropDownList([]);
        }
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  }, [outSideRef, searchDropdownPopup]);

  // This function gives patient list in patient dropdown
  // and orderlist in order dropdown and provider list in provider dropdown.
  const getValuesFromApi = () => {
    let url = "";
    if (props?.drpdwn == "patientList") {
      url = `${process.env.REACT_APP_BaseUrl}patientlist`;
    } else if (props?.drpdwn == "orderList") {
      let patientId = props?.displayedPatient;
      // console.log(patientId,'displayedPatient')
      url = `${process.env.REACT_APP_BaseUrl}orderlist?id=${patientId}`;
    } else {
      url = `${process.env.REACT_APP_BaseUrl}referringproviderlist`;
    }
    setSearchLoader(true);
    axiosRequest
      .get(url)
      .then((res) => {
        setSearchLoader(false);
        let data = res?.data?.content;
        let tempData = [];
        if (data) {
          if (props?.drpdwn == "patientList") {
            data?.forEach((element) => {
              tempData.push({
                name: `${element?.general?.name?.fullName} (${formatDate(
                  new Date(
                    element?.general?.dateOfBirth?.includes("T")
                      ? element?.general?.dateOfBirth
                      : element?.general?.dateOfBirth?.replaceAll("-", "/"),
                  ),
                )})`,
                data: element,
              });
            });
          } else if (props?.drpdwn == "orderList") {
            data?.forEach((element) => {
              tempData.push({ name: `${element?.displayId}`, data: element });
            });
          } else {
            data?.forEach((element) => {
              tempData.push({
                name: `${element?.general?.name?.fullName} (${element?.general?.npi})`,
                data: element,
              });
            });
          }
          setDropDownList([...tempData]);
          let pageS = pageSize + 10;
          setPageSize(pageS);
        }
      })
      .catch((err) => {
        setSearchLoader(false);
        console.log(err, "patientList");
        console.log(err, "orderlist");
        console.log(err, "referringproviderlist");
        setDropDownList([]);
        setPageSize(0);
      });
  };

  // When search dropdown is open then it get the list according to the tab.
  useEffect(() => {
    if (searchDropdownPopup) {
      getValuesFromApi();
    }
  }, [searchDropdownPopup]);

  //This function for search the patient and order and provider according to the tab.
  const searchTextApiCall = (size, inputValue) => {
    let url = "";
    if (props?.drpdwn == "patientList") {
      url = `${
        process.env.REACT_APP_BaseUrl
      }patientlist?pageSize=${size}&pageIndex=1&searchString=${inputValue
        ?.replaceAll("/", "-")
        ?.replaceAll("%", "%25")}`;
    } else if (props?.drpdwn == "orderList") {
      url = `${
        process.env.REACT_APP_BaseUrl
      }orderlist?pageSize=${size}&pageIndex=1&id=${inputValue?.replaceAll(
        "%",
        "%25",
      )}`;
    } else {
      url = `${
        process.env.REACT_APP_BaseUrl
      }referringproviderlist?pageSize=${size}&pageIndex=1&searchString=${inputValue?.replaceAll(
        "%",
        "%25",
      )}`;
    }
    setSearchLoader(true);
    axiosRequest
      .get(url)
      .then((res) => {
        setSearchLoader(false);
        let data = res?.data?.content;
        // console.log(data,"hhh");
        let tempData = [];
        if (data) {
          if (props?.drpdwn == "patientList") {
            data?.forEach((element) => {
              tempData.push({
                name: `${element?.general?.name?.fullName} (${formatDate(
                  new Date(
                    element?.general?.dateOfBirth?.includes("T")
                      ? element?.general?.dateOfBirth
                      : element?.general?.dateOfBirth?.replaceAll("-", "/"),
                  ),
                )})`,
                data: element,
              });
            });
          } else if (props?.drpdwn == "orderList") {
            data?.forEach((element) => {
              tempData.push({ name: `${element?.displayId}`, data: element });
            });
          } else {
            data?.forEach((element) => {
              tempData.push({
                name: `${element?.general?.name?.fullName} (${element?.general?.npi})`,
                data: element,
              });
            });
          }
          setDropDownList([...tempData]);
        }
      })
      .catch((err) => {
        setSearchLoader(false);
        console.log(err, "patientList,orderlist,referringproviderlist");
        setDropDownList([]);
        setPageSize(0);
      });
  };

  // This the function for the search text wich has applied debaunce concept.
  const searchInDropDown = (event) => {
    if (event.target.value && event.target.value?.length > 2) {
      let inputValue = event.target.value?.trim();
      setSearchedText(inputValue);
      searchTextApiCall(10, inputValue);
      setPageSize(10);
    } else if (event.target.value == "") {
      getValuesFromApi();
    }
  };

  // This useEffect used for scroll event, on scroll it calls next list.
  useEffect(() => {
    if (props?.drpdwn !== "orderList") {
      function handleClickOutside1(event) {
        if (
          parseInt(event.target.scrollHeight) -
            parseInt(event.target.scrollTop) ==
          parseInt(event.target.offsetHeight)
        ) {
          let url = "";
          if (!scrollCall) {
            if (!searchedText) {
              if (props?.drpdwn == "patientList") {
                url = `${process.env.REACT_APP_BaseUrl}patientlist?pageSize=${pageSize}&pageIndex=1`;
              } else if (props?.drpdwn == "orderList") {
                url = `${process.env.REACT_APP_BaseUrl}orderlist?pageSize=${pageSize}&pageIndex=1&id=${props?.displayedPatient}`;
              } else {
                url = `${process.env.REACT_APP_BaseUrl}referringproviderlist?pageSize=${pageSize}&pageIndex=1`;
              }
            } else {
              if (props?.drpdwn == "patientList") {
                url = `${
                  process.env.REACT_APP_BaseUrl
                }patientlist?pageSize=${pageSize}&pageIndex=1&searchString=${searchedText
                  ?.replaceAll("/", "-")
                  ?.replaceAll("%", "%25")}`;
              } else if (props?.drpdwn == "orderList") {
                url = `${
                  process.env.REACT_APP_BaseUrl
                }orderlist?pageSize=${pageSize}&pageIndex=1&id=${searchedText?.replaceAll(
                  "%",
                  "%25",
                )}`;
              } else {
                url = `${
                  process.env.REACT_APP_BaseUrl
                }referringproviderlist?pageSize=${pageSize}&pageIndex=1&searchString=${searchedText?.replaceAll(
                  "%",
                  "%25",
                )}`;
              }
            }
            setSearchLoader(true);
            axiosRequest
              .get(url)
              .then((res) => {
                setSearchLoader(false);
                let data = res?.data?.content;
                let tempData = [];
                if (data) {
                  if (props?.drpdwn == "patientList") {
                    data?.forEach((element) => {
                      tempData.push({
                        name: `${
                          element?.general?.name?.fullName
                        } (${formatDate(
                          new Date(
                            element?.general?.dateOfBirth?.includes("T")
                              ? element?.general?.dateOfBirth
                              : element?.general?.dateOfBirth?.replaceAll(
                                  "-",
                                  "/",
                                ),
                          ),
                        )})`,
                        data: element,
                      });
                    });
                  } else if (props?.drpdwn == "orderList") {
                    data?.forEach((element) => {
                      tempData.push({
                        name: `${element?.displayId}`,
                        data: element,
                      });
                    });
                  } else {
                    data?.forEach((element) => {
                      tempData.push({
                        name: `${element?.general?.name?.fullName} (${element?.general?.npi})`,
                        data: element,
                      });
                    });
                  }
                  if (pageSize > data?.length) {
                    setScrollCall(true);
                  }
                  setDropDownList([...tempData]);
                  let pageS = pageSize + 10;
                  setPageSize(pageS);
                }
              })
              .catch((err) => {
                setSearchLoader(false);
                console.log(err, "patientList,orderlist,referringproviderlist");
                setDropDownList([]);
                setPageSize(0);
              });
          }
        }
      }
      const scrollContent = document.querySelector(".list-scroll");
      scrollContent &&
        scrollContent.addEventListener("scroll", handleClickOutside1);
      return () => {
        // Unbind the event listener on clean up
        scrollContent &&
          scrollContent.removeEventListener("scroll", handleClickOutside1);
      };
    }
  }, [searchDropdownPopup, pageSize]);

  return (
    <div>
      <div className="input-group" ref={outSideRef}>
        <div
          className={`form-control filterdrp ${
            !props?.selectedValue?.data?.id &&
            ((props?.drpdwn == "patientList" &&
              props?.summaryParamsErrorMessage?.selectedPatient) ||
              (props?.drpdwn == "orderList" &&
                props?.summaryParamsErrorMessage?.selectedOrder) ||
              (props?.drpdwn == "providerList" &&
                props?.summaryParamsErrorMessage?.selectedProvider))
              ? "form-error"
              : ""
          }`}
          onClick={() => setsearchDropdownPopup(!searchDropdownPopup)}
        >
          {props?.placeholder}{" "}
          <button className="downarrow">
            <IoMdArrowDropdown />
          </button>
        </div>
        {searchDropdownPopup ? (
          <ul className="filterdrop ">
            <div className="drpdwnbx">
              <li className="filterdropitems mt-2">
                <DebounceInput
                  debounceTimeout={1000}
                  type="text"
                  placeholder={props?.inputPlaceHolder}
                  className="form-field-drp"
                  onChange={(e) => searchInDropDown(e)}
                  autoFocus
                />
              </li>
              <div className="list-scroll">
                {dropDownList &&
                  dropDownList?.length > 0 &&
                  dropDownList?.map((elem, ind) => {
                    return (
                      <li
                        className={`filterdropitems mt-2 ${
                          elem.name == props?.selectedValue?.name
                            ? "text-primary"
                            : ""
                        }`}
                        key={ind}
                        onClick={() => {
                          props?.setselectedValue(elem);
                          setsearchDropdownPopup(!searchDropdownPopup);
                        }}
                      >
                        {elem.name}
                      </li>
                    );
                  })}
              </div>
            </div>
            {SearchLoader ? (
              <div className="addbtnpopup">
                <div className="popupbackdrop">
                  <button className="btn loading-btn" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm loading-icon text-light"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </ul>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default DropDownWithSearch;
