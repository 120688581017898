import "bootstrap/dist/css/bootstrap.css";
import React, { useState, useEffect, useRef, useContext } from "react";
import Navbar from "../components/Navbar";
import { Container, Col, Row, Table, Button } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { FcProcess } from "react-icons/fc";
import { BsArrowDown } from "react-icons/bs";
import { BsArrowLeft } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";
import { BsArrowUp } from "react-icons/bs";
import {
  IoMdArrowDropdown,
  IoMdClose,
  IoMdArrowDropright,
} from "react-icons/io";
import { MdDelete } from "react-icons/md";
import {
  sortArrayListAsc,
  sortArrayListDes,
  sortDateArrayListAsc,
  sortDateArrayListDes,
} from "../Utils";
import { useHistory } from "react-router-dom";
import CommanSpinner from "../components/CommanSpinner";
import { ShowPages } from "../variable";
import { useCookies } from "react-cookie";
import { useIdleTimer } from "react-idle-timer";
import { IdealTimeGetFaxCall } from "../variable";
import { DebounceInput } from "react-debounce-input";
import CustomPagination from "../CommonComponents/Pagination";
import { axiosRequest } from "../axiosConfig";

const HomePage = () => {
  const [inboxPageState, setInboxPageState] = useState({
    alias: [],
    searchCallerId: "",
    pageNumber: 1,
  });
  const history = useHistory();
  const [openSplit, setOpenSplit] = useState(false);
  const [deleteBtnPopup, setdeleteBtnPopup] = useState(false);
  const [afterfDeleteChangePopup, setafterfDeleteChangePopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const [pageLoader, setPageLoader] = useState("");

  const [actualData, setActualData] = useState([]);
  const [showData, setShowData] = useState([]);
  const [searchVal, setSearchval] = useState("");
  const [aliasSelectedFilter, setAliasSelectedFilter] = useState([]);
  const [drpToggle, setDrpToggle] = useState(false);
  // const [onClickSort,setOnClickSort] = useState(false);

  const [pagesArray, setPagesArray] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const outSideRef = useRef(null);
  const [childPdfArray, setChildPdfArray] = useState([]);
  const [userAdded, setUserAdded] = useState("");
  const [activeSort, setactiveSort] = useState("");
  const [cookies] = useCookies();
  const [selectedAliasToShow, setSelectedAliasToShow] = useState();
  const [aliasCount, setAliasCount] = useState(0);
  const sortByList = {
    ReceivedOn: "ReceivedOn",
    Status: "status",
  };
  const orderByList = {
    ASC: "ASC",
    DESC: "DESC",
  };
  const [sortBy, setSortBy] = useState(sortByList.ReceivedOn);
  const [orderBy, setOrderBy] = useState(orderByList.ASC);
  const [makeAPIcall, setMakeAPIcall] = useState("");
  const [searchValOnSearch, setSearchValOnSearch] = useState("null");

  // Get the Fax list basis on alias and callerid and page index.
  const getFaxList = (alias, callerid, pagenum, sortBy, orderBy) => {
    setPageLoader("faxload");
    callerid = callerid?.replace(/[- )(]/g, "");

    // Make the request
    axiosRequest
      .get(
        `getfaxinbox/alias/${alias}/callerid/${callerid}/pageindex/${pagenum}`,
        {
          params: {
            sortExpression: sortBy,
            sortOrder: orderBy,
          },
        },
      )
      .then((res) => {
        setPageLoader("");
        // console.log(res.data);
        let actualArrayRes = res.data;
        let actualArray = [];
        let len = actualArrayRes?.content?.length;
        function formatDate(date) {
          let hours = date.getHours();
          let minutes = date.getMinutes();
          let ampm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          minutes = minutes < 10 ? "0" + minutes : minutes;
          let strTime = hours + ":" + minutes + " " + ampm;
          let month = date.getMonth() + 1;
          month = month % 12;
          month = month ? "0" + month : "12";
          let date1 = date.getDate();
          date1 = "0" + date1;
          // console.log(date1,"mydate")
          return (
            month?.slice(-2) +
            "-" +
            date1?.slice(-2) +
            "-" +
            date?.getFullYear() +
            " " +
            strTime
          );
        }
        for (let i = 0; i < len; i++) {
          let callerIdNum = `${
            actualArrayRes?.content[i]?.callerId
              ? actualArrayRes?.content[i]?.callerId
              : ""
          }`;
          let calleridFormat = `(${callerIdNum?.slice(
            0,
            3,
          )}) ${callerIdNum?.slice(3, 6)}-${callerIdNum?.slice(
            6,
            callerIdNum?.length,
          )}`;
          let faxLineNum = `${
            actualArrayRes?.content[i]?.faxLine
              ? actualArrayRes?.content[i]?.faxLine
              : ""
          }`;
          let faxLineFormat = `(${faxLineNum?.slice(0, 3)}) ${faxLineNum?.slice(
            3,
            6,
          )}-${faxLineNum?.slice(6, faxLineNum?.length)}`;
          actualArray.push({
            Id: `${
              actualArrayRes?.content[i]?.id
                ? actualArrayRes?.content[i]?.id
                : ""
            }`,
            ReceivedOn: `${
              actualArrayRes?.content[i]?.receivedOn
                ? formatDate(new Date(actualArrayRes?.content[i]?.receivedOn))
                : ""
            }`,
            Pages: `${
              actualArrayRes?.content[i]?.pageCount
                ? actualArrayRes?.content[i]?.pageCount
                : ""
            }`,
            Status: `${
              actualArrayRes?.content[i]?.status
                ? actualArrayRes?.content[i]?.status
                : ""
            }`,
            CallerId: `${calleridFormat}`,
            FaxLine: `${faxLineFormat}`,
            FaxId: `${
              actualArrayRes?.content[i]?.faxId
                ? actualArrayRes?.content[i]?.faxId
                : ""
            }`,
            Name: `${
              actualArrayRes?.content[i]?.location?.name
                ? actualArrayRes?.content[i]?.location?.name
                : ""
            }`,
            Alias: `${
              actualArrayRes?.content[i]?.alias
                ? actualArrayRes?.content[i]?.alias
                : ""
            }`,
            ProcessedBy: `${
              actualArrayRes?.content[i]?.processed_by
                ? actualArrayRes?.content[i]?.processed_by
                : ""
            }`,
            ChildPdf: !actualArrayRes?.content[i]?.childpdf
              ? []
              : actualArrayRes?.content[i]?.childpdf,
            Locked: actualArrayRes?.content[i].locked,
            // "Actions":<button className="view-btn" onClick={()=>viewPage(actualArray?.content[i])}><FaEye /></button>,
          });
        }
        setActualData(actualArray);
        setShowData(actualArray);
        setTotalElements(res.data?.totalElements);
        setTotalPages(res.data?.totalPages);
      })
      .catch((err) => {
        setPageLoader("");
        console.log(err, "getfaxinbox");
      });
  };

  // get the alias list from api and also maintain the state.
  const getAliasList = () => {
    axiosRequest
      .get(`${process.env.REACT_APP_BaseUrl}getaliaslist`)
      .then((res) => {
        let allAlias = [];
        let len = res.data.length;
        for (let i = 0; i < len; i++) {
          let isCheckedValue = false;
          if (history?.location?.state?.indexPageState?.alias?.length > 0) {
            history?.location?.state?.indexPageState?.alias.forEach((elm) => {
              if (elm?.alias == `${res.data[i]}`) {
                isCheckedValue = true;
              }
            });
          }
          let aliasObj = {
            alias: `${res.data[i]}`,
            isChecked: isCheckedValue,
          };
          allAlias.push(aliasObj);
        }
        setAliasSelectedFilter([...allAlias]);
        if (allAlias?.length > 0) {
          let showAlias = "";
          let selectedAlias = allAlias?.filter((item) => {
            if (item.isChecked == true) {
              showAlias =
                (showAlias?.length > 0 ? showAlias + ", " : showAlias) +
                item.alias;
              return item;
            }
          });
          setSelectedAliasToShow(showAlias);
          setAliasCount(selectedAlias?.length);
        }
      })
      .catch((err) => {
        console.log(err, "getaliaslist");
      });
  };

  useEffect(() => {
    // if the user has already state of inbox then if condition wrote.
    if (userAdded == "true") {
      let InboxPageState = history?.location?.state?.indexPageState;
      if (
        InboxPageState &&
        (InboxPageState.alias?.length > 0 ||
          InboxPageState.searchCallerId?.length > 0 ||
          InboxPageState.pageNumber > 1 ||
          (InboxPageState.sortBy &&
            InboxPageState.sortBy != sortByList.ReceivedOn) ||
          (InboxPageState.orderBy && InboxPageState.orderBy != orderByList.ASC))
      ) {
        let searchText = "null";
        let aliasStringList = "null";
        let pageNumber = 1;
        if (InboxPageState.alias?.length > 0) {
          let alias = [...InboxPageState.alias];
          alias = alias.filter((e) => {
            return e.isChecked == true;
          });
          aliasStringList = alias
            ?.map(function (element) {
              return element.alias;
            })
            .join(", ");
        }
        if (InboxPageState.searchCallerId?.length > 0) {
          searchText = InboxPageState.searchCallerId;
        }
        if (InboxPageState.pageNumber > 1) {
          pageNumber = InboxPageState.pageNumber;
        }
        getFaxList(
          aliasStringList,
          searchText,
          pageNumber,
          InboxPageState.sortBy,
          InboxPageState.orderBy,
        );
        setSearchval(searchText == "null" ? "" : searchText);
        setSortBy(InboxPageState.sortBy);
        setOrderBy(InboxPageState.orderBy);
        setSelectedPage(InboxPageState.pageNumber);
        setInboxPageState(history?.location?.state?.indexPageState);
      } else {
        getFaxList("null", "null", selectedPage, sortBy, orderBy);
        setInboxPageState({
          alias: [],
          searchCallerId: "",
          pageNumber: 1,
          sortBy: sortBy,
          orderBy: orderBy,
        });
      }
      getAliasList();
    }
  }, [userAdded == "true"]);

  // Validate the user and redrirect to the url;
  useEffect(() => {
    console.log(cookies);
    if (cookies?.firstName) {
      setUserAdded("true");
    }
    // else {
    //   window?.location?.replace(`${window?.location?.origin}`);
    // }
  }, []);

  // useEffect (()=>{
  //   setShowData(actualData);
  // },[actualData])

  // Drop down auto close event bind and unbind.
  useEffect(() => {
    function handleClickOutside(event) {
      if (outSideRef.current && !outSideRef.current.contains(event.target)) {
        if (drpToggle) {
          setDrpToggle(false);
        }
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  }, [outSideRef, drpToggle]);

  const sortByWithOrder = (sortby, orderby, e) => {
    e?.stopPropagation();
    e?.preventDefault();
    let alias = [...aliasSelectedFilter];
    alias = alias.filter((e) => {
      return e.isChecked == true;
    });
    let aliasStringList = alias
      ?.map(function (element) {
        return element.alias;
      })
      .join(", ");
    getFaxList(
      aliasStringList ? aliasStringList : "null",
      searchVal ? searchVal : "null",
      selectedPage,
      sortby,
      orderby,
    );
    setSortBy(sortby);
    setOrderBy(orderby);
  };

  function searchCall(enteredVal) {
    let searchText = enteredVal ? enteredVal?.trim() : "null";
    let alias = [...aliasSelectedFilter];
    alias = alias.filter((e) => {
      return e.isChecked == true;
    });
    let aliasStringList = alias
      ?.map(function (element) {
        return element.alias;
      })
      .join(", ");
    getFaxList(
      aliasStringList ? aliasStringList : "null",
      searchText,
      1,
      sortBy,
      orderBy,
    );
    setSelectedPage(1);
    let inboxPageStateObj1 = inboxPageState;
    inboxPageStateObj1.pageNumber = 1;
    inboxPageStateObj1.searchCallerId = searchText;
    inboxPageStateObj1.alias = alias ? alias : [];
    inboxPageStateObj1.sortBy = sortBy;
    inboxPageStateObj1.orderBy = orderBy;
    setInboxPageState(inboxPageStateObj1);
  }
  useEffect(() => {
    if (searchValOnSearch !== "null") {
      clearInterval(makeAPIcall);
      setMakeAPIcall(
        setTimeout(() => {
          searchCall(searchVal);
        }, 1000),
      );
    }
  }, [searchValOnSearch]);

  const searchRecord = (value) => {
    const numRegex = /^[0-9\b]+$/;
    let enteredVal = "";
    if (value && !numRegex?.test(value?.replace(/[- )(]/g, ""))) {
      setSearchval(searchVal);
      return;
    } else if (
      value &&
      !value?.includes(" ") &&
      !value?.includes("(") &&
      !value?.includes(")") &&
      !value?.includes("-") &&
      numRegex?.test(value?.replace(/[- )(]/g, ""))
    ) {
      enteredVal = value?.slice(0, 10);
    } else if (
      value &&
      value?.replace(/[- )(]/g, "")?.length <= 10 &&
      numRegex?.test(value?.replace(/[- )(]/g, ""))
    ) {
      enteredVal = value;
    } else if (value !== "") {
      return;
    }
    setSearchval(enteredVal?.trim());
    setSearchValOnSearch(enteredVal?.trim());
  };

  // Cleare the all fiters.
  const clearFilter = () => {
    setSearchval("");
    // setSearchValOnSearch("");
    setSearchValOnSearch("null");
    clearInterval(makeAPIcall);
    let tempAry = [...aliasSelectedFilter];
    tempAry.forEach((e) => {
      return (e.isChecked = false);
    });
    setAliasSelectedFilter(tempAry);
    getFaxList("null", "null", 1, sortByList.ReceivedOn, orderByList.ASC);
    setSelectedPage(1);
    setSortBy(sortByList.ReceivedOn);
    setOrderBy(orderByList.ASC);
    let inboxPageStateObj1 = inboxPageState;
    inboxPageStateObj1.pageNumber = 1;
    inboxPageStateObj1.searchCallerId = "";
    inboxPageStateObj1.alias = [];
    inboxPageStateObj1.sortBy = sortByList.ReceivedOn;
    inboxPageStateObj1.orderBy = orderByList.ASC;
    setInboxPageState(inboxPageStateObj1);
    setSelectedAliasToShow("");
  };

  //get the fax based on Alias.
  const addRemoveAliasFiler = (e, child) => {
    if (e) {
      e.stopPropagation();
    }
    let tempAry = [...aliasSelectedFilter];
    let filterlength = tempAry.length;
    if (!child && e) {
      for (let i = 0; i < filterlength; i++) {
        if (e.target.checked && e.target.value == tempAry[i].alias) {
          tempAry[i].isChecked = true;
        } else if (e.target.value == tempAry[i].alias) {
          tempAry[i].isChecked = false;
        }
      }
    } else if (e == "") {
      for (let i = 0; i < filterlength; i++) {
        if (child.checked && child.value == tempAry[i].alias) {
          tempAry[i].isChecked = false;
        } else if (child.value == tempAry[i].alias) {
          tempAry[i].isChecked = true;
        }
      }
    }
    setAliasSelectedFilter([...tempAry]);
    if (tempAry?.length > 0) {
      let showAlias = "";
      let selectedAlias = tempAry?.filter((item) => {
        if (item.isChecked == true) {
          showAlias =
            (showAlias?.length > 0 ? showAlias + ", " : showAlias) + item.alias;
          return item;
        }
      });
      setSelectedAliasToShow(showAlias);
      setAliasCount(selectedAlias?.length);
    }
    //data filter by api
    let selectedAliaList = tempAry.filter((element) => {
      return element.isChecked == true;
    });
    let aliasStringList = selectedAliaList
      .map(function (element) {
        return element.alias;
      })
      .join(", ");

    getFaxList(
      aliasStringList ? aliasStringList : "null",
      searchVal ? searchVal : "null",
      1,
      sortBy,
      orderBy,
    );
    setSelectedPage(1);
    let inboxPageStateObj1 = inboxPageState;
    inboxPageStateObj1.pageNumber = 1;
    inboxPageStateObj1.searchCallerId = searchVal;
    inboxPageStateObj1.alias = selectedAliaList ? selectedAliaList : [];
    inboxPageStateObj1.sortBy = sortBy;
    inboxPageStateObj1.orderBy = orderBy;
    setInboxPageState(inboxPageStateObj1);
  };

  // View page called.
  const viewRecord = (row) => {
    history.push({
      pathname: "/viewpage",
      state: { pdfVal: row, indexPageState: inboxPageState },
    });
  };

  // View page for Child Fax called.
  const viewChildRecord = (parent, child, ind) => {
    history.push({
      pathname: "/viewpage",
      state: {
        parent: parent,
        child: child,
        ind: ind,
        indexPageState: inboxPageState,
      },
    });
  };

  // Process page Fax called.
  const processRecord = (row) => {
    history.push({
      pathname: "/processview",
      state: { pdfVal: row, indexPageState: inboxPageState },
    });
  };

  // Drop down click toggle changes for child fax
  const childArrayClick = (pdf) => {
    if (openSplit == true && selectedRow !== pdf.Id) {
      setOpenSplit(true);
    } else {
      setOpenSplit(!openSplit);
    }
    setSelectedRow(pdf.Id);
    setChildPdfArray([...pdf.ChildPdf]);
    setdeleteBtnPopup(false);
  };

  // Delete the child pdf and get refresh the fax.
  const childPdfDelete = (parentId, childId) => {
    if (parentId && childId) {
      axiosRequest
        .delete(
          `${process.env.REACT_APP_BaseUrl}fax/childfax/${parentId}/${childId}`,
        )
        .then((res) => {
          setafterfDeleteChangePopup(true);
          let childAray = childPdfArray;
          let index = childAray?.findIndex((elm) => elm.id == childId);
          if (index !== -1) {
            childAray?.splice(index, 1);
          }
          setChildPdfArray([...childAray]);
          if (childAray?.length == 0) {
            setOpenSplit(false);
            setSelectedRow("");
          }
          let alias = [...aliasSelectedFilter];
          alias = alias.filter((e) => {
            return e.isChecked == true;
          });
          let searchText = searchVal ? searchVal?.trim() : "null";
          let aliasStringList = alias
            ?.map(function (element) {
              return element.alias;
            })
            .join(", ");
          getFaxList(
            aliasStringList ? aliasStringList : "null",
            searchText,
            selectedPage,
            sortBy,
            orderBy,
          );
        })
        .catch((err) => {
          console.log(err, "delete child pdf=====");
        });
    }
  };

  const processChildRecord = (parent, child) => {
    history.push({
      pathname: "/processview",
      state: { parent: parent, child: child, indexPageState: inboxPageState },
    });
  };

  // On Alias filter check box check uncheck.
  const toggleCheckbox = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.childNodes?.length > 0) {
      addRemoveAliasFiler("", e.target.childNodes[0]);
    }
  };

  // On Idle the Fax list get Refreshed.
  const onIdle = () => {
    if (window?.location?.pathname == "/home") {
      let alias = [...aliasSelectedFilter];
      alias = alias.filter((e) => {
        return e.isChecked == true;
      });
      let searchText = searchVal ? searchVal?.trim() : "null";
      let aliasStringList = alias
        ?.map(function (element) {
          return element.alias;
        })
        .join(", ");
      getFaxList(
        aliasStringList ? aliasStringList : "null",
        searchText,
        selectedPage,
        sortBy,
        orderBy,
      );
    }
  };
  // Toggle function for both label.
  const setOnClickToggleSort = (label) => {
    if (orderBy == orderByList.ASC) {
      sortByWithOrder(label, orderByList.DESC);
    } else {
      sortByWithOrder(label, orderByList.ASC);
    }
  };
  const getCurrentPageData = (currentPage, pageSize) => {
    // console.log(currentPage,pageSize,"ooooooooooo")
    let alias = [...aliasSelectedFilter];
    alias = alias.filter((e) => {
      return e.isChecked == true;
    });
    let searchText = searchVal ? searchVal?.trim() : "null";
    let aliasStringList = alias
      ?.map(function (element) {
        return element.alias;
      })
      .join(", ");
    getFaxList(
      aliasStringList ? aliasStringList : "null",
      searchText,
      currentPage,
      sortBy,
      orderBy,
    );
    setSelectedPage(currentPage);
    let inboxPageStateObj1 = inboxPageState;
    inboxPageStateObj1.pageNumber = parseInt(currentPage);
    inboxPageStateObj1.searchCallerId = searchText;
    inboxPageStateObj1.alias = alias ? alias : [];
    inboxPageStateObj1.sortBy = sortBy;
    inboxPageStateObj1.orderBy = orderBy;
    setInboxPageState(inboxPageStateObj1);
  };
  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime,
  } = useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * IdealTimeGetFaxCall,
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 2000,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });
  return (
    <>
      {cookies?.firstName ? (
        <>
          <Navbar />
          <Container className="inbxpg">
            <Row className="mt-4">
              <Col xs={12} lg={6}>
                <h2 className="semi-bold">Inbox</h2>
              </Col>
              <Col xs={12} lg={2} className="">
                <button
                  className="form-btn1 clrfilbtn"
                  aria-label="Clear filter"
                  onClick={() => clearFilter()}
                >
                  CLEAR FILTER
                </button>
              </Col>
              <Col xs={12} lg={2} className="">
                <div className="input-group">
                  {/* <DebounceInput debounceTimeout={1000}  type="tel" maxLength={14}  placeholder='Search by Caller ID' className='form-control' onChange={(event) => searchRecord(event.target.value?.trim())}  value={searchVal} /> */}
                  <input
                    type="tel"
                    className="form-control"
                    maxLength={14}
                    value={searchVal}
                    onChange={(event) =>
                      searchRecord(event.target.value?.trim())
                    }
                    placeholder="Search by Caller ID"
                  />
                </div>
              </Col>
              <Col xs={12} sm={6} lg={2} className="">
                <div className="input-group">
                  <div className="form-control filterdrp">
                    <ul className="filterdrop" ref={outSideRef}>
                      <li
                        className="filterdropitems"
                        style={{ color: "#6c757d" }}
                        onClick={() => setDrpToggle(!drpToggle)}
                      >
                        {selectedAliasToShow ? (
                          <div className="showSelectedAlias">
                            {aliasCount ? <span>({aliasCount})</span> : ""}{" "}
                            {selectedAliasToShow}
                          </div>
                        ) : (
                          "Filter by Alias"
                        )}{" "}
                        <button className="downarrow">
                          <IoMdArrowDropdown />
                        </button>
                      </li>
                      {drpToggle &&
                        aliasSelectedFilter &&
                        aliasSelectedFilter.map((elment, ind) => {
                          return (
                            <li
                              className="filterdropitems"
                              key={ind}
                              onClick={(e) => toggleCheckbox(e)}
                            >
                              <input
                                type="checkbox"
                                checked={elment.isChecked}
                                onChange={(e) => addRemoveAliasFiler(e, "")}
                                name={elment.alias}
                                value={elment.alias}
                              />{" "}
                              {elment.alias}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="tablepad">
              <Table className="table table-bordered ">
                <thead className="thead">
                  <tr className="thead-tr">
                    <td
                      className="thead-th"
                      onClick={() => {
                        setOnClickToggleSort(sortByList.ReceivedOn);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <Row>
                        <Col sm={6}>Received</Col>
                        <Col sm={6} style={{ textAlign: "end" }}>
                          <button
                            className={
                              sortBy == sortByList.ReceivedOn &&
                              orderBy == orderByList.ASC
                                ? "activesort"
                                : "sortarrow"
                            }
                            onClick={(e) =>
                              sortByWithOrder(
                                sortByList.ReceivedOn,
                                orderByList.ASC,
                                e,
                              )
                            }
                          >
                            <BsArrowUp />
                          </button>
                          <button
                            className={
                              sortBy == sortByList.ReceivedOn &&
                              orderBy == orderByList.DESC
                                ? "activesort"
                                : "sortarrow"
                            }
                            onClick={(e) =>
                              sortByWithOrder(
                                sortByList.ReceivedOn,
                                orderByList.DESC,
                                e,
                              )
                            }
                          >
                            <BsArrowDown />
                          </button>
                        </Col>
                      </Row>
                    </td>
                    <td className="thead-th">Pages</td>
                    <td
                      className="thead-th"
                      onClick={() => {
                        {
                          setOnClickToggleSort(sortByList.Status);
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <Row>
                        <Col sm={6}>Status</Col>
                        <Col sm={6} style={{ textAlign: "end" }}>
                          <button
                            className={
                              sortBy == sortByList.Status &&
                              orderBy == orderByList.ASC
                                ? "activesort"
                                : "sortarrow"
                            }
                            onClick={(e) =>
                              sortByWithOrder(
                                sortByList.Status,
                                orderByList.ASC,
                                e,
                              )
                            }
                          >
                            <BsArrowUp />
                          </button>
                          <button
                            className={
                              sortBy == sortByList.Status &&
                              orderBy == orderByList.DESC
                                ? "activesort"
                                : "sortarrow"
                            }
                            onClick={(e) =>
                              sortByWithOrder(
                                sortByList.Status,
                                orderByList.DESC,
                                e,
                              )
                            }
                          >
                            <BsArrowDown />
                          </button>
                        </Col>
                      </Row>
                    </td>
                    <td className="thead-th">Caller ID</td>
                    <td className="thead-th">Fax Line</td>
                    <td className="thead-th">Fax ID</td>
                    <td className="thead-th">Location</td>
                    <td className="thead-th">Alias</td>
                    <td className="thead-th">Processed By</td>
                    <td className="thead-th">Actions</td>
                  </tr>
                </thead>
                <tbody className="tbody">
                  {showData &&
                    showData.length > 0 &&
                    showData?.map((pdf, index) => {
                      return (
                        <React.Fragment key={index}>
                          <tr className="tbody-tr veralign">
                            <td
                              className={
                                pdf?.Locked
                                  ? "locDocument tbody-td"
                                  : "tbody-td"
                              }
                              onClick={() => {
                                childArrayClick(pdf);
                              }}
                            >
                              {pdf?.ReceivedOn}
                              {pdf?.ChildPdf?.length > 0 &&
                              pdf?.ChildPdf?.some((el) => {
                                return el?.deleted == false;
                              }) ? (
                                <button
                                  className="downarrow"
                                  style={{
                                    position: "absolute",
                                    right: "unset",
                                  }}
                                >
                                  {openSplit == true &&
                                  selectedRow == pdf.Id ? (
                                    <IoMdArrowDropdown />
                                  ) : (
                                    <IoMdArrowDropright />
                                  )}
                                </button>
                              ) : (
                                ""
                              )}
                            </td>
                            <td
                              className={
                                pdf?.Locked
                                  ? "locDocument tbody-td justify-content-center text-center"
                                  : "tbody-td justify-content-center text-center"
                              }
                              style={{ paddingTop: "13px" }}
                            >
                              {pdf?.Pages}
                            </td>
                            <td
                              className={
                                pdf?.Locked
                                  ? "locDocument tbody-td justify-content-center text-center"
                                  : "tbody-td justify-content-center text-center"
                              }
                              style={{ display: "flex" }}
                            >
                              <span
                                className={
                                  pdf?.Status == "New"
                                    ? "statusopen"
                                    : pdf?.Status == "Processing"
                                    ? "statusprocess"
                                    : "statuscomplted"
                                }
                              >
                                {pdf?.Status}
                              </span>
                            </td>
                            <td
                              className={
                                pdf?.Locked
                                  ? "locDocument tbody-td"
                                  : "tbody-td"
                              }
                            >
                              {pdf?.CallerId}
                            </td>
                            <td
                              className={
                                pdf?.Locked
                                  ? "locDocument tbody-td"
                                  : "tbody-td"
                              }
                            >
                              {pdf?.FaxLine}
                            </td>
                            <td
                              className={
                                pdf?.Locked
                                  ? "locDocument tbody-td"
                                  : "tbody-td"
                              }
                            >
                              {pdf?.FaxId}
                            </td>
                            <td
                              className={
                                pdf?.Locked
                                  ? "locDocument tbody-td"
                                  : "tbody-td"
                              }
                            >
                              {pdf?.Name}
                            </td>
                            <td
                              className={
                                pdf?.Locked
                                  ? "locDocument tbody-td"
                                  : "tbody-td"
                              }
                            >
                              {pdf?.Alias}
                            </td>
                            <td
                              className={
                                pdf?.Locked
                                  ? "locDocument tbody-td processedUser"
                                  : "tbody-td processedUser"
                              }
                            >
                              {pdf?.ProcessedBy}
                            </td>
                            <td className="tbody-td action-td">
                              {pdf?.Locked ? (
                                <span className="docInUse">Doc in Use</span>
                              ) : (
                                <>
                                  <button
                                    className="view-btn"
                                    style={{ marginRight: "5px" }}
                                    onClick={() => viewRecord(pdf)}
                                  >
                                    <FaEye />
                                  </button>
                                  {
                                    // (pdf?.ChildPdf?.length >0 || pdf?.Status=="Completed")?"":
                                    pdf?.ChildPdf?.length > 0 ? (
                                      ""
                                    ) : (
                                      <button
                                        className="view-btn"
                                        onClick={() => processRecord(pdf)}
                                      >
                                        <FcProcess />
                                      </button>
                                    )
                                  }
                                </>
                              )}
                            </td>
                          </tr>
                          {openSplit && selectedRow == pdf.Id
                            ? childPdfArray &&
                              childPdfArray?.length > 0 &&
                              childPdfArray?.map((el, ind) => {
                                if (!el.deleted) {
                                  return (
                                    <tr
                                      className="tbody-tr childpdf-tr veralign"
                                      key={ind}
                                    >
                                      <td className="tbody-td receive-td">
                                        {pdf?.FaxId + `-${ind + 1}`}{" "}
                                      </td>
                                      <td
                                        className="tbody-td justify-content-center text-center"
                                        style={{ paddingTop: "13px" }}
                                      >
                                        {el?.pages?.length}
                                      </td>
                                      <td
                                        className="tbody-td justify-content-center text-center"
                                        style={{ display: "flex" }}
                                      >
                                        <span
                                          className={
                                            el?.status == "New"
                                              ? "statusopen"
                                              : el?.status == "Processing"
                                              ? "statusprocess"
                                              : "statuscomplted"
                                          }
                                        >
                                          {el?.status}
                                        </span>
                                      </td>
                                      <td className="tbody-td">
                                        {pdf?.CallerId}
                                      </td>
                                      <td className="tbody-td">
                                        {pdf?.FaxLine}
                                      </td>
                                      <td className="tbody-td">
                                        {pdf?.FaxId + `-${ind + 1}`}
                                      </td>
                                      <td className="tbody-td">{pdf?.Name}</td>
                                      <td className="tbody-td">{pdf?.Alias}</td>
                                      <td className="tbody-td processedUser">
                                        {pdf?.ProcessedBy}
                                      </td>
                                      <td className="tbody-td">
                                        <button
                                          className="view-btn childpdf-tr"
                                          style={{ marginRight: "5px" }}
                                          onClick={() =>
                                            viewChildRecord(pdf, el, ind)
                                          }
                                        >
                                          <FaEye />
                                        </button>
                                        <button
                                          className="view-btn childpdf-tr"
                                          onClick={() =>
                                            processChildRecord(pdf, el)
                                          }
                                        >
                                          <FcProcess />
                                        </button>
                                        <button
                                          className="view-btn remov childpdf-tr"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            setdeleteBtnPopup(true);
                                            setafterfDeleteChangePopup(false);
                                          }}
                                        >
                                          <MdDelete />
                                        </button>
                                        {deleteBtnPopup ? (
                                          <div className="addbtnpopup">
                                            <div className="popupbackdrop">
                                              <div className="popupdelete mt-4">
                                                <div className="clsbtnrow">
                                                  <button
                                                    className="clsbtn"
                                                    onClick={() => {
                                                      setafterfDeleteChangePopup(
                                                        false,
                                                      );
                                                      setdeleteBtnPopup(false);
                                                    }}
                                                  >
                                                    <IoMdClose />
                                                  </button>
                                                </div>
                                                <div className="popup-header mb-4 text-center">
                                                  <button className="delete-btn delete-popup-icon">
                                                    <span>
                                                      <MdDelete />
                                                    </span>
                                                  </button>
                                                </div>
                                                {afterfDeleteChangePopup ? (
                                                  <div>
                                                    <p className="text-center delete-popup-text">
                                                      Deleted successfully
                                                    </p>
                                                  </div>
                                                ) : (
                                                  <div>
                                                    <div>
                                                      <p className="text-center delete-popup-text">
                                                        Are you sure you want to
                                                        delete the child
                                                        document?
                                                      </p>
                                                    </div>
                                                    <Row>
                                                      <Col
                                                        sm={6}
                                                        className="text-center"
                                                      >
                                                        <Button
                                                          className="form-btn"
                                                          style={{
                                                            width: "80%",
                                                            background:
                                                              "#cecece",
                                                          }}
                                                          onClick={() => {
                                                            setafterfDeleteChangePopup(
                                                              false,
                                                            );
                                                            setdeleteBtnPopup(
                                                              false,
                                                            );
                                                          }}
                                                        >
                                                          Cancel
                                                        </Button>
                                                      </Col>
                                                      <Col
                                                        sm={6}
                                                        className="text-center"
                                                      >
                                                        <Button
                                                          className="form-btn"
                                                          style={{
                                                            width: "80%",
                                                            background:
                                                              "#1C3765",
                                                          }}
                                                          onClick={() =>
                                                            childPdfDelete(
                                                              pdf?.Id,
                                                              el?.id,
                                                            )
                                                          }
                                                        >
                                                          Delete
                                                        </Button>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                    </tr>
                                  );
                                }
                              })
                            : ""}
                        </React.Fragment>
                      );
                    })}
                </tbody>
              </Table>
            </Row>
            <Row>
              <Col
                lg={6}
                className="justify-content-start text-align-start record-count"
              >
                <p className="mb-0">
                  Showing {showData?.length} of {totalElements} Entries
                </p>
              </Col>
              {showData?.length > 0 ? (
                <Col
                  lg={6}
                  className="d-flex flex-col justify-content-end align-items-end col-sm-6"
                >
                  <CustomPagination
                    totalCount={totalElements}
                    showPages={0}
                    pagesArray={0}
                    pageSize={20}
                    currentPage={selectedPage}
                    getCurrentPageData={getCurrentPageData}
                    setTotalPages={setTotalPages}
                    show={true}
                  />
                </Col>
              ) : (
                ""
              )}
            </Row>
            {pageLoader == "faxload" ? (
              <CommanSpinner spinner={pageLoader} text="" />
            ) : (
              ""
            )}
          </Container>
        </>
      ) : (
        ""
      )}
    </>
  );
};
export default HomePage;
