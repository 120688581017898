import React, { useState, useEffect } from "react";
// import ReactPaginationNav from "react-pagination-nav";
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  DropdownButton,
  Pagination,
} from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";

export default function CustomPagination(props) {
  const [total_record, setTotal_record] = useState(0);
  const [total_pages, setTotal_pages] = useState(0);
  const [show_pages, setShow_pages] = useState(0);
  const [pages_array, setPages_array] = useState(0);
  const [page_size, setPage_size] = useState(0);
  const [current_page, setCurrent_page] = useState(1);
  useEffect(() => {
    setTotal_record(props?.totalCount ? props?.totalCount : 0);
    let totalPagesTemp = props?.totalPages ? props?.totalPages : 0;
    if (totalPagesTemp == 0) {
      if (props?.totalCount) {
        totalPagesTemp = Math.ceil(
          parseInt(props?.totalCount) / parseInt(props?.pageSize),
        );
      }
    }
    setTotal_pages(totalPagesTemp);
    if (props?.setTotalPages) {
      props?.setTotalPages(totalPagesTemp);
    }
    let showPagesTemp = props?.showPages ? props?.showPages : 0;
    if (showPagesTemp == 0) {
      if (totalPagesTemp) {
        showPagesTemp = totalPagesTemp > 200 ? 7 : 5;
      }
    }
    setShow_pages(showPagesTemp);
    let pagesArrayTemp = props?.pagesArray ? props?.pagesArray : 0;
    let pages = [];
    if (pagesArrayTemp == 0) {
      if (props?.currentPage <= showPagesTemp) {
        if (totalPagesTemp > showPagesTemp) {
          pages = Array.from(Array(showPagesTemp), (_, index) => index + 1);
        } else {
          pages = Array.from(Array(totalPagesTemp), (_, index) => index + 1);
        }
      } else if (
        props?.currentPage > showPagesTemp &&
        props?.currentPage <= totalPagesTemp
      ) {
        let currentSlot = Math.ceil(
          parseInt(props?.currentPage) / parseInt(showPagesTemp),
        );
        if (parseInt(currentSlot) * parseInt(showPagesTemp) <= totalPagesTemp) {
          pages = Array.from(
            Array(parseInt(currentSlot) * parseInt(showPagesTemp)),
            (_, index) => index + 1,
          );
          pages = [...pages?.slice(-showPagesTemp)];
        } else if (
          parseInt(currentSlot) * parseInt(showPagesTemp) >
          totalPagesTemp
        ) {
          pages = Array.from(Array(totalPagesTemp), (_, index) => index + 1);
          pages = [
            ...pages?.slice(
              (parseInt(currentSlot) - 1) * parseInt(showPagesTemp),
            ),
          ];
        }
      }
    }
    setPages_array(pages ? pages : pagesArrayTemp);
    setPage_size(props?.pageSize ? props?.pageSize : 0);
    setCurrent_page(props?.currentPage ? props?.currentPage : 1);
  }, [props]);

  //++++pagination ++++++++
  const prevPage = () => {
    if (current_page > 1) {
      let currentPage = current_page - 1;
      setCurrent_page(currentPage);
      if (props?.getCurrentPageData) {
        props?.getCurrentPageData(currentPage, page_size);
      }
      if (currentPage < pages_array[0] && currentPage > 1) {
        prev5Page();
      }
    }
  };
  const nextPage = () => {
    if (current_page < total_pages) {
      let currentPage = current_page + 1;
      setCurrent_page(currentPage);
      if (props?.getCurrentPageData) {
        props?.getCurrentPageData(currentPage, page_size);
      }
      if (currentPage > pages_array[pages_array?.length - 1]) {
        next5Page();
      }
    }
  };
  const onPageClick = (currentPage) => {
    setCurrent_page(currentPage);
    if (props?.getCurrentPageData) {
      props?.getCurrentPageData(currentPage, page_size);
    }
  };
  const prev5Page = () => {
    // console.log(pages_array,"pages_array",pages_array[0]);
    let pages = 0;
    if (pages_array[0] - show_pages >= 0) {
      let nextPages = pages_array[0] - 1;
      pages = Array.from(Array(nextPages), (_, index) => index + 1);
      pages = [...pages?.slice(-show_pages)];
    } else if (total_pages < show_pages) {
      pages = Array.from(Array(total_pages), (_, index) => index + 1);
      pages = [...pages?.slice(-total_pages)];
    } else if (pages_array[0] - show_pages <= 0) {
      pages = Array.from(Array(show_pages), (_, index) => index + 1);
      pages = [...pages?.slice(-show_pages)];
    }
    setPages_array(pages);
  };
  const next5Page = () => {
    // console.log(pages_array,"pages_array",pages_array[pages_array?.length-1],current_page);
    let pages = 0;
    if (pages_array[pages_array?.length - 1] + show_pages < total_pages) {
      let nextPages = pages_array[pages_array?.length - 1] + show_pages;
      pages = Array.from(Array(nextPages), (_, index) => index + 1);
      pages = [...pages?.slice(-show_pages)];
    } else if (pages_array[pages_array?.length - 1] < total_pages) {
      pages = Array.from(Array(total_pages), (_, index) => index + 1);
      pages = [
        ...pages?.slice(-(total_pages - pages_array[pages_array?.length - 1])),
      ];
    }
    setPages_array(pages);
  };
  // +++pagination end++++++

  return props?.show == true ? (
    <ul className="pagination justify-content-end">
      {total_pages >= show_pages && pages_array?.length > 0 ? (
        <li className="page-item sale-filter mr-2">
          {" "}
          <button
            className={`page-link ${
              pages_array[0] <= show_pages ? "d-none" : ""
            }`}
            onClick={() => prev5Page()}
          >
            <BsArrowLeft />
            Prev {show_pages}
          </button>
        </li>
      ) : (
        ""
      )}
      <li className="page-item sale-filter mr-2">
        {" "}
        <button
          className={`page-link ${
            current_page == 1 ||
            (pages_array?.length > 0 && !pages_array?.includes(current_page))
              ? "d-none"
              : ""
          }`}
          onClick={() => prevPage()}
        >
          <BsArrowLeft />
        </button>
      </li>
      {pages_array?.length > 0 &&
        pages_array?.map((element, ind) => {
          return (
            <li className="page-item sale-filter mr-2" key={element}>
              {" "}
              <button
                className={`page-link  ${
                  current_page === parseInt(element) ? "active" : ""
                } `}
                onClick={(event) => onPageClick(element)}
              >
                {element}
              </button>
            </li>
          );
        })}
      {total_pages > 1 ? (
        <li className="page-item sale-filter mr-2">
          {" "}
          <button
            className={`page-link ${
              current_page == total_pages ||
              (pages_array?.length > 0 && !pages_array?.includes(current_page))
                ? "d-none"
                : ""
            } `}
            onClick={() => nextPage()}
          >
            <BsArrowRight />
          </button>
        </li>
      ) : (
        ""
      )}
      {total_pages >= show_pages && pages_array?.length > 0 ? (
        <li className="page-item sale-filter mr-2">
          {" "}
          <button
            className={`page-link ${
              total_pages >= show_pages &&
              total_pages - pages_array[pages_array?.length - 1] <= 0
                ? "d-none"
                : ""
            }`}
            onClick={() => next5Page()}
          >
            Next {show_pages}
            <BsArrowRight />
          </button>
        </li>
      ) : (
        ""
      )}
    </ul>
  ) : (
    ""
  );
}
