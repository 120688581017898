import React, { useEffect, useState, useMemo } from "react";
import {
  Viewer,
  Worker,
  RotateDirection,
  SpecialZoomLevel,
  ScrollMode,
  PrimaryButton,
} from "@react-pdf-viewer/core";
import { rotatePlugin } from "@react-pdf-viewer/rotate";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import {
  SelectionMode,
  selectionModePlugin,
} from "@react-pdf-viewer/selection-mode";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import packageJson from "../../package.json";
import { GrRotateRight } from "react-icons/gr";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import "@react-pdf-viewer/selection-mode/lib/styles/index.css";

const ProcessPDFView = (props) => {
  const rotatePluginInstance = rotatePlugin();
  const { RotatePage } = rotatePluginInstance;
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { CurrentPageLabel } = pageNavigationPluginInstance;
  const selectionModePluginInstance = selectionModePlugin();
  const { SwitchSelectionModeButton } = selectionModePluginInstance;
  const [pdfFile, setPdfFile] = useState();
  const [activePage, setactivePage] = useState([]);
  const [selcttionMode, setSelectionMode] = useState("Text");
  const pdfjsVersion = packageJson.dependencies["pdfjs-dist"];
  const defaultLayoutPluginInstance = {
    fullScreenPlugin: {
      onEnterFullScreen: (zoom) => {
        zoom(SpecialZoomLevel.PageFit);
        defaultLayoutPluginInstance.toolbarPluginInstance.scrollModePluginInstance.switchScrollMode(
          ScrollMode.Wrapped,
        );
      },
      onExitFullScreen: (zoom) => {
        zoom(SpecialZoomLevel.PageWidth);
        defaultLayoutPluginInstance.toolbarPluginInstance.scrollModePluginInstance.switchScrollMode(
          ScrollMode.Vertical,
        );
      },
    },
  };

  // Selecting the hand or arrow on pdf.
  const loadpage = (selectedMode) => {
    setSelectionMode(selectedMode);
  };

  // Set the initial values to existing parameter and pdf url.
  useEffect(() => {
    if (props?.pdf) {
      let url = URL.createObjectURL(props?.pdf);
      setPdfFile(url);
    }
  }, [props]);

  return (
    <>
      {/* This is worker file need for pdf show, this is third party library with @react-pdf-viewer */}
      <Worker
        workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsVersion}/pdf.worker.min.js`}
      ></Worker>
      {pdfFile ? (
        <div
          className="rpv-core__viewer"
          style={{
            height: "auto",
            overflow: "auto",
          }}
        >
          <div style={{ flex: 1 }}>
            <div
              style={{
                alignItems: "center",
                backgroundColor: "#eeeeee",
                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                display: "flex",
                justifyContent: "center",
                padding: "4px",
              }}
            >
              <ZoomInButton />
              <ZoomPopover />
              <ZoomOutButton />
              <RotatePage>
                {(props) => (
                  <GrRotateRight
                    onClick={() =>
                      props.onRotatePage(activePage, RotateDirection.Forward)
                    }
                  ></GrRotateRight>
                )}
              </RotatePage>
              <CurrentPageLabel>
                {(props) => setactivePage(props?.currentPage)}
              </CurrentPageLabel>
              {
                <span onClick={() => loadpage("Text")}>
                  <SwitchSelectionModeButton
                    mode={SelectionMode.Text}
                  ></SwitchSelectionModeButton>
                </span>
              }
              {
                <span onClick={() => loadpage("Hand")}>
                  <SwitchSelectionModeButton
                    mode={SelectionMode.Hand}
                  ></SwitchSelectionModeButton>
                </span>
              }
            </div>
            <Viewer
              fileUrl={pdfFile}
              plugins={[
                rotatePluginInstance,
                zoomPluginInstance,
                selectionModePluginInstance,
                defaultLayoutPluginInstance,
                pageNavigationPluginInstance,
              ]}
              defaultScale={SpecialZoomLevel.PageWidth}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ProcessPDFView;
